import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import Error from '../Components/Error/Error';
const PvtRoute = ({ path, children: Component }) => {

  const location = useLocation();
  const token = localStorage.getItem("token");



  // Check if the user is logged in or not
  // if(token && !Component?.role?.includes(JSON.parse(localStorage.getItem('userData'))?.data?.user.role)){

  //   return <Error />;
  // }
  if (!token) {
    // If the user is not logged in and tries to visit restricted pages
    if (
      location.pathname === "/" || location.pathname.includes("/reset-password") || location.pathname === "/forgot-password"


    ) {
      return <Component.element />; // Allow access to login, register, reset-password, or forgot-password pages
    } else {
      // Redirect to the login page if the user is not logged in and tries to access any other page

      return <Navigate to="/" />;
    }
  } else {
    // If the user is logged in and tries to visit restricted pages
    if (

      location.pathname === "/" || location.pathname.includes("/reset-password") || location.pathname === "/forgot-password"
    ) {

      // Redirect to the previous route or the home page

      ; // This will navigate to the previous route
      return    <Navigate to="/Dashboard" />; // Render nothing as we're navigating away
    } else {
      // If the user is logged in, allow access to the protected route
      return <Component.element />;
    }
  }
};

export default PvtRoute;
