import React , { useState, useEffect, useContext }from 'react';
import './TopBar.scss';
import { Dropdown, Space, Menu } from 'antd';

import { UserOutlined, LogoutOutlined } from '@ant-design/icons'; // Import Ant Design icons
import myImage from '../../assests/dummy.png'
import { Link } from "react-router-dom";
import image  from '../../assests/logo.svg'
import { useNavigate } from 'react-router-dom';
import { UserInfo } from "../../Context/userInfo";

import {getRole} from '../../Helpers/utils'
const TopBar = ({ toggleSidebar }) => {

  const { userData, updateUserData } = useContext(UserInfo);
  const [roles,setRoles]=useState(null)

  const navigate = useNavigate();
  useEffect(()=>{
    console.log(userData)
if(userData){
  setRoles( getRole(userData.data?.userInfo?.roles))
}


  },[userData])






  const items = [
    {
      label: (
        <Space>
          <UserOutlined />
          <span>My-Account</span>
        </Space>
      ),
      key: 'account',

    },
    {
      type: 'divider',
    },
    {
      label: (
        <Space>
          <LogoutOutlined />
          <span>Logout</span>
        </Space>
      ),
      key: 'logout',
      onClick: () => logout(),

    },
  ];

  const logout=()=>{
    localStorage.removeItem('loginTime')
        localStorage.removeItem('userData')
        localStorage.removeItem('token')
        navigate('/')
  }


  return (
    <div className="home-section" style={{ position: 'sticky', display: 'flex', justifyContent: 'space-between', alignItems: 'center',height: '10%' }}>

      <div className="home-content">
        <i className='bx bx-menu' onClick={toggleSidebar} style={{color:'#dfdfdf'}}></i>

      </div>
      <div className="logo-details" style={{ fontFamily: 'Myriad Pro',
                    fontSize: 'xx-large',
                    color: 'whitesmoke'}}>
      311 Services Reporting Dashboard

      </div>

      <div className="profile-section" style={{display : 'flex' ,marginRight:'2%'}}>
      <div style={{marginTop:'10%',color:'whitesmoke'}}>
        <h5 > <b>{roles} </b></h5>
        </div>

        <div style={{marginLeft : '19px'}}>
        <Dropdown
          overlay={<Menu items={items} />}
          trigger={['click']}
        >
          <div onClick={(e) => e.preventDefault()}>
            <Space>
            <img
          className='profile-img'
          // src={'data:image/jpeg;base64,'+userData.imageData}
          src={myImage}
          alt="Logo"
        />

            </Space>
          </div>
        </Dropdown>
        </div>

      </div>
    </div>
  );
};

export default TopBar;
