import { createContext, useState ,useEffect} from "react";

export const UserInfo = createContext()

export const UserProvider =({children })=>{

    const [userData,setuserData]=useState(null)
    const updateUserData = (newUserData) => {
        console.log('here ',newUserData)
        setuserData(newUserData); // This updates the userData in state
    };



    return <UserInfo.Provider value={{userData,updateUserData}}>
        {children }
    </UserInfo.Provider>

}