import React, { useState, useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,LineChart,Line
} from "recharts";
import * as Yup from "yup";
import { Steps, Select, Table } from "antd";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  StreetViewPanorama,
} from "@react-google-maps/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// Sample data: Request type, estimated vs actual resolution times (in hours)
import "./TicketDetail.scss";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";

import image from "../../assests/Screenshot 2025-03-07 181628.png";
const containerStyle = {
  width: "100%",
  height: "15rem",
};

const formatDateUS = (date) => {
  return new Date(date).toLocaleDateString("en-US");
};
export default function TicketDetail() {
  const contentRef = useRef();
  const [stepStatus, setstepStatus] = useState("Reported");
  const columns = [
    {
      title: "Request/Type",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: (text) => text.toFixed(2), // Format the score to 2 decimal places
    },
  ];
  const steps = [
    { title: "Reported" },
    { title: "In Progress" },
    { title: "Assign To DPT" },
    { title: "Resolved" },
  ];
  const chartData = [
    { name: "Jan", value: 40 },
    { name: "Feb", value: 60 },
    { name: "Mar", value: 30 },
    { name: "Apr", value: 70 },
    { name: "Jan", value: 40 },
    { name: "Feb", value: 60 },
    { name: "Mar", value: 30 },
    { name: "Apr", value: 70 },
  ];
  const extractInteger = (percentageStr) => {
    const number = parseFloat(percentageStr); // Convert string to number
    return Math.floor(number); // Return integer part
  };
  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: { display: false },
      y: { display: false },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
  };
  const navigate = useNavigate();
  const [streetViewVisible, setStreetViewVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({
    request_type: "",
    request_subtype: "",
    name: "",
    contact_phone: "",
    contactEmail: "",
    status: "",
    priority: "",
    description: "",
    ticketNumber: "",
  });

  const validationSchema = Yup.object().shape({
    request_type: Yup.string(),
    //   .required("Role is required *"),
    request_subtype: Yup.string(),
    //   .required("Role is required *"),
    name: Yup.string(),
    // .required("Role is required *"),
    contact_phone: Yup.string(),
    //   .required("Role is required *"),
    contactEmail: Yup.string(),
    //   .required("Role is required *"),
    status: Yup.string(),
    //   .required("Role is required *"),
    priority: Yup.string(),
    //   .required("Role is required *"),
    description: Yup.string(),
    //   .required("Role is required *"),
    ticketNumber: Yup.string(),
    //   .required("Role is required *"),
  });

  const { id } = useParams();
  const [data, setData] = useState({});
  const [logList, setLoglist] = useState([]);


  const datadummy = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const [center, setcenter] = useState({ lat: 39.5, lng: -98.35 });
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://demo.tscti.com/api/status-history/ticketnumber/${id}`
      );

      console.log(response.data);

      setData(response.data.incident);

      setLoglist(
        response.data.statusHistories.map((res) => {
          return {
            title: res.status,
            description: `${res.description} ${formatDateUS(res.createdAt)}`,
          };
        })
      );

      let data = response.data.incident;
      setcenter({
        lat: data["location"]["coordinates"][0],
        lng: data["location"]["coordinates"][1],
      }); // Approximate center of the U.S.

      setInitialValues({
        request_type: data.request_type,
        request_subtype: data.request_subtype,
        name: data.reporter.name,
        contact_phone: data.reporter.contact_phone,
        contactEmail: data.reporter.contactEmail,
        status: data.status == "Request Created" ? "Reported" : data.status,
        priority: data.priority,
        description: data.description,
        ticketNumber: data.ticketNumber,
        address: data.location.address,
      });
      setstepStatus(
        data.status == "Request Created" ? "Reported" : data.status
      ); // Dynamic value from your state or API

      fetchMuitiQuery(data);

      fetchClass(data);

      fetchSimilarImage(data);
      fetchPredictImage(data);
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log(false);
    }
  };

  const [multiQuery, setmultiQuery] = useState({});
  const [multiClass, setmultiClass] = useState({});

  async function convertImageToBase64(imageUrl) {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64String = btoa(
      String.fromCharCode(...new Uint8Array(arrayBuffer))
    );
    console.log(base64String);
    return base64String;
  }

  const fetchMuitiQuery = async (data) => {
    try {
      const response = await axios.post(
        `https://smartml.tscti.com/predict_multiple_queries`,
        { texts: [data.description] }
      );
      setmultiQuery(response.data[0]);
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log(false);
    }
  };
  const fetchClass = async (data) => {
    try {
      const response = await axios.post(
        `https://smartml.tscti.com/predict_multiple_class?input_data=${data.description}`
      );
      setmultiClass(response.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log(false);
    }
  };
  const [predict,setpredict]=useState([])
  const fetchSimilarImage = async (data) => {
    // const urlArray = data.attachment.map(item => item.url);

    // try {
    //   const response = await axios.post(
    //     `https://smartml.tscti.com/find_similar`,
    //     { file: file }
    //   );
    // } catch (err) {
    //   console.log(err.message);
    // } finally {
    //   console.log(false);
    // }
  };
  const fetchPredictImage = async (data) => {

    const urlArray = data.attachment.map(item => item.url);
    try {
      const response = await axios.post(
        `https://smartml.tscti.com/predict_image/`,
        { "image_paths": urlArray }
      );

      setpredict(response.data.results)

    } catch (err) {
      console.log(err.message);
    } finally {
      console.log(false);
    }
  };

  const nightModeStyles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
  ];
  const mapOptions = {
    styles: nightModeStyles,
    disableDefaultUI: false,
    zoomControl: true,
  };

  const submit = async (value, reset) => {
    try {
      const response = await axios.post(
        "https://demo.tscti.com/api/status-history",
        {
          ticketHistoryId: value.ticketNumber,
          status: value.status,
          description: value.description,
        }
      );

      if (response.status == 200) {
        // Checks if status code is 200 (OK)
        toast.success("Status change successfully");
        reset();
        fetchData();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log(false);
    }
  };
  const currentStepIndex = steps.findIndex((step) => step.title === stepStatus);

  const [isDivVisible, setIsDivVisible] = useState(false);

  const generatePDF = () => {
    // Toggle the div visibility to show it for PDF generation
    setIsDivVisible(true);

    // Get the HTML content of the div with id 'download-template'
    const content = document.getElementById("download-template");

    // Use html2pdf.js to generate the PDF from HTML content
    const options = {
      margin: 1,
      filename: "ticket-details.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a3", orientation: "portrait", fontSize: 5 },
    };

    // Generate PDF
    html2pdf()
      .from(content) // Pass the content you want to convert to PDF
      .set(options)
      .save() // Automatically download the PDF
      .finally(() => {
        // Hide the div after PDF generation
        setIsDivVisible(false);
      });
  };
  const decimalToPercentage = (value) => `${(value * 100).toFixed(2)}%`;
  return (
    <>
      <div className="container-fluid">
        <div
          className="card  card-margin  p-2"
          style={{
            background:
              "rgb(5, 162, 179)",
            textAlign: "center",
          }}
        >
          <h5 style={{ color: "whitesmoke" }}>
            {" "}
            311 Service Ticket No #{data.ticketNumber}
          </h5>
        </div>
        <div
          className="pt-3 pb-2 card-margin"
          style={{
            textAlign: "right",
          }}
        ></div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-size1 status-card">
              <div className="heading-content p-1">
                <h5>Ticket Status</h5>
              </div>
              <div style={{ textAlign: "right" }}>
                <button className="btn  btn-save" onClick={generatePDF}  style={{ background: "rgb(5, 162, 179)", color: "whitesmoke" }}>
                  <i className="fas fa-download"></i>{" "}
                </button>
              </div>

              <div style={{ padding: "1rem 9rem 3rem 9rem" }}>
                <Steps
                  current={currentStepIndex}
                  items={steps.map((step, index) => ({
                    ...step,
                    status:
                      index < currentStepIndex
                        ? "finish" // Completed steps
                        : index === currentStepIndex
                        ? currentStepIndex === steps.length - 1
                          ? "finish" // Last step should be "finish" instead of "process"
                          : "process" // Current step in progress
                        : "wait", // Future steps
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div
              className="card card-size"
              style={{ background: "rgb(31, 41, 53)" }}
            >
              <Formik
                // validationSchema={validationSchema}
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) => {
                  submit(values, resetForm); // Use your custom submit logic here
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form id="signup-form" className="signup-form">
                    <div className="row">
                      <div className="heading-content p-1">
                        <h5>Tickets Detail</h5>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading1">Ticket Number</label>
                        <Field
                          className={`form-input ${
                            errors.email && touched.email ? "error" : ""
                          }`}
                          name="ticketNumber"
                          id="ticketNumber"
                          placeholder="Ticket Number*"
                          autoComplete="new-password"
                        />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading1">Ticket Type</label>
                        <Field
                          className={`form-input ${
                            errors.email && touched.email ? "error" : ""
                          }`}
                          name="request_type"
                          id="request_type"
                          placeholder="Ticket Type*"
                          autoComplete="new-password"
                        />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading1">
                          Ticket Sub Type
                        </label>
                        <Field
                          className={`form-input ${
                            errors.email && touched.email ? "error" : ""
                          }`}
                          name="request_subtype"
                          id="request_subtype"
                          placeholder="Ticket Sub Type*"
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading1">Priority</label>
                        <Field
                          className={`form-input ${
                            errors.email && touched.email ? "error" : ""
                          }`}
                          name="priority"
                          id="priority"
                          placeholder="Priority*"
                          autoComplete="new-password"
                        />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading1">Status</label>
                        <Field
                          name="status"
                          className={`form-input ${
                            errors.role && touched.role ? "error" : ""
                          }`}
                        >
                          {({ field, form }) => (
                            <Select
                              style={{
                                width: "100%",
                                height: 48,
                                background: "#15212d",
                                borderColor: "rgb(223, 223, 223)",
                              }}
                              {...field}
                              options={[
                                { value: "Reported", label: "Reported" },
                                { value: "In Progress", label: "In Progress" },
                                {
                                  value: "Assign To DPT",
                                  label: "Assign to DPT",
                                },
                                {
                                  value: "Resolved",
                                  label: "Resolved",
                                },
                              ]}
                              onChange={(option) => {
                                console.log(option);
                                setFieldValue("status", option);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-md-4 col-lg-4"></div>
                    </div>

                    <div className="row mt-3">
                      <div className="heading-content p-1">
                        <h5>Reporter Details</h5>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading1">Name</label>
                        <Field
                          className={`form-input ${
                            errors.email && touched.email ? "error" : ""
                          }`}
                          name="name"
                          id="name"
                          placeholder="Name*"
                          autoComplete="new-password"
                        />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading1">Phone No</label>
                        <Field
                          className={`form-input ${
                            errors.email && touched.email ? "error" : ""
                          }`}
                          name="contact_phone"
                          id="contact_phone"
                          placeholder="Phone No*"
                          autoComplete="new-password"
                        />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading1">Contact Email</label>
                        <Field
                          className={`form-input ${
                            errors.email && touched.email ? "error" : ""
                          }`}
                          name="contactEmail"
                          id="contactEmail"
                          placeholder="Email*"
                          autoComplete="new-password"
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="form-group">
                        <div className="col-md-6 col-lg-6">
                          <label className="label-heading1">Description</label>
                          <Field
                            as="textarea"
                            className={`form-input ${
                              errors.email && touched.email ? "error" : ""
                            }`}
                            name="description"
                            id="description"
                            placeholder="Description*"
                            autoComplete="new-password"
                            rows="4"
                            cols="12"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <button
                        className="btn btn-style  btn-save"
                        style={{
                          background: "rgb(5, 162, 179)",
                          color: "whitesmoke",
                        }}
                      >
                        <i
                          className="fas fa-save"
                          style={{ marginRight: "8px" }}
                        ></i>{" "}
                        Save
                      </button>

                      <button
                        className="btn btn-style  btn-cancel"
                        onClick={() => navigate("/dashboard")}
                        style={{ background: "#F44336", color: "whitesmoke" }}
                      >
                        <i
                          className="fas fa-times"
                          style={{ marginRight: "8px" }}
                        ></i>{" "}
                        Cancel
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div
              className="card  card-size2 "
              style={{
                background: "rgb(21 33 45)",
                border: "0px",
                overflowX: "hidden",
              }}
            >
              <div
                className="attachment1 p-3 mb-3"
                style={{ background: "rgb(31, 41, 53)" }}
              >
                <div className="heading-content p-1">
                  <h5>Attachments</h5>
                </div>
                <div className="image-box">
                  <div className="row">
                    {data.attachment?.length > 0 &&
                      data.attachment.map((res, index) => (
                        <div className="col-md-6">
                          {" "}
                          <a
                            key={index}
                            href={res.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ width: "50%" }} // Each image container takes 35% of the parent width
                          >
                            <img
                              src={res.url}
                              style={{
                                width: "100%", // Makes image fill 35% of the parent container
                                aspectRatio: "1/1", // Ensures a square shape
                                objectFit: "fill", // Crops image to fit without distortion
                                borderRadius: "5px", // Optional: rounded corners
                              }}
                              alt="Attachment"
                            />
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div
                className="attachment1 p-3"
                style={{ background: "rgb(31, 41, 53)" }}
              >
                <div className="heading-content p-1">
                  <h5>Location</h5>
                </div>

                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={15}
                  options={{
                    mapTypeId: "satellite", // Set Satellite View as default
                    styles: nightModeStyles, // Apply night mode ONLY to the main map
                    streetViewControl: true, // Enable Street View button
                    mapTypeControl: false,
                  }}
                  onClick={() => setStreetViewVisible(!streetViewVisible)}
                >
                  <Marker position={center} />
                  {streetViewVisible && (
                    <StreetViewPanorama
                      position={center} // Start Street View at the same center point
                      visible={true}
                      options={{
                        pov: { heading: 100, pitch: 0 }, // Street View angle
                        zoom: 1,
                      }}
                    />
                  )}
                </GoogleMap>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6">
            <div
              className="card card-size logs-box"
              style={{
                background: "rgb(31, 41, 53)",
                backgroundImage: `url(${image})`,
              }}
            >
              {" "}
              <div className="heading-content p-1">
                <h5>311 with Gen-AI</h5>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div
                    className="card inner-cards"
                    style={{
                      background:
                        "linear-gradient(to left, rgb(8 151 156 / 33%), rgb(69 120 120))",  position: "relative",
                    }}

                  >

<div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0.2, // Make the chart subtle
          zIndex: 1,
          padding:
          '2% 15% 0 15%'
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartData}>
          <XAxis dataKey="name" hide />
          <YAxis hide />
          <Bar dataKey="value" fill="rgba(230, 240, 250, 0.5)
" barSize={15}/>
        </BarChart>
      </ResponsiveContainer>
      </div>
                    <div className="heading-content1 p-1">
                      {/* <h5>311 with Gen-AI</h5> */}
                    </div>
                    <div className="row">
                      {Object.entries({
                        Predicted_Request_Reason: "Order Trash Cart",
                        Predicted_Request_Type: "Trash/Recycling",
                        Predicted_Responsible_Agency:
                          "Department of Sanitation",
                        Predicted_Contractor: "Richards Disposal",
                      }).map(([key, value], index) => (
                        <div
                          key={index}
                          className="col-6"
                          style={{ padding: "0 0 3% 5%" }}
                        >
                          <h5 className="li-headings">
                            {key.replace(/_/g, " ")}
                          </h5>
                          <div className="li-colors">
                            <span>
                              <i
                                className="fas fa-chevron-right"
                                style={{ marginRight: "3%" }}
                              ></i>
                            </span>
                            {value}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="heading-content p-1">
                <h5>Predict From Images</h5>
              </div>
              <div className="row mb-3">
                <div className="">
                {predict?.length > 0 &&
                        predict.map((res, index) => (
                          <div
                          className="card inner-cards"
                          style={{
                            background: "rgb(21, 33, 45)",
                            height: "auto", // Set the height of the card
                            maxHeight: "auto",
                            overflowY: "auto",
                            overflowX: "hidden", // Hides horizontal scroll
                            border: "none",
                            padding: "10px", // Add some spacing
                          }}
                        >
                          <div className="row">
                            {/* Image Section */}
                            <div className="col-md-6">

                              <a href={res.image_path} target="_blank" rel="noopener noreferrer" style={{ width: "80%", display: "block",marginTop:'15%' }}>
                                <img
                                  src={res.image_path}
                                  style={{
                                    width: "100%",
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                    borderRadius: "5px",
                                  }}
                                  alt="Attachment"
                                />
                              </a>
                            </div>


                            <div className="col-md-6" >

                            <h5 style={{ color: "white", display: "block" }}>Category :<span style={{ color: "white"}}> {res.predicted_label}</span></h5>
                              <div
                      style={{
                        width: "80%",
                        height: "100%",
                        marginBottom: 20,
                      }}
                    >
                      <CircularProgressbar
                        value={extractInteger(res.probability)}
                        text={`${extractInteger(res.probability)}%`}
                        styles={buildStyles({
                          pathColor: "#82c784",
                          trailColor: "#8884d8",
                          textColor: "white",
                          textSize: "16px",
                        })}
                      />
                    </div></div>
                            </div>
                          </div>




                        ))}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-6 ">
            <div
              className="card card-size "
              style={{ background: "rgb(31, 41, 53)" }}
            >
              {" "}
              <div className="heading-content p-1">
                <h5>Logs History</h5>
              </div>
              <Steps
                className="logs "
                progressDot
                direction="vertical"
                items={logList}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid "
        id="download-template"
        style={{
          display: isDivVisible ? "block" : "none", // Toggle visibility based on state
        }}
      >
        <div
          className="pt-3 pb-2 card-margin"
          style={{
            textAlign: "right",
          }}
        ></div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-size1 status-card-download">
              <div className="heading-content p-1">
                <h5>Ticket Status</h5>
              </div>

              <div className="p-5 ">
                <Steps
                  className="download"
                  current={currentStepIndex}
                  items={steps.map((step, index) => ({
                    ...step,
                    status:
                      index < currentStepIndex
                        ? "finish" // Completed steps
                        : index === currentStepIndex
                        ? currentStepIndex === steps.length - 1
                          ? "finish" // Last step should be "finish" instead of "process"
                          : "process" // Current step in progress
                        : "wait", // Future steps
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card card-size-download">
              <Formik
                // validationSchema={validationSchema}
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) => {
                  submit(values, resetForm); // Use your custom submit logic here
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form id="signup-form" className="signup-form">
                    <div className="row">
                      <div className="heading-content p-1">
                        <h5>Tickets Detail</h5>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading-download">
                          Ticket Number
                        </label>
                        <div className="mt-1"> {data.ticketNumber}</div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading-download">
                          Ticket Type
                        </label>
                        <div className="mt-1"> {data.request_type}</div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading-download">
                          Ticket Sub Type
                        </label>
                        <div className="mt-1"> {data.request_subtype}</div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading-download">
                          Priority
                        </label>
                        <div className="mt-1"> {data.priority}</div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading-download">Status</label>
                        <div className="mt-1">
                          {" "}
                          {data.status == "Request Created"
                            ? "Reported"
                            : data.status}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4"></div>
                    </div>

                    <div className="row mt-3">
                      <div className="heading-content p-1">
                        <h5>Reporter Details</h5>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading-download">Name</label>
                        <div className="mt-1">
                          {" "}
                          {data?.name ? data?.name : "N/A"}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading-download">
                          Phone No
                        </label>
                        <div className="mt-1">
                          {" "}
                          {data?.contact_phone ? data?.contact_phone : "N/A"}
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <label className="label-heading-download">
                          Contact Email
                        </label>
                        <div className="mt-1">
                          {" "}
                          {data?.contactEmail ? data?.contactEmail : "N/A"}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="form-group">
                        <div className="col-md-6 col-lg-6">
                          <label className="label-heading-download">
                            Description
                          </label>
                          <div className="mt-1"> {data.description}</div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="form-group">
                        <div className="col-md-6 col-lg-6">
                          <label className="label-heading-download">
                            Address
                          </label>
                          <div className="mt-1"> {data.description}</div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    ></div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
