import React, { useState,useEffect ,useContext} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route,useLocation,useNavigate  } from 'react-router-dom';
import PvtRoute from './Helpers/PvtRoute';
import { routes } from './Helpers/Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Components/SideBar/SideBar'; // Import your sidebar
import "./index.css";
import TopBar from './Components/TopBar/TopBar';
import { UserInfo } from "./Context/userInfo";
import { UserProvider } from './Context/userInfo';
import Axios from "../src/Helpers/Interceptors";
const App = () => {

  const { userData, updateUserData } = useContext(UserInfo);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token && !userData) {
      // Example: Fetch user data from API

      fetchUserData(token);
    }
  }, []);

  const fetchUserData = async (token) => {
    try {

        const response = await Axios.get('user/user-details');
        if(response.status == 200 ){


          updateUserData(response.data)


        }else if(response.status == 401 ){
     
        }


      } catch (error) {
        console.log(error)


      }
  };
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
};
const showSidebarAndTopbar = ![
  '/', '/register', '/forgot-password'
].includes(location.pathname) && !location.pathname.startsWith('/reset-password');
    return (
      <div className={`app ${isSidebarOpen ? 'sidebar-open' : ''}`} style={{height: '100vh'}}> {/* Add class based on sidebar state */}


        {showSidebarAndTopbar && <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />}
        {showSidebarAndTopbar && <TopBar toggleSidebar={toggleSidebar} />}

        <div className={ !showSidebarAndTopbar  ? ` ` : 'home-section1'} style={{height:'auto'}} > {/* Main content wrapper */}

          <ToastContainer />

          <Routes>
            {routes()?.map((val, i) => (

              <Route
                key={i}
                path={val.path}
                element={

                    <PvtRoute
                      children={val}
                      path={val.path?.split("/")[1]}
                    />

                }
              />

            ))}
          </Routes>


        </div>

      </div>

    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <UserProvider>
    <App />
    </UserProvider>
  </BrowserRouter>
);
