import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import Axios from "../../Helpers/Interceptors";
import "./ForgotPassword.scss";
import { Formik, Form, Field ,ErrorMessage} from "formik";
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import Loader from '../Loader/Loader';
import { getLogoName } from '../../Helpers/utils';
import image  from '../../assests/logo.svg'
const ForgotPassword = () => {
  const [Logo, setLogo] = useState(getLogoName);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    email: "",

  });
  const [loading, setloading] = useState(false);
  const handleSubmit = async (value,resetForm) => {
    console.log(value)
    try {


      setloading(true)
            const response = await Axios.post(`forgotPassword/verifyMail/${value.email}`, {});
            if (response.status === 200) {  // Checks if status code is 200 (OK)
              toast.success('Password reset link sent to your email address');
              setloading(false)
              resetForm()
              navigate('/')
            }else if (response.status === 401 || response.status === 403 || response.status === 404) {
              console.log('here')
              toast.error(response.response.data.message);
              setloading(false)
            }
            toast.error( response?.response?.data);
            console.log('here')
        } catch (error) {


      // If error is a CORS issue, you might not have a response object
      if (error.response) {

        toast.error(`Error: ${error.response.data.message || 'An error occurred. Please try again later.'}`);
      } else if (error.request) {

        toast.error('Request was made but no response was received. This could be a CORS issue or network problem.');
      } else {

        toast.error('An error occurred. Please try again later.');
      }

      setloading(false);
        }

  };


  const checkAllowed = async (value,resetForm)=>{
    try {
      setloading(true);
      const response = await Axios.get(`forgotPassword/isApproved/${value.email}`);
      if (response.status === 200) {
        console.log(response)
        if(response?.data?.isApproved == 'true'){
          handleSubmit(value,resetForm)
        }else{
          toast.error('User Not Approved');
        }

        setloading(false);
      }else{

        toast.error(response.response.data?.message);
        setloading(false);
      }
      console.log(response)
    } catch (error) {
      // Handle CORS error or other network issues
      console.log(error)
      // If error is a CORS issue, you might not have a response object
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error('Request was made but no response was received. This could be a CORS issue or network problem.');
      } else {
        toast.error('An error occurred. Please try again later.');
      }
      toast.error('Request was made but no response was received. This could be a CORS issue or network problem.');
      setloading(false);
    }
   }






  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required *")
      .email("Email is invalid"),

  });

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
    { loading && <Loader />}
    <section className="signup">
    <div className="overlay">
        <div className="container-size">
          <div className="signup-content">



            <Formik
              validationSchema={validationSchema}
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values, { resetForm }) => {
                // Handle your submit logic here
                console.log(values)
                checkAllowed(values,resetForm);
                // Call your custom submit function
               ; // Optionally reset the form after submission
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form id="signup-form" className="signup-form" autoComplete="off">
                  <div className="register-logo">
                  <div className="logo-resize d-flex" style={{justifyContent: 'center'}}>
                    <img
                      src={image}
                      style={{ height: "65px" }}
                      alt="Logo"
                    /><span style={{marginTop:"1.9%"}}><h3 style={{ marginTop: '10%',
                    fontFamily: 'Myriad Pro',
                    fontSize: 'xx-large',
                    color: 'whitesmoke'}}>1</h3></span>
                    <span><h3 style={{ marginTop: '10%',
                    fontFamily: 'Myriad Pro',
                    fontSize: 'xx-large',
                    color: 'rgba(130,195,65,255)'}}>Dallas</h3></span>

                    </div>
                     <h2 className="forgot-password">Forgot Password</h2>
                    <h6 className="form-title">Enter your email to get a verification link.</h6>
                  </div>

                  <div className="form-group">
                  <Field autoComplete="off"
                      className={`form-input ${errors.email && touched.email ? 'error' : ''}`}
                      name="email"
                      placeholder="Email*"
                    />
                    <ErrorMessage name="email">
                      {msg => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl"></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>


                  <div className="form-group">
                    <button className="form-submit" type="submit">
                      Send
                    </button>
                  </div>


                  <p className="loginhere">
                    Have already an account?{" "}
                    <Link to="/" className="loginhere-link">
                      Login here
                    </Link>
                  </p>

                </Form>
              )}
            </Formik>
          </div>
        </div>
        </div>
      </section>
    </>

  );
};

export default ForgotPassword;
