import React, { useState,useEffect ,useContext } from "react";
import { useNavigate } from "react-router";
import Axios from "../../Helpers/Interceptors";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import './Login.scss';

import { toast } from "react-toastify";
import Loader from '../Loader/Loader';
import { getLogoName ,listenToEvent, removeListener } from '../../Helpers/utils';
import image  from '../../assests/logo.svg'
import { UserInfo } from "../../Context/userInfo";
const Login = () => {
  const { userData, updateUserData } = useContext(UserInfo);
  const [Logo, setLogo] = useState(getLogoName);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const validationSchema = Yup.object().shape({
    emailOrPhone: Yup.string()
      .required("Email is required *")
      .email("Email is invalid"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters *")
      .required("Password is required *"),
  });

  const handleSubmit = async (values,reset) => {

    try {
      setloading(true);
      const response = await Axios.post('auth/signin', values);
      if(response.status == 200 ){

         localStorage.setItem('token',response.data.data.accessToken)
       
         updateUserData(response.data)
         navigate("/Dashboard");
         toast.success(response.data.message);
      }else if(response.status == 401 ){
        toast.error(response.response.request.data);
      }

      setloading(false);
    } catch (error) {
      console.log(error)

      setloading(false);
    }
  };


  useEffect(() => {
    document.getElementById("emailOrPhone").setAttribute("autocomplete", "off");
    document.getElementById("password").setAttribute("autocomplete", "new-password");
    setLogo(getLogoName)
    const handleCustomEvent = (e) => {
      setLogo(getLogoName) // Get the event data from e.detail
    };

    listenToEvent('customEvent', handleCustomEvent); // Listen for the event

    // Cleanup the event listener when ComponentB is unmounted
    return () => {
      removeListener('customEvent', handleCustomEvent); // Remove the listener
    };

    // Dynamically set autocomplete attributes to prevent autofill suggestions

  }, []);



 const  Focus = event => {
  var foo = document.querySelector("input");
  foo.setAttribute("autoComplete", "off");


 }

  return (
    <>
   { loading && <Loader />}
      <section className="signup">
        <div className="overlay">
        <div className="container-size">
          <div className="signup-content">
            <Formik
            enableReinitialize
              initialValues={{ emailOrPhone: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values,resetForm);

              }}
            >
              {({ errors, touched }) => (
                <Form id="signup-form" className="signup-form"
               >
                  <div className="register-logo mb-3">
                    <div className="logo-resize d-flex" style={{justifyContent: 'center'}}>
                    <img
                      src={image}
                      style={{ height: "65px" }}
                      alt="Logo"
                    /><span style={{marginTop:"1.9%"}}><h3 style={{ marginTop: '10%',
                    fontFamily: 'Myriad Pro',
                    fontSize: 'xx-large',
                    color: 'whitesmoke'}}>1</h3></span>
                    <span><h3 style={{ marginTop: '10%',
                    fontFamily: 'Myriad Pro',
                    fontSize: 'xx-large',
                    color: 'rgba(130,195,65,255)'}}>Dallas</h3></span>

                    </div>

                    {/* <h2>Admin Login</h2> */}
                  </div>

                  <div className="form-group">

                    <Field
                      className={`form-input ${errors.emailOrPhone && touched.emailOrPhone ? 'error' : ''}`}
                      name="emailOrPhone"
                      id="emailOrPhone"
                      placeholder="Email*"
                       onFocus={Focus}
                       autoComplete="new-password"
                    />
                    <ErrorMessage name="emailOrPhone">
                      {msg => (
                        <span className="error-message">
                          <i className="fa-solid fa-circle-info fa-xl"></i>
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="form-group">

                    <div className="input-group">
                      <Field
                        className={`form-input ${errors.password && touched.password ? 'error' : ''}`}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password*"
                        id="password"
                        onFocus={Focus}
                        autoComplete='nope'

                      />
                      <i
                        className={`far ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: 'pointer', marginLeft: '-30px', marginTop:'24px' }}
                      ></i>
                    </div>
                    <ErrorMessage name="password">
                      {msg => (
                        <span className="error-message">
                          {/* <i className="fa-solid fa-circle-info fa-xl"></i> */}
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                    <div className="forgot-password" style={{textAlign:"right"}}>
    <Link to="/forgot-password" className="forgot-link">Forgot Password?</Link>
  </div>
                  </div>

                  <div className="form-group">
                    <button className="form-submit" type="submit">Log in</button>
                  </div>

                  <p className="loginhere">
                    Don't have an account?{" "}
                    <Link className="loginhere-link" to='/register'>Signup here</Link>
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        </div>
      </section>
    </>

  );
};

export default Login;
