
import Error from '../Components/Error/Error';

import Dashboard from '../Components/Dashboard/Dashboard';

import TicketDetail from '../Components/TicketDetail/TicketDetail';
import CallTickets from '../Components/Tickets/CallTickets';
import Login from '../Components/Login/Login';
import ForgotPassword from '../Components/ForgotPassword/ForgotPassword';
import ResetPassword from '../Components/ResetPassword/ResetPassword';
import MyTickets from '../Components/MyTickets/MyTickets';


export const routes = () => [
  { path: '/', element: Login , pvtRoute: false,role:['admin','contributor','compliance analyst'] }, // Public route (login)
  {
    path: '/',
    element: Login,
    pvtRoute: false,role:['admin','contributor','compliance analyst']
  },


  {
    path: 'Dashboard',
    element: Dashboard,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'reset-password/:id/:check',
    element: ResetPassword,pvtRoute: false,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'forgot-password',
    element: ForgotPassword,
    role:['admin','contributor','compliance analyst']
  },


  {
    path: 'ticket-detail/:id',
    element: TicketDetail,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'Agent-care',
    element: CallTickets,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'My-tickets',
    element: MyTickets,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },


  { path: '*', element: Error }, // Error route

];
