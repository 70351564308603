
import { useLocation } from 'react-router-dom';

import React, { useState,useEffect , useCallback, useRef} from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { getLogoName ,listenToEvent, removeListener } from '../../Helpers/utils';
import { GoogleMap, LoadScript, Marker,Polyline,Polygon  } from '@react-google-maps/api';
import image  from '../../assests/recycle-bin.png'

import image2  from '../../assests/mute.png'
import image6  from '../../assests/car_10889472.png'
import image3  from '../../assests/pet-friendly_2059740.png'
import image7  from '../../assests/shield.png'
import {coordinates ,coordinates2} from '../../Helpers/utils'
const containerStyle = {
    width: '100%',
    height: '500px',
  };

  const center = { lat: 39.5, lng: -98.35 }; // Approximate center of the U.S.



  const denverBoundaryCoords = [
    { lat: 39.9142, lng: -105.1099 },
    { lat: 39.9142, lng: -104.5997 },
    { lat: 39.6142, lng: -104.5997 },
    { lat: 39.6142, lng: -105.1099 },
  ];


  const polylineOptions = {
    fillColor: "#FF0000",
    fillOpacity: 0.2,
    strokeColor: "Red",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: true,
  };
  const getPolylineColor = (state) => {
    switch (state) {
        case 'Texas':
            return { fillColor: "rgb(241 60 60 / 44%)", strokeColor: "rgb(241 60 60 / 44%)" }; // Red for Texas
        case 'California':
            return { fillColor: "#0000FF", strokeColor: "blue" }; // Blue for California
        case 'Florida':
            return { fillColor: "#008000", strokeColor: "green" }; // Green for Florida
        case 'New York':
            return { fillColor: "rgb(233 233 115 / 35%)", strokeColor: "rgb(233 233 115 / 35%)" }; // Yellow for New York
        default:
            return { fillColor: "#FF0000", strokeColor: "red" }; // Default to Red
    }
};
  const highlightedStates = ['Texas', 'California', 'Florida', 'New York'];
  const Map = () => {



    const markerData = [
      { position: { lat: 32.7767, lng: -96.7970 }, icon: image3, title: 'Animal Control' }, // Downtown Dallas
      { position: { lat: 32.7527, lng: -96.8100 }, icon: image, title: 'Recycle & Trash' }, // Near Klyde Warren Park
      { position: { lat: 32.9020, lng: -96.7887 }, icon: image6, title: 'Parking Issue' }, // Oak Cliff
      { position: { lat: 32.7768, lng: -96.7952 }, icon: image7, title: 'Public Safety' }, // Near Dallas City Hall
      { position: { lat: 32.7308, lng: -96.6975 }, icon: image3, title: 'Animal Control' }, // Highland Park
      { position: { lat: 32.6608, lng: -96.8245 }, icon: image6, title: 'Parking Issue' }, // Deep Ellum
      { position: { lat: 32.7827, lng: -96.8132 }, icon: image, title: 'Recycle & Trash' }, // North Dallas
      { position: { lat: 32.7821, lng: -96.7880 }, icon: image3, title: 'Animal Control' }, // Uptown Dallas
      { position: { lat: 32.8314, lng: -96.7724 }, icon: image6, title: 'Parking Issue' }, // Trinity Groves
      { position: { lat: 32.8121, lng: -96.8880 }, icon: image7, title: 'Public Safety' }, // West Dallas
      { position: { lat: 32.8463, lng: -96.7963 }, icon: image3, title: 'Animal Control' }, // Oak Lawn
      { position: { lat: 32.8555, lng: -96.8192 }, icon: image, title: 'Recycle & Trash' }, // Lakewood
      { position: { lat: 32.7931, lng: -96.7769 }, icon: image2, title: 'Noise Issue' }, // Forest Hills
      { position: { lat: 32.8667, lng: -96.8712 }, icon: image6, title: 'Parking Issue' }, // Pleasant Grove
      { position: { lat: 32.8372, lng: -96.8247 }, icon: image3, title: 'Animal Control' }, // Vickery Meadows
      { position: { lat: 32.7500, lng: -96.7790 }, icon: image7, title: 'Public Safety' }, // South Dallas
      { position: { lat: 32.8782, lng: -96.7884 }, icon: image2, title: 'Noise Issue' }, // Casa Linda
      { position: { lat: 32.8124, lng: -96.8799 }, icon: image6, title: 'Parking Issue' }, // North Oak Cliff
      { position: { lat: 32.7499, lng: -96.8128 }, icon: image3, title: 'Animal Control' }, // Cedars District
      { position: { lat: 32.8200, lng: -96.7658 }, icon: image7, title: 'Public Safety' }, // Bishop Arts District
      { position: { lat: 32.7369, lng: -96.7573 }, icon: image2, title: 'Noise Issue' }, // Grand Prairie
      { position: { lat: 32.8062, lng: -96.7476 }, icon: image6, title: 'Parking Issue' }, // Red Bird
      { position: { lat: 32.7512, lng: -96.8334 }, icon: image3, title: 'Animal Control' }, // Fair Park
      { position: { lat: 32.8504, lng: -96.7669 }, icon: image7, title: 'Public Safety' }, // Garland Road
      { position: { lat: 32.8167, lng: -96.7482 }, icon: image6, title: 'Parking Issue' }, // Lower Greenville
      { position: { lat: 32.8134, lng: -96.7591 }, icon: image3, title: 'Animal Control' }, // Lake Highlands
      { position: { lat: 32.7591, lng: -96.8051 }, icon: image, title: 'Recycle & Trash' }, // Northpark Center
      { position: { lat: 32.7742, lng: -96.7605 }, icon: image3, title: 'Animal Control' }, // Victory Park
      { position: { lat: 32.7724, lng: -96.7560 }, icon: image7, title: 'Public Safety' }, // Victory Park West
      { position: { lat: 32.7573, lng: -96.7988 }, icon: image3, title: 'Animal Control' }, // Dallas Design District
      { position: { lat: 32.7905, lng: -96.8104 }, icon: image7, title: 'Public Safety' }, // Arts District
      { position: { lat: 32.8532, lng: -96.7954 }, icon: image2, title: 'Noise Issue' }, // White Rock Lake
      { position: { lat: 32.7910, lng: -96.8000 }, icon: image6, title: 'Parking Issue' }, // Lakewood Heights
      { position: { lat: 32.7285, lng: -96.7850 }, icon: image7, title: 'Public Safety' }, // Bluffview
      { position: { lat: 32.7896, lng: -96.8232 }, icon: image3, title: 'Animal Control' }, // Lake Highlands North
      { position: { lat: 32.7661, lng: -96.7885 }, icon: image, title: 'Recycle & Trash' }, // Preston Hollow
      { position: { lat: 32.8305, lng: -96.8086 }, icon: image6, title: 'Parking Issue' }, // East Dallas
  ];



      const dallasBoundaryCoordinates = [
        { lat: 32.8343, lng: -96.7593 },
        { lat: 32.7903, lng: -96.8208 },
        { lat: 32.7566, lng: -96.8067 },
        { lat: 32.7203, lng: -96.8032 },
        { lat: 32.6811, lng: -96.8001 },
        { lat: 32.6399, lng: -96.8037 },
        { lat: 32.6114, lng: -96.8189 },
        { lat: 32.5764, lng: -96.8393 },
        { lat: 32.5445, lng: -96.8509 },
        { lat: 32.5224, lng: -96.8618 },
        { lat: 32.5082, lng: -96.8703 },
        { lat: 32.4707, lng: -96.8951 },
        { lat: 32.4562, lng: -96.9235 },
        { lat: 32.4388, lng: -96.9319 },
        { lat: 32.4215, lng: -96.9428 },
        { lat: 32.4052, lng: -96.9541 },
        { lat: 32.3965, lng: -96.9755 },
        { lat: 32.3904, lng: -96.9898 },
        { lat: 32.3827, lng: -97.0009 },
        { lat: 32.3715, lng: -97.0101 },
        { lat: 32.3623, lng: -97.0174 },
        { lat: 32.3494, lng: -97.0224 },
        { lat: 32.3377, lng: -97.0325 },
        { lat: 32.3239, lng: -97.0423 },
        { lat: 32.3079, lng: -97.0564 },
        { lat: 32.2978, lng: -97.0605 },
        { lat: 32.2885, lng: -97.0625 },
        { lat: 32.2824, lng: -97.0658 },
        { lat: 32.2708, lng: -97.0659 },
        { lat: 32.2633, lng: -97.0701 },
        { lat: 32.2567, lng: -97.0738 },
        { lat: 32.2494, lng: -97.0782 },
        { lat: 32.2374, lng: -97.0864 },
        { lat: 32.2267, lng: -97.0889 },
        { lat: 32.2184, lng: -97.0932 },
        { lat: 32.2103, lng: -97.0944 },
        { lat: 32.2023, lng: -97.0959 },
        { lat: 32.1917, lng: -97.0978 },
        { lat: 32.1809, lng: -97.0985 },
        { lat: 32.1691, lng: -97.0991 },
        { lat: 32.1573, lng: -97.0996 },
        { lat: 32.1454, lng: -97.0997 },
        { lat: 32.1344, lng: -97.0995 },
        { lat: 32.1229, lng: -97.0992 },
        { lat: 32.1106, lng: -97.0987 },
        { lat: 32.0995, lng: -97.0980 },
        { lat: 32.0883, lng: -97.0971 },
        { lat: 32.0782, lng: -97.0961 },
        { lat: 32.0684, lng: -97.0950 },
        { lat: 32.0597, lng: -97.0939 },
        { lat: 32.0524, lng: -97.0928 },
        { lat: 32.0453, lng: -97.0917 },
        { lat: 32.0387, lng: -97.0905 },
        { lat: 32.0325, lng: -97.0894 },
        { lat: 32.0266, lng: -97.0881 },
        { lat: 32.0210, lng: -97.0868 },
        { lat: 32.0157, lng: -97.0855 },
        { lat: 32.0107, lng: -97.0841 }
      ];

      const [map, setMap] = useState(null);

    const nightModeStyles = [
        { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#263c3f' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#38414e' }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#17263c' }],
        },
      ];
      const mapOptions = {
        mapTypeId: "satellite",
        styles:  nightModeStyles ,
        disableDefaultUI: false,
        zoomControl: true,
      };
      const mapOptions1 = {

        styles:  nightModeStyles ,
        disableDefaultUI: false,
        zoomControl: true,
      };

      const handleMapClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        console.log(`Clicked location: Latitude ${lat}, Longitude ${lng}`);
      };
      const mapRef = useRef(null);

      const onLoad = useCallback(async(map) => {

        // setMap(map);
        // mapRef.current = map;

        // if (map) {


        //   new google.maps.Polygon({
        //     paths: dallasBoundaryCoordinates,
        //     strokeColor: "#FF0000",
        //     strokeOpacity: 0.8,
        //     strokeWeight: 2,
        //     fillColor: "#FF0000",
        //     fillOpacity: 0.35,
        //     map: map,
        //   });
        //     // GeoJSON for US states (outer boundaries only)

        //     map.data.loadGeoJson(
        //         'https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/us-states.json'
        //     );

        //     // Highlight selected states
        //     map.data.setStyle((feature) => {
        //         const stateName = feature.getProperty('name');
        //         const { fillColor, strokeColor } = getPolylineColor(stateName);
        //         return {
        //             fillColor: highlightedStates.includes(stateName) ? fillColor : 'transparent',
        //             strokeColor: highlightedStates.includes(stateName) ? strokeColor : 'transparent',
        //             strokeWeight: highlightedStates.includes(stateName) ? 1 : 0,
        //             fillOpacity: highlightedStates.includes(stateName) ? 0.5 : 0,
        //         };
        //     });






        //   }
    }, []);


    const cityCoordinates = [
      { lat: 37.7749, lng: -122.4194 }, // Example: San Francisco
      { lat: 37.7749, lng: -122.3894 },
      { lat: 37.7549, lng: -122.3894 },
      { lat: 37.7549, lng: -122.4194 },
    ];

    return (
    <div className='row m-5'>

        <div className='col-md-6 bg-gradient'>
        <div className="heading-content mt-2">
                    <h5>Issue Hotspot Detection</h5>
                  </div>

        <GoogleMap mapContainerStyle={containerStyle} center={{ lat: 32.8305, lng: -96.8086 }} zoom={12}   options={mapOptions1}  onLoad={onLoad} >
        <Marker position={center} />
        {markerData.map((marker, index) => (
            <Marker
              key={index}
              position={marker.position}
              title={marker.title}
              icon={{
                url: marker.icon,
                scaledSize: new window.google.maps.Size(30, 30), // Resize icon
              }}
            />
          ))}















      </GoogleMap>

        </div>
        <div className='col-md-6 bg-gradient'>
        <div className="heading-content mt-2">
                    <h5>Issue Hotspot Detection</h5>
                  </div>

      <GoogleMap mapContainerStyle={containerStyle}  center={{ lat: 32.8305, lng: -96.8086 }} zoom={12}    onLoad={onLoad}  onClick={handleMapClick}>
        <Marker position={center}  title="asdasdsa"/>
        {markerData.map((marker, index) => (
  <Marker
    key={index}
    title={marker.title}
    position={marker.position}
    icon={{
      url: marker.icon,
      scaledSize: new window.google.maps.Size(30, 30),
      anchor: new window.google.maps.Point(15, 15), // Center the icon
    }}
  >


  </Marker>
))}













      </GoogleMap>


        </div>
    </div>

    );
  };

  export default Map;

