import React, { useState, useEffect, useRef,useContext } from "react";
import axios from "axios";
import Axios from "../../Helpers/Interceptors";
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Line,
  Legend,
  Cell,
  Label,
  BarChart,
  LineChart,
  AreaChart,
  Area,Sector
} from "recharts";
import {
  Table,
  Button,
  Space,
  Select,
  DatePicker,
  TimePicker,
  Tabs,
  Tag,
  Card,
} from "antd";
import "./Dashboard.scss";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin
import utc from "dayjs/plugin/utc";
import { useNavigate } from "react-router-dom";
import CallTickets from '../Tickets/CallTickets'
import dayjs from "dayjs";
import {getRole} from '../../Helpers/utils'
import { UserInfo } from "../../Context/userInfo";
import { object } from "prop-types";
import Map from "../Map/Map";

const calculateSeverityScore = () => {
  // Dummy AI logic: Assume an incident gets a severity score out of 100
  return Math.floor(Math.random() * 100); // Random score between 0-100
};

const sampleData = [
  { type: "Pothole Repair", estimated: 24, actual: 30 },
  { type: "Street Light Fix", estimated: 12, actual: 14 },
  { type: "Tree Removal", estimated: 48, actual: 60 },
  { type: "Water Leak", estimated: 8, actual: 9 },
];

// Function to generate a simple AI-predicted resolution time (basic average-based logic)
const predictResolutionTime = (type) => {
  const item = sampleData.find((d) => d.type === type);
  return item ? (item.estimated + item.actual) / 2 : 10; // Simple avg for prediction
};

const getNeedleCoords = (score, radius, centerX, centerY) => {
  const angle = 180 - (score / 100) * 180; // Convert score to angle (180° to 0°)
  const radians = (angle * Math.PI) / 180; // Convert to radians

  return {
    x: centerX + radius * Math.cos(radians),
    y: centerY - radius * Math.sin(radians),
  };
};

const Dashboard = () => {
  const columns = [
    {
      title: "Request Type",
      dataIndex: "request_type",
      key: "request_type",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (record) => (
        <div
          style={{
            color:
              record === "High"
                ? "red"
                : record === "Medium"
                ? "orange"
                : (record === "Low" || !record)
                ? "green"
                : "black", // Default color if none matches
            fontWeight: "bold",
          }}
        >
          {record ? record : 'Low'}
        </div>
      ),
    },

    // {
    //   title: "Created At",
    //   dataIndex: "createdAt",
    //   key: "createdAt",

    //   render: (record) => formatDateUS(record),
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "address",
      align: "center",
      render: (_, record) => (
        <div  onClick={() => navigate(`/ticket-detail/${record.ticketNumber}`)}
        style={{ cursor: "pointer" }}>
          <i className="bx bx-show" style={{ fontSize: "20px" }}></i>
        </div>
      ),
    },
  ];


  const columns2 = [
    {
      title: "Request Type",
      dataIndex: "request_type",
      key: "request_type",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (record) => (
        <div
          style={{
            color:
              record === "High"
                ? "red"
                : record === "Medium"
                ? "orange"
                : (record === "Low" || !record)
                ? "green"
                : "black", // Default color if none matches
            fontWeight: "bold",
          }}
        >
          {record ? record : 'Low'}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",


    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",

      render: (record) => formatDateUS(record),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "address",
      align: "center",
      render: (_, record) => (
        <div  onClick={() => navigate(`/ticket-detail/${record.ticketNumber}`)}
        style={{ cursor: "pointer" }}>
          <i className="bx bx-show" style={{ fontSize: "20px" }}></i>
        </div>
      ),
    },
  ];
  const navigate = useNavigate();
  const [list, setlist] = useState([]);
  const [list1, setlist1] = useState([]);
  const [graphCount,setgraphCount] = useState({})
  const [severityScore, setSeverityScore] = useState(0);
  const [secondGraph, setSecondGraph] = useState(sampleData);


  const CHARTSCOLORS = ["#00C49F", "#FFBB28", "#FF4848"]; // Green, Yellow, Red
  const centerX = 200; // Adjust according to your chart width
  const centerY = 180; // Move down to match Pie center
  const needleLength = 80; // Length of the needle
  const needleCoords = getNeedleCoords(severityScore, needleLength, centerX, centerY);

  const { userData, updateUserData } = useContext(UserInfo);
  const [roles,setRoles]=useState(null)
  const data = [
    { category: "Vegetation Overgrowth", SAM: 10, MaskRCNN: 5, YOLO: 8 },
    { category: "Road Damage", SAM: 25, MaskRCNN: 10, YOLO: 15 },
    { category: "Tree Damage", SAM: 20, MaskRCNN: 15, YOLO: 10 },

    { category: "Litter", SAM: 30, MaskRCNN: 25, YOLO: 20 },


  ];

  const data1 = [
    {
      name: "Page A",
      uv: 40,
      pv: 24,
      amt: 24,
    },
    {
      name: "Page B",
      uv: 30,
      pv: 13,
      amt: 22,
    },
    {
      name: "Page C",
      uv: 20,
      pv: 98,
      amt: 22,
    },
    {
      name: "Page D",
      uv: 27,
      pv: 39,
      amt: 20,
    },
    {
      name: "Page E",
      uv: 18,
      pv: 48,
      amt: 21,
    },
    {
      name: "Page F",
      uv: 23,
      pv: 38,
      amt: 25,
    },
    {
      name: "Page G",
      uv: 34,
      pv: 43,
      amt: 21,
    },
  ];

  const severityLevels = [
    { name: "Low", value: 30, color: "#00C49F" }, // Green
    { name: "Medium", value: 40, color: "#FFBB28" }, // Yellow
    { name: "High", value: 30, color: "#FF4848" }, // Red
  ];

  const data02 = [
    { name: "Positive", value: 2400 },
    { name: "Neutral", value: 4567 },
    { name: "Negative", value: 1398 },
  ];
  const data4 = [
    { date: "Oct", originalReports: 5, duplicates: 2 },
  { date: "Nov", originalReports: 3, duplicates: 1 },
  { date: "Dec", originalReports: 7, duplicates: 3 },
  { date: "Jan", originalReports: 4, duplicates: 2 },
  { date: "Feb", originalReports: 6, duplicates: 5 },
  { date: "Mar", originalReports: 0, duplicates: 0 },
  ];
  const data5 = [
    { department: "Parks & Recreation", resolutionRate: 75, satisfaction: 4.0 },
    { department: "Sanitation", resolutionRate: 85, satisfaction: 4.2 },
    { department: "Roadwork", resolutionRate: 70, satisfaction: 3.8 },
    { department: "Utilities", resolutionRate: 90, satisfaction: 4.5 },


  ];


  const tabs = [
    {
      id: 1,
      label: `AI Graphical Insights`,
      content: (
        <>
          {" "}
          <div
            className="signup-content2 "
            style={{ paddingLeft: "2%", paddingRight: "0.5%", paddingTop: "1%" }}
          >
            <div className="row mt-3">
              <div className="col-md-3">
                <Card
                  className="custm-card parent-div3"
                  style={{ position: "relative" }}
                >
                  <div className="custm-content">
                    <h4>{graphCount.totalReportedIncidents }</h4>
                    <h7>Reported Tickets </h7>
                  </div>
                  <div className="d-flex cstm-btns justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                      <p style={{color: 'Red'}}>High</p>
                      <p>{graphCount.priorityHigh }</p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p style={{color: 'Orange'}}>Medium</p>
                      <p>{graphCount.priorityMedium}</p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p style={{color: 'green'}}>Low</p>
                      <p>{graphCount.priorityLow }</p>
                    </div>
                  </div>

                  {/* <div className="bottom-line-text">


                  </div> */}
                  <div
                    className="child-div3"
                    style={{
                      background:
                        "linear-gradient(135deg, #3399ff 0%, #66ccff 100%)",
                    }}
                  >
                    <i className="bx bx-message-square-detail cusotm-ixon"></i>
                  </div>
                </Card>
              </div>

              <div className="col-md-3">
                <Card
                  className="custm-card parent-div3"
                  style={{ position: "relative" }}
                >
                  <div className="custm-content">
                    <h4>{graphCount.inProgress }</h4>
                    <h7>In Progresss</h7>
                  </div>
                  <div className="d-flex cstm-btns justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                  </div>

                  <div className="bottom-line-text"></div>
                  <div
                    className="child-div3"
                    style={{
                      background:
                        "linear-gradient(135deg, #e55353 0%, #ff7b7b 100%)",
                    }}
                  >
                    <i className="bx bx-message-square-detail cusotm-ixon"></i>
                  </div>
                </Card>
              </div>

              <div className="col-md-3">
                <Card
                  className="custm-card parent-div3 bg-gradient"
                  style={{ position: "relative" }}
                >
                  <div className="custm-content">
                    <h4>{graphCount.assignedToDpt }</h4>
                    <h7>Assign To Department</h7>
                  </div>
                  <div className="d-flex cstm-btns justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                  </div>

                  <div className="bottom-line-text"></div>
                  <div
                    className="child-div3"
                    style={{
                      background:
                        "linear-gradient(135deg,#f76b1c 0%,  #f9b115 100%)",
                    }}
                  >
                    <i className="bx bx-wallet cusotm-ixon"></i>
                  </div>
                </Card>
              </div>
              <div className="col-md-3">
                <Card
                  className="custm-card parent-div3"
                  style={{ position: "relative" }}
                >
                  <div className="custm-content">
                    <h4>{graphCount.resolved }</h4>
                    <h7>Resolved </h7>
                  </div>
                  <div className="d-flex cstm-btns justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                  </div>
                  <div className="bottom-line-text"></div>
                  <div
                    className="child-div3"
                    style={{
                      background:
                        "linear-gradient(135deg, rgb(34, 193, 195) 0%, rgb(45, 253, 180) 100%)",
                    }}
                  >
                    <i className="bx bx-wallet cusotm-ixon"></i>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div className="signup-content2 ">
            <div className="row">
              <div className="col-9">
              <div
                  className="row  "
                  style={{ marginTop: "2%", marginLeft: "2%" }}
                >
                  <div className="col-md-4 ">
                    <div className="bg-gradient card-padding rounded">
                      <div className="heading-content">
                        <h5>Service Efficiency Metrics</h5>
                      </div>
                      <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data5} >
      <XAxis
  dataKey="department"
  tick={{
    angle: 25,  // Reduce the angle for better readability
    textAnchor: 'middle',
    fontSize: 12
  }}
  tickLine={false}
  interval={0}
  padding={{ bottom: 20 }}
/>
        <YAxis />
        <Tooltip />
        <Legend
      verticalAlign="top"
      align="center"
      layout="horizontal" wrapperStyle={{ marginBottom: '10px', padding: '5px' }}    // Adjusted layout for legend
     // Added top padding to increase space between legend and chart
    />
        <Bar dataKey="resolutionRate" fill="#82ca9d" name="Resolution Rate (%)" />
        <Bar dataKey="satisfaction" fill="#8884d8" name="User Satisfaction (1-5)" />
      </BarChart>
    </ResponsiveContainer>

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="bg-gradient card-padding rounded">
                      <div className="heading-content">
                        <h5>Similar Image Matching</h5>
                      </div>
                      <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data4} >
        <XAxis dataKey="date" tick={{ angle: 45, textAnchor: 'start', fontSize: 12 }} // Adjust font size here
              tickLine={false} // Removes tick lines, adding more space for the labels
              interval={0}/>
        <YAxis />
        <Tooltip />
        <Legend  verticalAlign="top"
      align="center"
      layout="horizontal" wrapperStyle={{ marginBottom: '10px', padding: '5px' }}  />
        <Line type="monotone" dataKey="originalReports" stroke="#8884d8" name="Original Reports" />
        <Line type="monotone" dataKey="duplicates" stroke="#82ca9d" name="Duplicate Reports" />
      </LineChart>
    </ResponsiveContainer>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="bg-gradient card-padding rounded">
                      <div className="heading-content">
                        <h5>Sentiment Analysis</h5>
                      </div>

                      <ResponsiveContainer width="97%" height={300} style={{padding:'5%'}}>
                        <PieChart >
                          <Pie
                            data={data02}
                            cx="50%"
                            cy="50%"

                            fill="#8884d8"
                            dataKey="value"
                            label
                          >
                            {data02.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={CHARTSCOLORS[index]}
                              />
                            ))}
                          </Pie>

                          <Tooltip />
                          <Legend  verticalAlign="top"
      align="center"
      layout="horizontal" wrapperStyle={{ marginBottom: '10px', padding: '5px' }}  />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
                <div
                  className="row "
                  style={{ marginTop: "2%", marginLeft: "2%" }}
                >
                  <div className="col-md-4">
                    <div className="bg-gradient card-padding rounded">
                      <div className="heading-content">
                        <h5>Environmental Feature Detection</h5>
                      </div>

                      <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} margin={{ top: 0, right: 30, left: 20, bottom: 0 }}>
        <XAxis dataKey="category"   tick={{ angle: 45, textAnchor: 'start', fontSize: 12 }} // Adjust font size here
              tickLine={false} // Removes tick lines, adding more space for the labels
              interval={0} />
        <YAxis />
        <Tooltip />
        <Legend  verticalAlign="top"
      align="center"
      layout="horizontal" wrapperStyle={{ marginBottom: '10px', padding: '5px' }}  />
        <Bar dataKey="SAM" stackId="a" fill="#82ca9d" name="SAM Detection" />
        <Bar dataKey="MaskRCNN" stackId="a" fill="#8884d8" name="Mask R-CNN Detection" />
        <Bar dataKey="YOLO" stackId="a" fill="#ff7300" name="YOLO Detection" />
      </BarChart>
    </ResponsiveContainer>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="bg-gradient card-padding rounded">
                      <div className="heading-content">
                        <h5>Resolution Time Estimation</h5>
                      </div>

                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          data={secondGraph}
                          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          <XAxis dataKey="type" tick={{ angle: 45, textAnchor: 'start', fontSize: 12 }} // Adjust font size here
              tickLine={false} // Removes tick lines, adding more space for the labels
              interval={0}/>
                          <YAxis />
                          <Tooltip />
                          <Legend  verticalAlign="top"
      align="center"
      layout="horizontal" wrapperStyle={{ marginBottom: '10px', padding: '5px' }}  />
                          <Bar
                            dataKey="estimated"
                            fill="#8884d8"
                            name="Estimated Time"
                          />
                          <Bar
                            dataKey="actual"
                            fill="#82ca9d"
                            name="Actual Time"
                          />
                          <Bar
                            dataKey="predicted"
                            fill="#ff7300"
                            name="AI Predicted Time"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="bg-gradient card-padding rounded custpichart">
                      <div className="heading-content">
                        <h5>Incident Severity Score: {severityScore}</h5>
                      </div>

                      <ResponsiveContainer width="100%" height={300} className="chart-container">
  <PieChart>
    {/* Gauge Pie */}
    <Pie
      data={severityLevels}
      dataKey="value"
      cx="50%" // Center X
      cy="50%" // Center Y
      innerRadius={70}
      outerRadius={100}
      startAngle={180}
      endAngle={0}
    >
      {severityLevels.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={entry.color} />
      ))}
    </Pie>

    {/* Needle */}
    <svg width="100%" height="100%">
      <line
        x1="50%" // Start at center
        y1="50%"
        x2={needleCoords.x} // End at calculated point
        y2={needleCoords.y}
        stroke="black"
        strokeWidth="3"
      />
      <circle cx="50%" cy="50%" r="5" fill="black" /> {/* Needle Base */}
    </svg>

    <Tooltip />
    <Legend
      verticalAlign="top"
      align="center"
      layout="horizontal"
      wrapperStyle={{ marginBottom: "10px", padding: "5px" }}
    />
  </PieChart>
</ResponsiveContainer>


                      {/* Severity Legend */}

                    </div>
                  </div>
                </div>


              </div>
              <div className="col-3" style={{ marginTop: "1.5%" }}>
                <div className="bg-gradient p-2 rounded custm-table">
                  <div className="heading-content">
                    <h5>Recent Tickets</h5>
                  </div>
                  <Table
                    dataSource={list}
                    columns={columns}
                    rowClassName={() => "custom-row"}
                    scroll={{ x: "max-content" ,y: 500}}
                    pagination={false}
                  />
                  ;
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },

    {
      id: 3,
      label: `Real-Time 311 Incident Monitoring`,
      content: (
        <div className="signup-content2 mb-2">
         {/* <CallTickets/> */}

         <>
          <div
            className="signup-content2 "
            style={{
              paddingLeft: "2%",
              paddingRight: "2%",
              paddingTop: "1%",
              paddingBottom: "2%",
            }}
          >
           <div className="row mt-3">
              <div className="col-md-3">
                <Card
                  className="custm-card parent-div3"
                  style={{ position: "relative" }}
                >
                  <div className="custm-content">
                    <h4>{graphCount.totalReportedIncidents }</h4>
                    <h7>Reported Tickets </h7>
                  </div>
                  <div className="d-flex cstm-btns justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                      <p style={{color: 'Red'}}>High</p>
                      <p>{graphCount.priorityHigh }</p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p style={{color: 'Orange'}}>Medium</p>
                      <p>{graphCount.priorityMedium}</p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p style={{color: 'green'}}>Low</p>
                      <p>{graphCount.priorityLow }</p>
                    </div>
                  </div>

                  {/* <div className="bottom-line-text">


                  </div> */}
                  <div
                    className="child-div3"
                    style={{
                      background:
                        "linear-gradient(135deg, #3399ff 0%, #66ccff 100%)",
                    }}
                  >
                    <i className="bx bx-message-square-detail cusotm-ixon"></i>
                  </div>
                </Card>
              </div>

              <div className="col-md-3">
                <Card
                  className="custm-card parent-div3"
                  style={{ position: "relative" }}
                >
                  <div className="custm-content">
                    <h4>{graphCount.inProgress }</h4>
                    <h7>In Progresss</h7>
                  </div>
                  <div className="d-flex cstm-btns justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                  </div>

                  <div className="bottom-line-text"></div>
                  <div
                    className="child-div3"
                    style={{
                      background:
                        "linear-gradient(135deg, #e55353 0%, #ff7b7b 100%)",
                    }}
                  >
                    <i className="bx bx-message-square-detail cusotm-ixon"></i>
                  </div>
                </Card>
              </div>

              <div className="col-md-3">
                <Card
                  className="custm-card parent-div3 bg-gradient"
                  style={{ position: "relative" }}
                >
                  <div className="custm-content">
                    <h4>{graphCount.assignedToDpt }</h4>
                    <h7>Assign To Department</h7>
                  </div>
                  <div className="d-flex cstm-btns justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                  </div>

                  <div className="bottom-line-text"></div>
                  <div
                    className="child-div3"
                    style={{
                      background:
                        "linear-gradient(135deg,#f76b1c 0%,  #f9b115 100%)",
                    }}
                  >
                    <i className="bx bx-wallet cusotm-ixon"></i>
                  </div>
                </Card>
              </div>
              <div className="col-md-3">
                <Card
                  className="custm-card parent-div3"
                  style={{ position: "relative" }}
                >
                  <div className="custm-content">
                    <h4>{graphCount.resolved }</h4>
                    <h7>Resolved </h7>
                  </div>
                  <div className="d-flex cstm-btns justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p></p>
                      <p></p>
                    </div>
                  </div>
                  <div className="bottom-line-text"></div>
                  <div
                    className="child-div3"
                    style={{
                      background:
                        "linear-gradient(135deg, rgb(34, 193, 195) 0%, rgb(45, 253, 180) 100%)",
                    }}
                  >
                    <i className="bx bx-wallet cusotm-ixon"></i>
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div className="row m-3 ">
            <div className="col-6">
              <div
                className="bg-gradient p-2 rounded"
                style={{ position: "relative" }}
              >
                 <div
          className="card  mb-3  p-2"
          style={{
            background:
              "rgb(5, 162, 179)",
            textAlign: "center",
          }}
        >
          <h5 style={{ color: "whitesmoke" }}>Active Tickets & Incidents</h5>
        </div>
                <Table
                  dataSource={list}
                  columns={columns2}
                  rowClassName={() => "custom-row"}
                  scroll={{ y: 350 }}
                  // scroll={{ y: "10%" }} // Set min height for table to 10 rows
                />
                {/* <div
                  className="table-header"
                  style={{
                    background: "linear-gradient(60deg, #7b1fa2, #913f9e)",
                  }}
                >
                  <h4 style={{  fontFamily: 'Myriad Pro',}}>Active Tickets & Incidents</h4>
                </div> */}
                ;
              </div>
            </div>
            <div className="col-6">
              <div
                className="bg-gradient p-2 rounded"
                style={{ position: "relative" }}
              >
                 <div
          className="card  mb-3  p-2"
          style={{
            background:
              "rgb(5, 162, 179)",
            textAlign: "center",
          }}
        >
          <h5 style={{ color: "whitesmoke" }}> Guest User Tickets </h5>
        </div>
                <Table
                  dataSource={list}
                  columns={columns2}
                  rowClassName={() => "custom-row"}
                  scroll={{ y: 350 }}
                  // scroll={{ y: "10%" }} // Set min height for table to 10 rows

                />
                {/* <div
                  className="table-header"
                  style={{
                    background:
                      "linear-gradient(135deg, #3399ff 0%, #66ccff 100%)",
                  }}
                >
                  <h4 style={{  fontFamily: 'Myriad Pro',}}>Guest User Tickets</h4>
                </div> */}
                ;
              </div>
            </div>
          </div>
          </>
        </div>
      ),
    },

    { id: 2, label: `GIS Map-based visualization`, content: <Map></Map> },
  ];





  useEffect(() => {

    if(userData){

      setRoles( getRole(userData.data?.userInfo?.roles))

      if(getRole(userData.data?.userInfo?.roles) != 'Admin'){
        tabs.splice(1,1)
      }
    }

    fetchData();
    fetchData1()
    const newData = sampleData.map((d) => ({
      ...d,
      predicted: predictResolutionTime(d.type),
    }));
    setSecondGraph(newData);

    setSeverityScore(calculateSeverityScore());
  }, [userData]);







  const fetchData = async () => {

   let url = (getRole(userData?.data?.userInfo?.roles) == 'Admin'? 'incidents/all' : 'agents/assigned-tickets')
   console.log(url)
    try {
      const response = await Axios.get(
        url
      );
      console.log(response)



        if(getRole(userData.data?.userInfo?.roles) == 'Admin'){
          console.log(response.data.incidents)
          setlist(response.data.incidents);
          setgraphCount(response.data)

        }else{
          setlist(response.data.incidents);
          setgraphCount(response.data)
        }



    } catch (err) {
      console.log(err.message);
    } finally {
      console.log(false);
    }


  };
  
  const fetchData1 = async () => {
    try {
      const response = await Axios.get(
        "incidents/today"
      );

      console.log(response.data);
      setlist1(response.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log(false);
    }


  };




  const formatDateUS = (date) => {
    return new Date(date).toLocaleDateString("en-US");
  };

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];


  return (
    <div className="container-fluid" >
      <div className={`heading-content ${roles == 'Admin' ? '' : 'custm'}`} >
        <Tabs>
          {tabs.map((tab) => (
            <Tabs.TabPane key={tab.id} tab={tab.label} style={{ width: "50%" }}>
              {" "}
              {tab.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Dashboard;
