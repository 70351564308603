

export  const getLogoName =()=>{
  return localStorage.getItem('Logo')
}



export const eventEmitter = new EventTarget();

// Utility function to emit an event
export const emitEvent = (eventName, detail) => {
 const event = new CustomEvent(eventName, {
   detail, // Add event data here
 });
 eventEmitter.dispatchEvent(event); // Emit the event
};

// Utility function to listen for an event
export const listenToEvent = (eventName, callback) => {
 eventEmitter.addEventListener(eventName, callback);
};

// Utility function to remove the listener
export const removeListener = (eventName, callback) => {
 eventEmitter.removeEventListener(eventName, callback);
};


export const dateFormat = (date) => {

 // If the input is a Date object, convert it to a string format "YYYY-MM-DD"
 if (date instanceof Date) {
   date = date.toISOString().split('T')[0]; // Get date portion (YYYY-MM-DD)
 }

 // Ensure the date is in "YYYY-MM-DD" format
 const [year, month, day] = date.split('-');

 // Use UTC methods to ensure that time zone does not affect the date
 const parsedDate = new Date(Date.UTC(year, month - 1, day));

 // Format the date as MM-DD-YYYY
 const monthFormatted = String(parsedDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based
 const dayFormatted = String(parsedDate.getUTCDate()).padStart(2, '0');
 const yearFormatted = parsedDate.getUTCFullYear(); // Full year

 return `${monthFormatted}-${dayFormatted}-${yearFormatted}`;
};


export const  allowNumber = (event) => {
   // Allow only digits and update the form field value
   const key = event.key;
   if (!/[0-9]/.test(key)) {
     event.preventDefault(); // Prevent non-numeric input
   }
 };



 export const areAllKeysEmpty=(obj)=> {
   return Object.keys(obj).every(key => {
     const value = obj[key];
     // Check if value is empty (null, undefined, empty string, empty array, or empty object)
     return value === null || value === undefined || value === '' ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'object' && Object.keys(value).length === 0);
   });
 }

 export const removeLocaStorage=()=>{
 localStorage.removeItem('loginTime')
       localStorage.removeItem('userData')
       localStorage.removeItem('token')
}

export const getRole = (roles) => {
  const hasAdmin = roles?.some(role => role.name === "ADMIN");
  const hasAgent = roles?.some(role => role.name === "AGENT");

  if (hasAdmin && hasAgent) {
      return "Admin";  // Return 'admin' if both are present
  }

  if (hasAdmin) {
      return "Admin";  // Return 'admin' if only admin is present
  }

  if (hasAgent) {
      return "Agent";  // Return 'agent' if only agent is present
  }

  return null;  // Return null if neither are present
};

 export  const coordinates = [
   {lat:-94.5521800000544, lng:36.1022360001623},
   {lat:-94.5862000002629, lng:36.2999690003058},
   {lat:-94.6177559998005, lng:36.4994610003194},
   {lat:-92.7900600002039, lng:36.4976449997916},
   {lat:-92.5168359997772, lng:36.4987379998015},
   {lat:-92.3751589997513, lng:36.4971989997664},
   {lat:-91.6077230000786, lng:36.4995700002154},
   {lat:-91.4071379997261, lng:36.4970810000618},
   {lat:-90.818314999972, lng:36.4986889997449},
   {lat:-90.1522800004256, lng:36.4979500003182},
   {lat:-90.1547340001525, lng:36.4967389997923},
   {lat:-90.1534500003876, lng:36.4930480003001},
   {lat:-90.1600070003775, lng:36.4942570003581},
   {lat:-90.1561509999867, lng:36.4890969999826},
   {lat:-90.1564859997224, lng:36.4877039997202},
   {lat:-90.1601360002489, lng:36.4870339999385},
   {lat:-90.1571579997421, lng:36.4844009997162},
   {lat:-90.1596270004359, lng:36.4803019997107},
   {lat:-90.1499220000599, lng:36.4765650002349},
   {lat:-90.1438119999588, lng:36.4759069997354},
   {lat:-90.142412999668, lng:36.4715010000063},
   {lat:-90.1459159998813, lng:36.4689539996658},
   {lat:-90.1528899996227, lng:36.4709309997565},
   {lat:-90.1558039996571, lng:36.4635539998117},
   {lat:-90.1507000003848, lng:36.4655109996477},
   {lat:-90.1446200004209,lng: 36.462788999693},
   {lat:-90.1445889995605, lng:36.4675890001614},
   {lat:-90.1424939998588, lng:36.4679059998831},
   {lat:-90.1404319997691, lng:36.4645689999717},
   {lat:-90.1409620003968, lng:36.4585380002054},
   {lat:-90.1339390002517, lng:36.4535969997771},
   {lat:-90.1372650000147, lng:36.4503100000734},
   {lat:-90.1344029998589, lng:36.4359840000702},
   {lat:-90.138861999774, lng:36.4351159997236},
   {lat:-90.1377000002079, lng:36.4318380001887},
   {lat:-90.1429240000291,lng: 36.430957000168},
   {lat:-90.1442770002892, lng:36.4259179999205},
   {lat:-90.1386040000312, lng:36.4211540000215},
   {lat:-90.1343099999725, lng:36.4228000000954},
   {lat:-90.1350330000457, lng:36.4168159998151},
   {lat:-90.1385739998939, lng:36.4138170002336},
   {lat:-90.1339219996349, lng:36.4138570001002},
   {lat:-90.1274900002169, lng:36.4166670000825},
   {lat:-90.1265960004394, lng:36.4155919997024},
   {lat:-90.1285909996836, lng:36.4114250002594},
   {lat:-90.12155099982, lng:36.4108349999201},
   {lat:-90.118917999993, lng:36.4125379999467},
   {lat:-90.1183870004387, lng:36.4094130003469},
   {lat:-90.1154709998562, lng:36.4079900001758},
   {lat:-90.1135569999045,lng: 36.409509000085},
   {lat:-90.1156900001393, lng:36.4059060002365},
   {lat:-90.1108069999016, lng:36.4077789999114},
   {lat:-90.1108139995743, lng:36.4047850002088},
   {lat:-90.1093520004326, lng:36.4037839999526},
   {lat:-90.1045920002185, lng:36.4055050002851},
   {lat:-90.1015829997496, lng:36.4020250002903},
   {lat:-90.0983470003982, lng:36.4040220003478},
   {lat:-90.081407000165, lng:36.4010740001991},
   {lat:-90.0742450001027, lng:36.3930660001869},
   {lat:-90.0703529997269, lng:36.3934669999698},
   {lat:-90.068961999832, lng:36.3882970002032},
   {lat:-90.0657690001383,lng: 36.387463000146},
   {lat:-90.0681259997808, lng:36.3851490003395},
   {lat:-90.0633970004272, lng:36.3839569996969},
   {lat:-90.0667449999316, lng:36.3780060002861},
   {lat:-90.062738999753, lng:36.3772600003172},
   {lat:-90.0647260003979, lng:36.3689879999274},
   {lat:-90.0634080002979, lng:36.3670160003314},
   {lat:-90.0663599999671, lng:36.3651339998523},
   {lat:-90.0660519999955, lng:36.3599439997777},
   {lat:-90.0680580000087, lng:36.3565650002911},
   {lat:-90.0705119997356, lng:36.3562670003094},
   {lat:-90.0758100000749, lng:36.3498349997282},
   {lat:-90.0780429998688, lng:36.3491409997384},
   {lat:-90.0742099999426, lng:36.3425169998262},
   {lat:-90.0771700000077, lng:36.3411490001545},
   {lat:-90.0782860004414, lng:36.3367199997528},
   {lat:-90.0755740000734, lng:36.3342139997158},
   {lat:-90.0790699997157,lng: 36.327737000318},
   {lat:-90.081912999605, lng:36.3264570002429},
   {lat:-90.0798580000863, lng:36.3234950003538},
   {lat:-90.0824070002477, lng:36.3219489998708},
   {lat:-90.0798499996904, lng:36.3183889996693},
   {lat:-90.0766129996158,lng: 36.319213000332},
   {lat:-90.0695490003611,lng: 36.313261000245},
   {lat:-90.064169000006,lng: 36.302526000116},
   {lat:-90.0693180003824, lng:36.2985880000806},
   {lat:-90.0714209995817,lng: 36.293174000144},
   {lat:-90.0790510003475,lng: 36.289851000194},
   {lat:-90.0757270002343,lng: 36.281065999727},
   {lat:-90.079836000345, lng:36.2785240003179},
   {lat:-90.0835289996309, lng:36.2721220002691},
   {lat:-90.0960049999261, lng:36.2713420003214},
   {lat:-90.0995039999415, lng:36.2689470000768},
   {lat:-90.1028129999861,lng: 36.268981000004},
   {lat:-90.1065379999573, lng:36.2658589998357},
   {lat:-90.1104110000665, lng:36.2668339998384},
   {lat:-90.1168009996518,lng: 36.263770999759},
   {lat:-90.1160230002252, lng:36.2616010000033},
   {lat:-90.1174280003639, lng:36.2582760002845},
   {lat:-90.1192860002391,lng: 36.257715000203},
   {lat:-90.118076999919,lng: 36.255596000206},
   {lat:-90.1198939997863, lng:36.2546559997641},
   {lat:-90.1183740000199, lng:36.2532730002479},
   {lat:-90.1223049998554, lng:36.2492560001148},
   {lat:-90.1219070003705, lng:36.2467850000915},
   {lat:-90.1246339999088, lng:36.2444750000209},
   {lat:-90.1236279999783, lng:36.2434219999764},
   {lat:-90.1297160003381, lng:36.2432349998633},
   {lat:-90.1309480002244, lng:36.2413769997331},
   {lat:-90.1277699997011, lng:36.2396680001452},
   {lat:-90.127705000302, lng:36.2370259998324},
   {lat:-90.1247350001912, lng:36.2352929998542},
   {lat:-90.1265289995939, lng:36.2331979998869},
   {lat:-90.1263360001484, lng:36.2291230000513},
   {lat:-90.1311819996778,lng: 36.228693999729},
   {lat:-90.1331030002005, lng:36.2261110002368},
   {lat:-90.1422379997658,lng: 36.227521999701},
   {lat:-90.144134999999, lng:36.2234510000325},
   {lat:-90.1459130004066,lng: 36.223152999965},
   {lat:-90.1456029998869, lng:36.2211409996585},
   {lat:-90.1490490001988, lng:36.2216209997511},
   {lat:-90.152153000204, lng:36.2157469997821},
   {lat:-90.1555020004316, lng:36.2138810001186},
   {lat:-90.1610430004452, lng:36.2156580000584},
   {lat:-90.1677950004287, lng:36.2131810001369},
   {lat:-90.1695699995649, lng:36.2141370000846},
   {lat:-90.1729799998918, lng:36.2104199996904},
   {lat:-90.1758460002456, lng:36.2105740002795},
   {lat:-90.17783899984,lng: 36.207130999925},
   {lat:-90.1810779995644, lng:36.2087290000735},
   {lat:-90.1830429995698, lng:36.2049840002904},
   {lat:-90.1881840004486, lng:36.2054950000349},
   {lat:-90.1901519999286, lng:36.2013930001682},
   {lat:-90.1952140002664, lng:36.2003479999283},
   {lat:-90.1964560001985, lng:36.1963720002103},
   {lat:-90.2015379997295, lng:36.1960459998188},
   {lat:-90.2007469998841, lng:36.1921280002539},
   {lat:-90.2041930001961, lng:36.1897060001778},
   {lat:-90.2044259998246, lng:36.1867119999727},
   {lat:-90.2101030002806, lng:36.1857649999021},
   {lat:-90.2114699998861, lng:36.1830439998607},
   {lat:-90.2153050003605, lng:36.1846740000451},
   {lat:-90.2165990001711, lng:36.1835590001103},
   {lat:-90.2204870003489, lng:36.1844370000555},
   {lat:-90.2251579999762, lng:36.1768729997928},
   {lat:-90.2295599999899, lng:36.1731930003445},
   {lat:-90.2312749997499, lng:36.1636530002759},
   {lat:-90.2352680004081, lng:36.1596170001569},
   {lat:-90.2328349996993, lng:36.1563230001318},
   {lat:-90.2333689996267, lng:36.1547869996398},
   {lat:-90.2312630000544, lng:36.1538210002445},
   {lat:-90.2315059997286, lng:36.1467829997768},
   {lat:-90.2350599999956, lng:36.1450739996419},
   {lat:-90.2353720001651, lng:36.1394800001231},
   {lat:-90.2455560002169, lng:36.1361879996718},
   {lat:-90.2453700004441, lng:36.1335540000819},
   {lat:-90.2531919999322, lng:36.1272919997724},
   {lat:-90.2608619999826, lng:36.1272570000845},
   {lat:-90.2665200001721, lng:36.1201079999628},
   {lat:-90.2723080000579, lng:36.1184460003188},
   {lat:-90.2729590001611, lng:36.1169310000901},
   {lat:-90.2788750000935, lng:36.1174769998068},
   {lat:-90.2933010003254, lng:36.1143050002141},
   {lat:-90.2950670001391, lng:36.1126249998041},
   {lat:-90.2938500003214, lng:36.1099390000066},
   {lat:-90.2981839996647, lng:36.1073119999243},
   {lat:-90.297916999701, lng:36.1029930000666},
   {lat:-90.3002509997773, lng:36.0977310002389},
   {lat:-90.3069059996764, lng:36.0948019997554},
   {lat:-90.3117269999898,lng: 36.094748999779},
   {lat:-90.3164360001502, lng:36.0906409997939},
   {lat:-90.3188770003566, lng:36.0903089999087},
   {lat:-90.3206480001931, lng:36.0870520002321},
   {lat:-90.3188820003794, lng:36.0845240002908},
   {lat:-90.3202370002894, lng:36.0810700001581},
   {lat:-90.3182379999489, lng:36.0761339998703},
   {lat:-90.3207679998437, lng:36.0732250003372},
   {lat:-90.3204199996892, lng:36.0714819999692},
   {lat:-90.3236750002054, lng:36.0716380001725},
   {lat:-90.3279800001348, lng:36.0685520003448},
   {lat:-90.3332190000246, lng:36.0677009997303},
   {lat:-90.3362960002656, lng:36.0606679999901},
   {lat:-90.3334459998053, lng:36.0567300002832},
   {lat:-90.3377159995745, lng:36.0532549997647},
   {lat:-90.3368810002466,lng: 36.047368000154},
   {lat:-90.3407550001807, lng:36.0471379996495},
   {lat:-90.3435570000621,lng: 36.044984999735},
   {lat:-90.3436599999943, lng:36.0430699998636},
   {lat:-90.3482079995979, lng:36.0415730002335},
   {lat:-90.3493089999629, lng:36.0392949999507},
   {lat:-90.3482750004433, lng:36.0350509997741},
   {lat:-90.3513059997553,lng: 36.031074999978},
   {lat:-90.3541970002234, lng:36.0304670002012},
   {lat:-90.3517130003594, lng:36.0280059997468},
   {lat:-90.3518089997205, lng:36.0250819997732},
   {lat:-90.3569770003635, lng:36.0184860003039},
   {lat:-90.3643250001987,lng: 36.014078999997},
   {lat:-90.3778900002651, lng:35.9956830002956},
   {lat:-89.7330949999601, lng:36.0006079997882},
   {lat:-89.7284419998762, lng:35.9935680003498},
   {lat:-89.7191680003938,lng: 35.985975999916},
   {lat:-89.7196789998566, lng:35.9709390000179},
   {lat:-89.7145649996403, lng:35.9630340002498},
   {lat:-89.6869240000447, lng:35.9477160002529},
   {lat:-89.6531399998377, lng:35.9226179998816},
   {lat:-89.6448379999506, lng:35.9043510002339},
   {lat:-89.6465560000837,lng: 35.899801999665},
   {lat:-89.6443950002598,lng: 35.894781999771},
   {lat:-89.6453459999387, lng:35.8914189997462},
   {lat:-89.6483649995551, lng:35.8937669996475},
   {lat:-89.6661009996565,lng: 35.882866000246},
   {lat:-89.675084999609, lng:35.8843639998003},
   {lat:-89.6881369996644,lng: 35.896945000091},
   {lat:-89.7149339997112, lng:35.9062470000979},
   {lat:-89.7426060001673, lng:35.9066529996905},
   {lat:-89.7656890001136, lng:35.8912989999551},
   {lat:-89.7728550003739, lng:35.8762440000246},
   {lat:-89.7724670000363, lng:35.8650979997542},
   {lat:-89.7643430004244, lng:35.8583129997283},
   {lat:-89.729517000302,lng: 35.847632000314},
   {lat:-89.7063520003399, lng:35.8372020000929},
   {lat:-89.7028829995634, lng:35.8341529996855},
   {lat:-89.7011910002679, lng:35.8299120002226},
   {lat:-89.7015229996306, lng:35.8249149996771},
   {lat:-89.7043870003346, lng:35.8196070002743},
   {lat:-89.7238330001732,lng: 35.809246999983},
   {lat:-89.734706999788, lng:35.8060469996775},
   {lat:-89.7433190001948, lng:35.8057809999703},
   {lat:-89.7654380000434, lng:35.8112129997233},
   {lat:-89.76545700031,lng: 35.809512999738},
   {lat:-89.7811759996292, lng:35.8052959998136},
   {lat:-89.7963240001095, lng:35.7928069998658},
   {lat:-89.7993310000303, lng:35.7885030001334},
   {lat:-89.7970530000306, lng:35.7826479999668},
   {lat:-89.7992490000145,lng: 35.775438999979},
   {lat:-89.8048629998231, lng:35.7715159996495},
   {lat:-89.8128910000738, lng:35.7611540001451},
   {lat:-89.8208759997685, lng:35.7568679999846},
   {lat:-89.8368000000255, lng:35.7549410002589},
   {lat:-89.8471270001924, lng:35.7554989998716},
   {lat:-89.8656309996746, lng:35.7465769998356},
   {lat:-89.8764619996317, lng:35.7380259999282},
   {lat:-89.87725599985, lng:35.7413689997149},
   {lat:-89.8835350000055, lng:35.7449840002374},
   {lat:-89.8881629999751, lng:35.7500770000229},
   {lat:-89.9063619998588, lng:35.7595620001419},
   {lat:-89.9098249998892,lng: 35.759387000298},
   {lat:-89.9191069997675, lng:35.7530250000032},
   {lat:-89.9502770004141, lng:35.7384939997654},
   {lat:-89.9562519998976, lng:35.7333880003109},
   {lat:-89.9588789998768, lng:35.7238349999952},
   {lat:-89.9557530001303, lng:35.6906209996729},
   {lat:-89.94270000025,lng: 35.675120999858},
   {lat:-89.9373829996441, lng:35.6657109999367},
   {lat:-89.9310359997165, lng:35.6600440000173},
   {lat:-89.9154270000021, lng:35.6527819997329},
   {lat:-89.9042300003472, lng:35.6509969998607},
   {lat:-89.894566999804, lng:35.6515139998436},
   {lat:-89.8849319997483, lng:35.6551069997997},
   {lat:-89.8771579999408, lng:35.6661360003596},
   {lat:-89.8647390004453, lng:35.6703880001562},
   {lat:-89.8579420002559, lng:35.6683120003279},
   {lat:-89.8511579995871, lng:35.6574239996727},
   {lat:-89.8518239997589, lng:35.6442610003553},
   {lat:-89.8570650001969, lng:35.6341519998545},
   {lat:-89.8778629995724, lng:35.6260040002644},
   {lat:-89.8943450000462, lng:35.6155350000581},
   {lat:-89.8997520001655, lng:35.6150250000394},
   {lat:-89.9110819998898,lng: 35.617555999828},
   {lat:-89.9195529998313, lng:35.6121800000893},
   {lat:-89.9467109995532, lng:35.6007890000516},
   {lat:-89.9536940004137,lng: 35.59462399991},
   {lat:-89.9574949996546, lng:35.5885300002917},
   {lat:-89.9578770001443,lng: 35.58536900003},
   {lat:-89.9544719998402, lng:35.5766080001325},
   {lat:-89.9418750000695, lng:35.5568590001347},
   {lat:-89.9286849999219,lng: 35.551453999818},
   {lat:-89.9124789996326, lng:35.5494319998331},
   {lat:-89.9088709998372, lng:35.5473779997255},
   {lat:-89.9075789996765, lng:35.5421210002444},
   {lat:-89.9097400003987, lng:35.5419599998556},
   {lat:-89.9124019996397, lng:35.5379509996993},
   {lat:-89.9064580001182, lng:35.5371940001641},
   {lat:-89.9038820001927, lng:35.5341750003641},
   {lat:-89.9090220003483, lng:35.5205479997059},
   {lat:-89.9144839998208, lng:35.5156919996687},
   {lat:-89.9193310000735, lng:35.5138700002248},
   {lat:-89.9336140001903, lng:35.5185379998524},
   {lat:-89.948010000285, lng:35.5200900002189},
   {lat:-89.9577149997626, lng:35.5271920001611},
   {lat:-89.9556900003812, lng:35.5337879997665},
   {lat:-89.9588489997396, lng:35.5419160002969},
   {lat:-89.9789170002674, lng:35.5553280002864},
   {lat:-89.9894989998042, lng:35.5601359998114},
   {lat:-89.9992440003631, lng:35.5611930002688},
   {lat:-90.007993000139, lng:35.5600910002819},
   {lat:-90.017582000164, lng:35.5560099998088},
   {lat:-90.0261130003799,lng: 35.556176000034},
   {lat:-90.0351180002488, lng:35.5525119998285},
   {lat:-90.0395500003998, lng:35.5485569998391},
   {lat:-90.0419609995706,lng: 35.537467000233},
   {lat:-90.0495400002828, lng:35.5210490000949},
   {lat:-90.0498099997212,lng: 35.510454000226},
   {lat:-90.0434619999688, lng:35.4923390000974},
   {lat:-90.0337900001031, lng:35.4794109998313},
   {lat:-90.0203329999917, lng:35.4702910002407},
   {lat:-90.0189160001575, lng:35.4662309996885},
   {lat:-90.0266039997513, lng:35.4477879997185},
   {lat:-90.0265839996598, lng:35.4401030000938},
   {lat:-90.0310209998336, lng:35.4330539997828},
   {lat:-90.0315840000733, lng:35.4276619998141},
   {lat:-90.0426400001613, lng:35.4212369998146},
   {lat:-90.046597999761, lng:35.4129659996465},
   {lat:-90.0567819998127, lng:35.4038629998753},
   {lat:-90.0415630000857, lng:35.3966200002484},
   {lat:-90.044581999702, lng:35.3930730002377},
   {lat:-90.0543220002381, lng:35.3892770001504},
   {lat:-90.0694339998351, lng:35.4081630002331},
   {lat:-90.0620180002278, lng:35.4151799999946},
   {lat:-90.0705490004438, lng:35.4232910001488},
   {lat:-90.0740459999111, lng:35.4340430002881},
   {lat:-90.0740629996295, lng:35.4396109998964},
   {lat:-90.0672289996302, lng:35.4609519996426},
   {lat:-90.068155999918, lng:35.4667799997889},
   {lat:-90.0741320001248,lng: 35.472545999647},
   {lat:-90.0836500000047, lng:35.4782970000804},
   {lat:-90.0987340000125, lng:35.4785540000427},
   {lat:-90.1017719998955, lng:35.4768309999472},
   {lat:-90.1078369997907, lng:35.4768360000455},
   {lat:-90.1189280000388, lng:35.4683280003639},
   {lat:-90.1237560000248,lng: 35.459112999973},
   {lat:-90.1294469998263, lng:35.4419299999227},
   {lat:-90.1688819996517, lng:35.4222059996978},
   {lat:-90.1705119999213, lng:35.4187970002032},
   {lat:-90.1706759999528, lng:35.4105299996636},
   {lat:-90.1792649998951,lng: 35.385193999949},
   {lat:-90.1783409999804, lng:35.3820919997073},
   {lat:-90.16624600035, lng:35.3747449997637},
   {lat:-90.1449240001945, lng:35.3746329997337},
   {lat:-90.1355100000717, lng:35.3766679997786},
   {lat:-90.1434749996749, lng:35.3876020001256},
   {lat:-90.1461910002409, lng:35.3994679999066},
   {lat:-90.1441650001363, lng:35.4055540002652},
   {lat:-90.140921000389, lng:35.4092250001599},
   {lat:-90.1351250001072, lng:35.4129769998085},
   {lat:-90.129492999857, lng:35.4137590002237},
   {lat:-90.1125040001183, lng:35.4101529999112},
   {lat:-90.1048419995655, lng:35.4014870003429},
   {lat:-90.0966500001816, lng:35.3952569997578},
   {lat:-90.0799300000564, lng:35.3852720001743},
   {lat:-90.0749919995671, lng:35.3841519998463},
   {lat:-90.0879030000555, lng:35.3632700000136},
   {lat:-90.0968250000837, lng:35.3572160001604},
   {lat:-90.1073120000842, lng:35.3431429999746},
   {lat:-90.1102930000657, lng:35.3427880000287},
   {lat:-90.1038619995743, lng:35.3324049997845},
   {lat:-90.1068240001875, lng:35.3246179997427},
   {lat:-90.1090929999665, lng:35.3049870003379},
   {lat:-90.1161139995635, lng:35.3034119997107},
   {lat:-90.123706999621, lng:35.3045299998037},
   {lat:-90.1388640003221, lng:35.2991139998684},
   {lat:-90.1494490002319, lng:35.3033019996851},
   {lat:-90.1589129996851, lng:35.3006370002211},
   {lat:-90.1638119998163,lng: 35.296114999734},
   {lat:-90.1690790001935, lng:35.2845639999834},
   {lat:-90.1689240003828, lng:35.2802800001742},
   {lat:-90.1588650000045, lng:35.2625770001718},
   {lat:-90.1520939997545, lng:35.2559890000478},
   {lat:-90.1403940001343, lng:35.2522890001125},
   {lat:-90.1164929996802, lng:35.2557879997438},
   {lat:-90.1050929996356, lng:35.2542880002279},
   {lat:-90.0979470003652,lng: 35.249982999842},
   {lat:-90.0793450000753, lng:35.2288380002134},
   {lat:-90.0742619998211, lng:35.2183159998541},
   {lat:-90.0743920004158, lng:35.2105889997374},
   {lat:-90.0800849998671, lng:35.2076880000456},
   {lat:-90.0885969998164, lng:35.2123759998207},
   {lat:-90.0964660000585, lng:35.1948480002065},
   {lat:-90.109076000248,lng: 35.199105000228},
   {lat:-90.1161820002339, lng:35.1984980000222},
   {lat:-90.1173930002038, lng:35.1878900000935},
   {lat:-90.1142139998555, lng:35.1816910000093},
   {lat:-90.1094860003269,lng:35.17886499998},
   {lat:-90.1087570004058, lng:35.1752819999294},
   {lat:-90.1041700004442,lng: 35.169011999721},
   {lat:-90.0919989997475, lng:35.1563719999572},
   {lat:-90.0705259999793, lng:35.1536439997781},
   {lat:-90.0669580003669, lng:35.1518389997326},
   {lat:-90.0646119996968, lng:35.1406209997463},
   {lat:-90.0653919996714, lng:35.1376910002657},
   {lat:-90.0834209996758, lng:35.1216689997666},
   {lat:-90.096514999564, lng:35.1171309997724},
   {lat:-90.1093930004404, lng:35.1188909996361},
   {lat:-90.1427940003328, lng:35.1350909998266},
   {lat:-90.1559939996279, lng:35.1309909998595},
   {lat:-90.165328000283, lng:35.1252279999216},
   {lat:-90.173603000406, lng:35.1180730001042},
   {lat:-90.1768429999553, lng:35.1120880002499},
   {lat:-90.1813870002592, lng:35.0914010000451},
   {lat:-90.1958729998673, lng:35.0602079999161},
   {lat:-90.1972210001045, lng:35.0483810002563},
   {lat:-90.1958169997908, lng:35.0377880000063},
   {lat:-90.2002220001776, lng:35.0327429998732},
   {lat:-90.2093969999258, lng:35.0265460003647},
   {lat:-90.2162579996892, lng:35.0260489998863},
   {lat:-90.2306100003013,lng: 35.031319999692},
   {lat:-90.2564900001061, lng:35.0344940002398},
   {lat:-90.2623919997947, lng:35.0363939998636},
   {lat:-90.2652930003086, lng:35.0402939997739},
   {lat:-90.2919959997458,lng: 35.041792999819},
   {lat:-90.2955960000436, lng:35.0400929999774},
   {lat:-90.3098719995893, lng:35.0097490003089},
   {lat:-90.3100830003748, lng:35.0010409999558},
   {lat:-90.3077900003065, lng:34.9918529998885},
   {lat:-90.3024710000507, lng:34.9820769998084},
   {lat:-90.296422000049, lng:34.9763460001564},
   {lat:-90.2872389999049, lng:34.9725310002275},
   {lat:-90.2775019997419, lng:34.9647760003443},
   {lat:-90.2539690004321, lng:34.9549879996557},
   {lat:-90.2500560001399, lng:34.9511960002784},
   {lat:-90.246689000369, lng:34.9457469996687},
   {lat:-90.2444759997683, lng:34.9375960003134},
   {lat:-90.2447310000364, lng:34.9210259997599},
   {lat:-90.2500949995997, lng:34.9073200001296},
   {lat:-90.279363999815, lng:34.8900769996759},
   {lat:-90.3065550000471, lng:34.8775139999605},
   {lat:-90.313476000085, lng:34.8716980001168},
   {lat:-90.3025229999293,lng: 34.856470999962},
   {lat:-90.3039439999614, lng:34.8505169996356},
   {lat:-90.3091829998513, lng:34.8449939997649},
   {lat:-90.3136159998271, lng:34.8441049998812},
   {lat:-90.3230700001329, lng:34.8464330002786},
   {lat:-90.3322599999497, lng:34.8525060000097},
   {lat:-90.340380000262, lng:34.8603569999599},
   {lat:-90.3521739995935,lng: 34.853195999776},
   {lat:-90.3798369998287, lng:34.8459309998437},
   {lat:-90.4105510003657, lng:34.8320590000233},
   {lat:-90.4194850000895,lng: 34.832883000066},
   {lat:-90.4238849995551, lng:34.8346140000541},
   {lat:-90.4294380001626,lng: 34.84310199972},
   {lat:-90.4317250003832, lng:34.8589140000748},
   {lat:-90.4289770000301, lng:34.8671599999509},
   {lat:-90.438310999787, lng:34.8845830002242},
   {lat:-90.4496530001052, lng:34.8898269999268},
   {lat:-90.4618029999872, lng:34.8919100001779},
   {lat:-90.4661539999473, lng:34.8909889996565},
   {lat:-90.4798639997786, lng:34.8832729999402},
   {lat:-90.4845560002206, lng:34.8751060002132},
   {lat:-90.483875999805, lng:34.8613329998432},
   {lat:-90.4637110000911, lng:34.8348040000824},
   {lat:-90.4567830003805, lng:34.8226829999742},
   {lat:-90.459023999672, lng:34.8144400000927},
   {lat:-90.4653670002999, lng:34.8105920001708},
   {lat:-90.4722799999419, lng:34.8024649998953},
   {lat:-90.4745899997287, lng:34.7931999998891},
   {lat:-90.4704110001961, lng:34.7808769999021},
   {lat:-90.4567120002354, lng:34.7607540000587},
   {lat:-90.4517510001799, lng:34.7500990000758},
   {lat:-90.4512570004355, lng:34.7444849998293},
   {lat:-90.4539699997301, lng:34.7368770002368},
   {lat:-90.4579499999694, lng:34.7329459998047},
   {lat:-90.4698970003601,lng: 34.727030000295},
   {lat:-90.4888650003479, lng:34.7237310000314},
   {lat:-90.501667000158, lng:34.7242360003248},
   {lat:-90.520180999686, lng:34.7319020001316},
   {lat:-90.5243180002842, lng:34.7391350000749},
   {lat:-90.5245169995775, lng:34.7513679999632},
   {lat:-90.5208410000101, lng:34.7555360002326},
   {lat:-90.505502999559, lng:34.7645639996313},
   {lat:-90.5009890002906, lng:34.7711920003657},
   {lat:-90.5036799998439, lng:34.7801410000346},
   {lat:-90.5147080003428, lng:34.8017659999385},
   {lat:-90.5228920002291, lng:34.8022649997099},
   {lat:-90.5385029995933, lng:34.7972349998728},
   {lat:-90.5476120001176,lng: 34.784588999727},
   {lat:-90.5472439998716, lng:34.7766829996788},
   {lat:-90.5419060002476,lng: 34.761730000305},
   {lat:-90.5387360001201, lng:34.7589290001213},
   {lat:-90.5357960001465, lng:34.7528070000515},
   {lat:-90.5364170001125, lng:34.7482989999969},
   {lat:-90.5399949997706, lng:34.7448109997201},
   {lat:-90.559895000069,lng: 34.740788000175},
   {lat:-90.5635439998722, lng:34.7386710003447},
   {lat:-90.5672400004294, lng:34.7334630000942},
   {lat:-90.5680809996051, lng:34.7248020000269},
   {lat:-90.5460529999932, lng:34.7020760000439},
   {lat:-90.5356820003437,lng: 34.697879000107},
   {lat:-90.5220360000864, lng:34.6966110002233},
   {lat:-90.4853239996014, lng:34.7015359998664},
   {lat:-90.4724950000271, lng:34.6997880000046},
   {lat:-90.4670639996183, lng:34.6956429999583},
   {lat:-90.4628519995754, lng:34.6891440000435},
   {lat:-90.4628159995904,lng: 34.684073999784},
   {lat:-90.4660409999694, lng:34.6743119997985},
   {lat:-90.4797180001887, lng:34.6599339997393},
   {lat:-90.4944780003315, lng:34.6499339999445},
   {lat:-90.5081000002993, lng:34.6366819999688},
   {lat:-90.5194779997042, lng:34.6299010000226},
   {lat:-90.5321130000081, lng:34.6274890003612},
   {lat:-90.54097700031, lng:34.6285330000398},
   {lat:-90.5468200004474, lng:34.6310869996327},
   {lat:-90.5545449998511, lng:34.6422130002152},
   {lat:-90.5548670000664, lng:34.6486879997911},
   {lat:-90.5526419997701, lng:34.6597069998716},
   {lat:-90.550157999906, lng:34.6634449999126},
   {lat:-90.5394009995688, lng:34.6709109997406},
   {lat:-90.5378850000003, lng:34.6739789998113},
   {lat:-90.5391500003969, lng:34.6834510001775},
   {lat:-90.5498569996071, lng:34.6954780002524},
   {lat:-90.5571480004392, lng:34.6978229999887},
   {lat:-90.5673140000493, lng:34.6935219996913},
   {lat:-90.573106000133, lng:34.6864250000016},
   {lat:-90.5787450000559, lng:34.6838439997649},
   {lat:-90.5884189995714, lng:34.6709629997118},
   {lat:-90.5863359995653, lng:34.6516890003384},
   {lat:-90.583019999848, lng:34.6426789998599},
   {lat:-90.5882550004382, lng:34.6266159999184},
   {lat:-90.5872240003934, lng:34.6157320003251},
   {lat:-90.5816930004255, lng:34.6042269998296},
   {lat:-90.5701329996491, lng:34.5874569998568},
   {lat:-90.5535640000349, lng:34.5728620001324},
   {lat:-90.5458909996115, lng:34.5632569999908},
   {lat:-90.5407360002855, lng:34.5480850002575},
   {lat:-90.5457280003032, lng:34.5377500000199},
   {lat:-90.5552760003203, lng:34.5310120000374},
   {lat:-90.5693469998266, lng:34.5248669997859},
   {lat:-90.5801150000345, lng:34.5145370003125},
   {lat:-90.5889419996282, lng:34.4910969996305},
   {lat:-90.5896599996785, lng:34.4801009999035},
   {lat:-90.5860109998753,lng: 34.462345999929},
   {lat:-90.58372100018, lng:34.4588249998827},
   {lat:-90.5732319996314, lng:34.4509290001949},
   {lat:-90.5658090003515, lng:34.4354000002608},
   {lat:-90.5665049997623,lng: 34.42952799965},
   {lat:-90.5711450003257, lng:34.4203219996649},
   {lat:-90.5806809997489, lng:34.4105510003422},
   {lat:-90.613947999747, lng:34.3907210000708},
   {lat:-90.6349460000373, lng:34.3863620000452},
   {lat:-90.6585400003447, lng:34.3757050002304},
   {lat:-90.6553500001258, lng:34.3718400000808},
   {lat:-90.6667869999803, lng:34.3558170001859},
   {lat:-90.6668639999732, lng:34.3485509998511},
   {lat:-90.6573710002076,lng: 34.327286999997},
   {lat:-90.6574880003835, lng:34.3222309997173},
   {lat:-90.6629609997267, lng:34.3147789997809},
   {lat:-90.6693429998144, lng:34.3130200000389},
   {lat:-90.6780969996131, lng:34.3130309999165},
   {lat:-90.6860029996651, lng:34.3157709996579},
   {lat:-90.6931289997424, lng:34.3225699999658},
   {lat:-90.6936860001344, lng:34.3296799997412},
   {lat:-90.6915510002497,lng: 34.338617999685},
   {lat:-90.68161599972, lng:34.3529039996321},
   {lat:-90.6766599996874, lng:34.3714109997882},
   {lat:-90.7120880004076, lng:34.3638050002255},
   {lat:-90.724908999586, lng:34.3636589997586},
   {lat:-90.7501070002237, lng:34.3679190002992},
   {lat:-90.7561970002334, lng:34.3672560000429},
   {lat:-90.76414299957,lng: 34.363396000287},
   {lat:-90.7670609998024, lng:34.3602709996809},
   {lat:-90.768097999695, lng:34.3558840003014},
   {lat:-90.767107999658, lng:34.3452640000601},
   {lat:-90.7501060003989, lng:34.3320649997659},
   {lat:-90.7458270004088, lng:34.3267460000918},
   {lat:-90.7406100002603, lng:34.3134690001419},
   {lat:-90.7412529999676, lng:34.3055559997178},
   {lat:-90.7526809996012, lng:34.2892659996931},
   {lat:-90.7651650002923, lng:34.2805240003237},
   {lat:-90.8029239998175, lng:34.2824650003199},
   {lat:-90.8218709998888, lng:34.2774909998346},
   {lat:-90.829663000138, lng:34.2724240001344},
   {lat:-90.8329360001976, lng:34.2657370002509},
   {lat:-90.838807999749, lng:34.2420960000966},
   {lat:-90.8400089996731, lng:34.2230770001569},
   {lat:-90.847807999595, lng:34.2065300000545},
   {lat:-90.8567079998819, lng:34.2115979999356},
   {lat:-90.8670609999881,lng: 34.212144000057},
   {lat:-90.8790850003717, lng:34.2154329997906},
   {lat:-90.8944439998408, lng:34.2243260001609},
   {lat:-90.9000879997866, lng:34.2296260001089},
   {lat:-90.9042699996923, lng:34.2409699999051},
   {lat:-90.9070809997945, lng:34.2444909999122},
   {lat:-90.9231520003646, lng:34.2465310000287},
   {lat:-90.9295229996833, lng:34.2441059997002},
   {lat:-90.937042000121, lng:34.2353409999102},
   {lat:-90.9352190004059, lng:34.2190430002076},
   {lat:-90.9312750001516, lng:34.2130900000017},
   {lat:-90.9141530003435, lng:34.1954839999056},
   {lat:-90.887886999851, lng:34.1819820001784},
   {lat:-90.8774829996912, lng:34.1856370000291},
   {lat:-90.8738399997358, lng:34.1832249998378},
   {lat:-90.8696629998529, lng:34.1829710001599},
   {lat:-90.8555949998213, lng:34.1868819997478},
   {lat:-90.8381950001789,lng: 34.183799999765},
   {lat:-90.8283890004189, lng:34.1847830000881},
   {lat:-90.8165719997245, lng:34.1830229998636},
   {lat:-90.8123739999253,lng: 34.180766999654},
   {lat:-90.8086849999391, lng:34.1758780001313},
   {lat:-90.8071159997689, lng:34.1655900000399},
   {lat:-90.8087359999927, lng:34.1594159999839},
   {lat:-90.8158779999636, lng:34.1498790001514},
   {lat:-90.825708000013, lng:34.1420110000946},
   {lat:-90.836098999754, lng:34.1378759998513},
   {lat:-90.8471680002607,lng: 34.136884000012},
   {lat:-90.8645799995987, lng:34.1405550003658},
   {lat:-90.8830730001086,lng: 34.1515020002},
   {lat:-90.8943850002896, lng:34.1609530001533},
   {lat:-90.9020250002028, lng:34.1644180000921},
   {lat:-90.9100070004228, lng:34.1655019998377},
   {lat:-90.9542989996483, lng:34.1384960002503},
   {lat:-90.9593169996052, lng:34.1303489998964},
   {lat:-90.9584670002086, lng:34.1251049999718},
   {lat:-90.9551699998596, lng:34.1188329997275},
   {lat:-90.9431670002907, lng:34.1071649999421},
   {lat:-90.9183970001735, lng:34.0930549998528},
   {lat:-90.9011299997021, lng:34.0946670002708},
   {lat:-90.8935259997739, lng:34.0977950000451},
   {lat:-90.8826229998468, lng:34.0966129998812},
   {lat:-90.8766059996321, lng:34.0929109998225},
   {lat:-90.8710539997479, lng:34.0850250000029},
   {lat:-90.8705280002164, lng:34.0805159999809},
   {lat:-90.8745410000677, lng:34.0720409996572},
   {lat:-90.8878369996223, lng:34.0554030001791},
   {lat:-90.8890579996379, lng:34.0463620002839},
   {lat:-90.8870829995869, lng:34.0334000000551},
   {lat:-90.8903890001567,lng: 34.028222999709},
   {lat:-90.8941509999379, lng:34.0259490002312},
   {lat:-90.9120639995913, lng:34.0219980001152},
   {lat:-90.9237449998432,lng: 34.023143000119},
   {lat:-90.9426619997773, lng:34.0180499998857},
   {lat:-90.9503109999114, lng:34.0178219998607},
   {lat:-90.9707259998705, lng:34.0216200001376},
   {lat:-90.9879480001361, lng:34.0190380003634},
   {lat:-90.9798360002197, lng:33.9976650000545},
   {lat:-90.9710179999486, lng:33.9919559999856},
   {lat:-90.9615510001223,lng: 33.979946000365},
   {lat:-90.9626299998477, lng:33.9703839998395},
   {lat:-90.9676319999111, lng:33.9633239998498},
   {lat:-90.9748020003693, lng:33.9607620000586},
   {lat:-90.9833589996263, lng:33.9601859998605},
   {lat:-90.9922410003698, lng:33.9619760002358},
   {lat:-90.9983680001894, lng:33.9650209997343},
   {lat:-91.0029860001132,lng: 33.970901999666},
   {lat:-91.0037590004151,lng: 33.974935999903},
   {lat:-91.0188900002787, lng:34.0031510000264},
   {lat:-91.0337650000491, lng:33.9953230000649},
   {lat:-91.0427509996515, lng:33.9868109998084},
   {lat:-91.0483670000081, lng:33.9850780003174},
   {lat:-91.0753780003152, lng:33.9835859996392},
   {lat:-91.0831870002828, lng:33.9798649999878},
   {lat:-91.0879209996593, lng:33.9753350000615},
   {lat:-91.0897559999682, lng:33.9697210001276},
   {lat:-91.0893490002625, lng:33.9632279997503},
   {lat:-91.084095000304, lng:33.9561789998607},
   {lat:-91.0359610000331, lng:33.9437579999157},
   {lat:-91.0200970000506, lng:33.9371270001545},
   {lat:-91.0103180000548, lng:33.9293520000603},
   {lat:-91.0108310000658, lng:33.9255519997221},
   {lat:-91.0263820000539, lng:33.9079800002118},
   {lat:-91.0553089999624, lng:33.8830350001776},
   {lat:-91.0708830004151, lng:33.8667139998979},
   {lat:-91.0727980001917, lng:33.8623959998125},
   {lat:-91.0726600000994, lng:33.8549430002169},
   {lat:-91.067510999723, lng:33.8404429998542},
   {lat:-91.0566920003598, lng:33.8289349997319},
   {lat:-91.0468489998916, lng:33.8153650002792},
   {lat:-91.0251729997338, lng:33.8059530001095},
   {lat:-91.0077799997641, lng:33.8025940000332},
   {lat:-90.9997840001987, lng:33.7991400000297},
   {lat:-90.9917469997271, lng:33.7925969997473},
   {lat:-90.9884660001699, lng:33.7845300000174},
   {lat:-90.9912200003707, lng:33.7767909996354},
   {lat:-91.0001060004139, lng:33.7691650002529},
   {lat:-91.0127700001318, lng:33.7646750002286},
   {lat:-91.0232849997214,lng: 33.762990999786},
   {lat:-91.026782000087, lng:33.7636420001512},
   {lat:-91.0538860002805, lng:33.7787009998406},
   {lat:-91.0855099995901, lng:33.7764100002775},
   {lat:-91.1073179999924, lng:33.7706190003206},
   {lat:-91.1234659996571, lng:33.7821060003208},
   {lat:-91.132185000194, lng:33.7834199996697},
   {lat:-91.1398689995898, lng:33.7771170001904},
   {lat:-91.1451119996776, lng:33.7673400000049},
   {lat:-91.144812000102, lng:33.7639959997301},
   {lat:-91.1407559996946, lng:33.7597349999513},
   {lat:-91.1448730002013, lng:33.7509650002542},
   {lat:-91.1432870003127,lng: 33.747140999686},
   {lat:-91.146522999664, lng:33.7365030001814},
   {lat:-91.1463510001349, lng:33.7306089997154},
   {lat:-91.1423659998727, lng:33.7240350002699},
   {lat:-91.1319540002153, lng:33.7139020001051},
   {lat:-91.1255269999218, lng:33.7087800001334},
   {lat:-91.1173930002642, lng:33.7052649999483},
   {lat:-91.0968209999462, lng:33.7056540000073},
   {lat:-91.0682899998727, lng:33.7164770000197},
   {lat:-91.0598909999012, lng:33.7148160002966},
   {lat:-91.0467779997465, lng:33.7063129997544},
   {lat:-91.0390249998554, lng:33.6965950003241},
   {lat:-91.0361200000418, lng:33.6891130001727},
   {lat:-91.0304019995779, lng:33.6877660000055},
   {lat:-91.030332000156, lng:33.6816220003586},
   {lat:-91.0321890002063, lng:33.6765930003556},
   {lat:-91.036839999742,lng: 33.671315999853},
   {lat:-91.0591820000717, lng:33.6664000002454},
   {lat:-91.0785070004348,lng: 33.658283000071},
   {lat:-91.0866150001531, lng:33.6572769999967},
   {lat:-91.0940399999812, lng:33.6583509999267},
   {lat:-91.1321600000796, lng:33.6757490002256},
   {lat:-91.1613310003857, lng:33.7072010001472},
   {lat:-91.1725940001629, lng:33.7036059999234},
   {lat:-91.2007119997845, lng:33.7015350000869},
   {lat:-91.2103729997795, lng:33.6990219997943},
   {lat:-91.22057000025, lng:33.6929229996847},
   {lat:-91.2252790004104, lng:33.6877489996937},
   {lat:-91.2290150002523, lng:33.6775429996829},
   {lat:-91.2282279997066, lng:33.6713259998374},
   {lat:-91.2190479999355, lng:33.6615029996605},
   {lat:-91.2112840001737,lng: 33.658525999693},
   {lat:-91.1854550004226, lng:33.6536039997145},
   {lat:-91.1745890003054, lng:33.6495259996349},
   {lat:-91.1407249997325, lng:33.6268929999369},
   {lat:-91.1343889996756,lng: 33.619511999787},
   {lat:-91.1304450003196, lng:33.6060339997015},
   {lat:-91.1340430000692, lng:33.5944889999383},
   {lat:-91.1521480002416, lng:33.5827210001203},
   {lat:-91.1607549997272, lng:33.5813519996914},
   {lat:-91.178219999667, lng:33.5826069999645},
   {lat:-91.1982849998218,lng: 33.572060999764},
   {lat:-91.224121000144, lng:33.5673689998001},
   {lat:-91.2314179999255, lng:33.5605930002907},
   {lat:-91.2325979999333, lng:33.5552350003315},
   {lat:-91.2310220000904, lng:33.5494619997023},
   {lat:-91.221560000287, lng:33.5350049998012},
   {lat:-91.2104210003584, lng:33.5255870002744},
   {lat:-91.1873669998261, lng:33.5105509998759},
   {lat:-91.183420000097, lng:33.5050619999532},
   {lat:-91.1825649997793, lng:33.5010610001099},
   {lat:-91.1833720004165, lng:33.4981570000437},
   {lat:-91.1956339995531, lng:33.4884670000489},
   {lat:-91.2085349999958, lng:33.4686059997695},
   {lat:-91.2155079999122, lng:33.4647789998301},
   {lat:-91.2233379997962, lng:33.4627639999369},
   {lat:-91.2316609995998, lng:33.4570990000489},
   {lat:-91.2334220002889, lng:33.4440379996931},
   {lat:-91.2357999998477, lng:33.4397379996931},
   {lat:-91.2173740001833,lng: 33.434698999765},
   {lat:-91.2068069998169, lng:33.4338459998777},
   {lat:-91.1869790003869, lng:33.4385920003288},
   {lat:-91.1772930002775, lng:33.4436380001248},
   {lat:-91.169359999563, lng:33.4526290003307},
   {lat:-91.1756350004189, lng:33.4717609998156},
   {lat:-91.1770670003217, lng:33.4866309997777},
   {lat:-91.1725389999113, lng:33.4963579999513},
   {lat:-91.1675789996807, lng:33.4983089999828},
   {lat:-91.1646000002473, lng:33.4978410001048},
   {lat:-91.1366559998047, lng:33.4813230000428},
   {lat:-91.1251090003454, lng:33.4728420003711},
   {lat:-91.1179749998722, lng:33.4538059998721},
   {lat:-91.1210999997938, lng:33.4435620002007},
   {lat:-91.1285890000942, lng:33.4362840000352},
   {lat:-91.13188499972,lng: 33.43006200008},
   {lat:-91.1385530000379,lng: 33.427068000348},
   {lat:-91.1448770003993, lng:33.4277060001913},
   {lat:-91.1535809999693, lng:33.4256009999158},
   {lat:-91.176279999776, lng:33.4169780001727},
   {lat:-91.1844269998524, lng:33.4195759998243},
   {lat:-91.1919730000543, lng:33.4177270001767},
   {lat:-91.2000489999856, lng:33.4181370000445},
   {lat:-91.2052719999819, lng:33.4142309999387},
   {lat:-91.2087020004004, lng:33.4087180000164},
   {lat:-91.2081130002214, lng:33.4020059997482},
   {lat:-91.1911269999574, lng:33.3896339999599},
   {lat:-91.171968000174, lng:33.3810299999927},
   {lat:-91.1511609996793, lng:33.3786909997391},
   {lat:-91.1426920002859, lng:33.3798300002977},
   {lat:-91.1206780000194, lng:33.3885449997793},
   {lat:-91.1134620004287, lng:33.3933239996912},
   {lat:-91.09694200032, lng:33.4109899997923},
   {lat:-91.0949110001925, lng:33.4186149999919},
   {lat:-91.0974739996992, lng:33.4317330002085},
   {lat:-91.0967230000369, lng:33.4376030001181},
   {lat:-91.0942790003558, lng:33.4426700001785},
   {lat:-91.0864979999773, lng:33.4515759997678},
   {lat:-91.0778139996004, lng:33.4556469999526},
   {lat:-91.067622999876, lng:33.4551039999166},
   {lat:-91.059828000152, lng:33.4502570000115},
   {lat:-91.0576210002974, lng:33.4453410001154},
   {lat:-91.0581519998517, lng:33.4287050003544},
   {lat:-91.068167999674, lng:33.4140079998455},
   {lat:-91.0752929999264, lng:33.4059659998753},
   {lat:-91.1017900003976, lng:33.3868929997147},
   {lat:-91.1204090004058, lng:33.3638089999213},
   {lat:-91.1422189995596, lng:33.3489889997597},
   {lat:-91.1409680003051,lng: 33.336491999728},
   {lat:-91.1436670002543, lng:33.3283979996931},
   {lat:-91.1414169998436,lng: 33.315044000366},
   {lat:-91.1414400003082, lng:33.2988680000382},
   {lat:-91.1255389996174, lng:33.2802539998664},
   {lat:-91.1255279997467, lng:33.2747320001219},
   {lat:-91.1280779997331, lng:33.2685010002353},
   {lat:-91.118208000399, lng:33.2620709998281},
   {lat:-91.1105609999148, lng:33.2459299997714},
   {lat:-91.1061420001826, lng:33.2417989999665},
   {lat:-91.1000999998536, lng:33.2381239999834},
   {lat:-91.0983919997662,lng: 33.239079999877},
   {lat:-91.0947479999858, lng:33.2504980003506},
   {lat:-91.0904530001022, lng:33.2567989997137},
   {lat:-91.0857079999568, lng:33.2747909999848},
   {lat:-91.078530000001, lng:33.2833059996335},
   {lat:-91.0670350004202, lng:33.2871799999554},
   {lat:-91.0554510002527, lng:33.2862599998677},
   {lat:-91.0481500002732,lng: 33.282794999904},
   {lat:-91.0438200002295, lng:33.2756060003252},
   {lat:-91.0451910000329, lng:33.2654029998122},
   {lat:-91.0504070003565, lng:33.2512019998683},
   {lat:-91.0541259995816, lng:33.2461049998466},
   {lat:-91.0637599998125, lng:33.2376980003736},
   {lat:-91.0656289995583, lng:33.2329819997334},
   {lat:-91.0691440003656,lng: 33.232639000064},
   {lat:-91.0710790002337, lng:33.2300959998524},
   {lat:-91.0706969997439, lng:33.2273020002181},
   {lat:-91.0776730000334, lng:33.2274849998621},
   {lat:-91.0810249997357, lng:33.2224679999107},
   {lat:-91.0917109999278, lng:33.2208130003662},
   {lat:-91.0843659995674, lng:33.1808559999145},
   {lat:-91.0850369997621, lng:33.1593389998492},
   {lat:-91.0886329998619, lng:33.1422130003506},
   {lat:-91.093200999557, lng:33.1367260000885},
   {lat:-91.1043169999194, lng:33.1315969996331},
   {lat:-91.1266700001197, lng:33.1290499999357},
   {lat:-91.1503620003364, lng:33.1306950000743},
   {lat:-91.1530150002549, lng:33.1350920000631},
   {lat:-91.1625570004242, lng:33.1419889996712},
   {lat:-91.1836619999464, lng:33.1416909998958},
   {lat:-91.1931739999785, lng:33.1367330002561},
   {lat:-91.2017799996392, lng:33.1251209999389},
   {lat:-91.2014619996219, lng:33.1096370000352},
   {lat:-91.2001669999864, lng:33.1069299996256},
   {lat:-91.1808359997755, lng:33.0983630003559},
   {lat:-91.1715139997142, lng:33.0878179996872},
   {lat:-91.1498230003861, lng:33.0816029998169},
   {lat:-91.1246389999921, lng:33.0641269999738},
   {lat:-91.1211950002283, lng:33.0591649999838},
   {lat:-91.1203790002686, lng:33.0545299998982},
   {lat:-91.1235080003882, lng:33.0494869998642},
   {lat:-91.1271129998104, lng:33.0359640000959},
   {lat:-91.1374380003275, lng:33.0287359998597},
   {lat:-91.1497580000888, lng:33.0263109996521},
   {lat:-91.1623630002555, lng:33.0196830003179},
   {lat:-91.1667100000176, lng:33.0096639998284},
   {lat:-91.1660730001581, lng:33.0041059999842},
   {lat:-91.5001180001397, lng:33.0067840003632},
   {lat:-92.2221999996817, lng:33.0090899998208},
   {lat:-93.0001600000632, lng:33.0176280001804},
   {lat:-94.0429640002681, lng:33.0192189997448},
   {lat:-94.0434499996166, lng:33.5522529997555},
   {lat:-94.0501859997065, lng:33.5510760003125},
   {lat:-94.0519180000833,lng: 33.552640000046},
   {lat:-94.0614769999711, lng:33.5500010002205},
   {lat:-94.0729470003359, lng:33.5546699999956},
   {lat:-94.0738140003492, lng:33.5576989998895},
   {lat:-94.0709599996909, lng:33.5601399996681},
   {lat:-94.0666840000739, lng:33.5609529999288},
   {lat:-94.0611780002204, lng:33.5591579999729},
   {lat:-94.0565800003881, lng:33.5609319996979},
   {lat:-94.057085999828, lng:33.5680030002113},
   {lat:-94.06627499982, lng:33.5687309997656},
   {lat:-94.0685310000784, lng:33.5711030000587},
   {lat:-94.0705970003661, lng:33.5767249997676},
   {lat:-94.0700619997155, lng:33.5812939996299},
   {lat:-94.0729389999399, lng:33.5847970002066},
   {lat:-94.0752560002978, lng:33.5825099998053},
   {lat:-94.0720080003525, lng:33.5762950000038},
   {lat:-94.072825999962, lng:33.5722979996493},
   {lat:-94.0823520002378, lng:33.5757199998789},
   {lat:-94.0889369998168,lng: 33.575333999639},
   {lat:-94.0974410002686, lng:33.5737440001831},
   {lat:-94.1128430002937, lng:33.5669909997435},
   {lat:-94.1208599997755, lng:33.5670509997085},
   {lat:-94.1207369997519, lng:33.5605609997678},
   {lat:-94.1227360000924, lng:33.5538600002293},
   {lat:-94.1254679996536, lng:33.5511379997476},
   {lat:-94.1290019998291, lng:33.5510359999289},
   {lat:-94.1312079998588,lng: 33.553092000152},
   {lat:-94.1351439997172,lng: 33.571033000262},
   {lat:-94.1452180001641, lng:33.5649790003294},
   {lat:-94.1515740003125, lng:33.5684060002285},
   {lat:-94.1513440001587, lng:33.5713889999432},
   {lat:-94.1430239998299, lng:33.5777249997415},
   {lat:-94.1421600001897, lng:33.5813899998138},
   {lat:-94.1460480003675, lng:33.5819750002162},
   {lat:-94.1526260002737, lng:33.5759230003109},
   {lat:-94.1567749996692, lng:33.5757619998784},
   {lat:-94.1620060000614, lng:33.5808840001998},
   {lat:-94.1610810003217, lng:33.5879729997831},
   {lat:-94.1622660003523, lng:33.5889060001292},
   {lat:-94.176326999813, lng:33.5910769997167},
   {lat:-94.1839120003729,lng: 33.594685000282},
   {lat:-94.1964349996255, lng:33.5812929997834},
   {lat:-94.2038429997351, lng:33.5797730003355},
   {lat:-94.2130069996127, lng:33.5834819998752},
   {lat:-94.2171140000736, lng:33.5809940002381},
   {lat:-94.2162079997022, lng:33.5765690003376},
   {lat:-94.211244000172, lng:33.5737379998303},
   {lat:-94.201587000375, lng:33.5773480000147},
   {lat:-94.1941270003868,lng: 33.573468999926},
   {lat:-94.1896600000757, lng:33.5618070000007},
   {lat:-94.1914719999202, lng:33.5571830001398},
   {lat:-94.195432000068, lng:33.5550940002572},
   {lat:-94.2004480003751, lng:33.5570780003398},
   {lat:-94.2035940002131, lng:33.5665339996771},
   {lat:-94.2056660003485, lng:33.5672189999876},
   {lat:-94.2136250001039, lng:33.5631320002646},
   {lat:-94.2192099996002, lng:33.5560960002674},
   {lat:-94.2261880004378,lng: 33.552976000198},
   {lat:-94.2375750000635, lng:33.5526689999748},
   {lat:-94.2501300000015, lng:33.5567530003642},
   {lat:-94.252157999756, lng:33.5604960000962},
   {lat:-94.251099000122, lng:33.5658509999715},
   {lat:-94.2443499996133,lng: 33.573570000261},
   {lat:-94.2388660003994, lng:33.5767479996981},
   {lat:-94.2368329997237, lng:33.5809179996278},
   {lat:-94.236970999816, lng:33.5874069998648},
   {lat:-94.2427770001434,lng: 33.589708999909},
   {lat:-94.2567940001214,lng: 33.583605000247},
   {lat:-94.265669000294, lng:33.5735889997921},
   {lat:-94.2713480003999, lng:33.5620940000556},
   {lat:-94.2782600002169, lng:33.5575559998512},
   {lat:-94.2859400003131, lng:33.5562289998575},
   {lat:-94.2908970001706, lng:33.5599059996304},
   {lat:-94.2901270002417, lng:33.5681110001999},
   {lat:-94.2806050001638, lng:33.5749080001123},
   {lat:-94.2835819999474, lng:33.5818910002564},
   {lat:-94.2892150000225, lng:33.5821310003522},
   {lat:-94.2949689995731, lng:33.5791249998486},
   {lat:-94.3010229995977,lng: 33.57302099995},
   {lat:-94.3071820001026, lng:33.5597979998598},
   {lat:-94.3064110003488, lng:33.5555980002761},
   {lat:-94.3121499998308, lng:33.5506660003684},
   {lat:-94.3212660000278, lng:33.5490299999847},
   {lat:-94.3312020003824,lng: 33.552951000323},
   {lat:-94.3332030003727, lng:33.5553660002049},
   {lat:-94.3355240000302, lng:33.5644840000309},
   {lat:-94.3395619999959, lng:33.5676680001361},
   {lat:-94.3440229995608, lng:33.5678239999329},
   {lat:-94.3524180002327, lng:33.5621930000881},
   {lat:-94.3472930001457, lng:33.5521519999399},
   {lat:-94.3489450001565, lng:33.5483579998742},
   {lat:-94.3535930002176, lng:33.5440049998548},
   {lat:-94.3577910000168, lng:33.5430499997647},
   {lat:-94.3698749997765, lng:33.5456610002405},
   {lat:-94.3751999998801,lng: 33.544307000289},
   {lat:-94.3861360003174, lng:33.5448100000752},
   {lat:-94.3895299998525,lng: 33.546738999666},
   {lat:-94.3923879998104, lng:33.5511949996599},
   {lat:-94.3970790004274,lng: 33.553617999809},
   {lat:-94.4016160001604, lng:33.5593779998841},
   {lat:-94.4004379998024, lng:33.5629350000527},
   {lat:-94.3948509997581, lng:33.5665210001967},
   {lat:-94.3880329996523, lng:33.5655179998393},
   {lat:-94.3800799997447, lng:33.5689470000317},
   {lat:-94.3777559997142, lng:33.5746100000757},
   {lat:-94.3796739998638, lng:33.5806310000286},
   {lat:-94.3828980004179, lng:33.5832770003627},
   {lat:-94.4033419997911,lng: 33.568424000152},
   {lat:-94.4121950002224, lng:33.5687079999445},
   {lat:-94.4300409999286, lng:33.5911239998854},
   {lat:-94.4389789998504, lng:33.5936199998798},
   {lat:-94.4422140002751, lng:33.5912760002713},
   {lat:-94.4531969996698, lng:33.5917800001072},
   {lat:-94.4582320002434, lng:33.5982700001546},
   {lat:-94.45743899985, lng:33.6004120001635},
   {lat:-94.4623019999962, lng:33.5983790000775},
   {lat:-94.4675869999167, lng:33.5988419999856},
   {lat:-94.4724430003902, lng:33.6034689997538},
   {lat:-94.4694509996397, lng:33.6073159997179},
   {lat:-94.4527010002756, lng:33.6173729998082},
   {lat:-94.4527110003213, lng:33.6226209998513},
   {lat:-94.4544899996555, lng:33.6257420001434},
   {lat:-94.4602740002417, lng:33.6244149999031},
   {lat:-94.4627360003644,lng: 33.630909999809},
   {lat:-94.448438000179, lng:33.6344980002767},
   {lat:-94.4468709996587, lng:33.6401780000464},
   {lat:-94.4482239999188, lng:33.6424020000094},
   {lat:-94.4581390003571, lng:33.6453299996461},
   {lat:-94.4614530004245, lng:33.6436160002029},
   {lat:-94.4660749996479, lng:33.6362620003471},
   {lat:-94.4764229997312, lng:33.6389490003544},
   {lat:-94.4858779998619, lng:33.6378649997599},
   {lat:-94.4576120001023, lng:34.6430019998755},
   {lat:-94.4377689997054, lng:35.2402890001223},
   {lat:-94.4344160001782, lng:35.2979819998999},
   {lat:-94.4322489996082, lng:35.3630299999631},
   {lat:-94.433215000254, lng:35.3657910002557},
   {lat:-94.4315959998551, lng:35.3695379997342},
   {lat:-94.4355900003382, lng:35.3861220002189},
   {lat:-94.4310100000492, lng:35.3925140001249},
   {lat:-94.5521800000544, lng:36.1022360001623},
 ];

export const   coordinates2 = [
   [-85.3865810000125, 33.901718999712],
   [-85.4294699996475, 34.1250959997144],
   [-85.470450000252, 34.3282390000213],
   [-85.5614160002565, 34.7500789998558],
   [-85.5905010003488, 34.8989449996583],
   [-85.6051650002321, 34.9846780002776],
   [-85.3849669996365, 34.9829869999777],
   [-85.2356430000247, 34.9858339996416],
   [-84.8915150002736, 34.9882430001005],
   [-83.7498930004389, 34.9878360000426],
   [-83.6248820001115, 34.9866279998032],
   [-83.6199500003683, 34.9866690003375],
   [-83.6200509997524, 34.992031999961],
   [-83.2968500001465, 34.9962750002584],
   [-83.1106099998352, 35.0013030002248],
   [-83.1028669999898, 34.9972570002315],
   [-83.1035160004432, 34.9936749996642],
   [-83.0983119998152, 34.9906649999796],
   [-83.1028589995939, 34.9887079997677],
   [-83.101799000135, 34.986126000105],
   [-83.106964000405, 34.9835360003326],
   [-83.1057659999555, 34.9815030002183],
   [-83.1077350001588, 34.9798050001293],
   [-83.1112750001821, 34.9810029997967],
   [-83.1111830001206, 34.9757189998088],
   [-83.1165309997904, 34.9757229996407],
   [-83.1156210001193, 34.9730500003039],
   [-83.1203260000818, 34.9687830003259],
   [-83.1221100003371, 34.9643289997736],
   [-83.1216199998923, 34.9627659999117],
   [-83.1157909999986, 34.9601670002978],
   [-83.1202309996472, 34.9591310003307],
   [-83.1202880004469, 34.9579629996448],
   [-83.1148219998781, 34.9528499998357],
   [-83.1140589996218, 34.9502790003146],
   [-83.1244600003069, 34.955027000147],
   [-83.127052000126, 34.9542389997602],
   [-83.1247520003849, 34.9510739997178],
   [-83.1274339997174, 34.9493029997082],
   [-83.121077999569, 34.9421540000659],
   [-83.1165469996839, 34.9425049998612],
   [-83.1117549996828, 34.9349630001828],
   [-83.1179049999669, 34.9360430002873],
   [-83.1205439996417, 34.9329449998168],
   [-83.127312000417, 34.9386330000206],
   [-83.1317369999969, 34.9357189999817],
   [-83.1299589995894, 34.9313470001057],
   [-83.1415179996426, 34.9245149996596],
   [-83.1568910002538, 34.9307670000086],
   [-83.1592410002235, 34.9286080000066],
   [-83.1569899999879, 34.9151230003678],
   [-83.1604229998811, 34.915015999718],
   [-83.1648409997884, 34.9191700003618],
   [-83.1686069997674, 34.9193550003297],
   [-83.1707090000401, 34.9142410001147],
   [-83.174629000005, 34.910281999765],
   [-83.1809159996581, 34.9094699996902],
   [-83.1806949997251, 34.9045790001927],
   [-83.1881260002993, 34.8980869998449],
   [-83.1914980000931, 34.8967820002704],
   [-83.1945109998617, 34.8994330000223],
   [-83.1976619997225, 34.894767999819],
   [-83.1971820002218, 34.8905940001983],
   [-83.1986009997059, 34.8903849998772],
   [-83.2003330000827, 34.8932530003164],
   [-83.203536999647, 34.893451999938],
   [-83.2047579996626, 34.8902169999105],
   [-83.2010340004147, 34.8844069997184],
   [-83.2052609996279, 34.8801650001967],
   [-83.2095720003033, 34.8798559999873],
   [-83.2145769998414, 34.8836290001767],
   [-83.2127690001949, 34.88761499983],
   [-83.2140270000205, 34.8885690000255],
   [-83.2172769996155, 34.8860890001847],
   [-83.2174220002788, 34.8823810003576],
   [-83.2198869998763, 34.8811109998732],
   [-83.2201310002738, 34.8780360001514],
   [-83.2292480002957, 34.8797610000878],
   [-83.2324900003932, 34.8777770001957],
   [-83.2395100001653, 34.8788869998395],
   [-83.2429959997619, 34.8775369996364],
   [-83.2405849996927, 34.8727909996846],
   [-83.2347030000956, 34.8707429999973],
   [-83.237877000421, 34.8688769998544],
   [-83.2391129996069, 34.8650859999792],
   [-83.2460250003223, 34.8672290002788],
   [-83.2480920004349, 34.8655770000616],
   [-83.2463430003396, 34.8600250003415],
   [-83.2482380000247, 34.8581699997003],
   [-83.2464139995864, 34.8555179998248],
   [-83.2533189997308, 34.8559229999527],
   [-83.2524500000677, 34.8521849999219],
   [-83.253905000435, 34.8487689999467],
   [-83.2489269997628, 34.8451410002602],
   [-83.2572859995513, 34.8440999998107],
   [-83.2606320003042, 34.8452849999039],
   [-83.2617250002733, 34.8486370001576],
   [-83.2671959999667, 34.8484689996508],
   [-83.2725880000174, 34.835512999666],
   [-83.2671800000732, 34.8313249996486],
   [-83.269637999998, 34.8236470001879],
   [-83.2683180002481, 34.8211249997932],
   [-83.2708819995799, 34.8185879999201],
   [-83.2770839997425, 34.81699799966],
   [-83.2890319999581, 34.8246460001041],
   [-83.2910770003293, 34.8231929996369],
   [-83.2899550000479, 34.8184589997641],
   [-83.2945169998952, 34.8160319996898],
   [-83.2951429998841, 34.8132250001758],
   [-83.2998500003946, 34.8150370003198],
   [-83.298949999871, 34.8174480002589],
   [-83.300568000445, 34.8186609997931],
   [-83.3030089997531, 34.8188360001042],
   [-83.3063660003766, 34.8156700000236],
   [-83.3044049996709, 34.8111500000335],
   [-83.2994160000264, 34.8094519997697],
   [-83.3008420000814, 34.8043440001394],
   [-83.3032230000133, 34.8022080000219],
   [-83.3141629997503, 34.7995609999412],
   [-83.3140640000161, 34.7964709997036],
   [-83.320404000271, 34.7931790001844],
   [-83.3204419999058, 34.7905809997858],
   [-83.3241419997627, 34.7892459996721],
   [-83.3212129996597, 34.7790410002869],
   [-83.3226620001793, 34.7773900003064],
   [-83.318977000391, 34.7713090001131],
   [-83.3229979997399, 34.7662470000113],
   [-83.3191759996843, 34.7646139998881],
   [-83.3232959996657, 34.7607380001763],
   [-83.3187480000622, 34.7576260002616],
   [-83.3262330001647, 34.7562520001442],
   [-83.3263849996023, 34.7507479996787],
   [-83.3323290000221, 34.7491150000417],
   [-83.3330539997451, 34.7456020002999],
   [-83.3389129997761, 34.7417829999833],
   [-83.3391649996712, 34.7382850002319],
   [-83.3486249998247, 34.7370409997615],
   [-83.3534160000009, 34.7284240002608],
   [-83.3526920001027, 34.7169039996787],
   [-83.3504869998979, 34.709694000207],
   [-83.3466489999488, 34.7042199997313],
   [-83.3529890002036, 34.7008400002983],
   [-83.3537900000947, 34.6981660002109],
   [-83.3401339997917, 34.6914829999458],
   [-83.3382459997793, 34.687033000155],
   [-83.3426169998309, 34.6829909997325],
   [-83.3420040002608, 34.6817700002901],
   [-83.3389000002556, 34.6800389998274],
   [-83.3302839996508, 34.6813420003395],
   [-83.3253360000141, 34.6795169997496],
   [-83.3194400001733, 34.6759740002375],
   [-83.3164009995671, 34.66931600017],
   [-83.3046409996725, 34.6695610000901],
   [-83.3014770002911, 34.6665819999709],
   [-83.3008479999292, 34.6624699996395],
   [-83.2931829999016, 34.654295999856],
   [-83.2807819999495, 34.6489959998361],
   [-83.2779599999766, 34.6448530000906],
   [-83.27198200012, 34.6418959997945],
   [-83.2625820002409, 34.6403959999219],
   [-83.2538810001456, 34.636696000175],
   [-83.2482809996824, 34.6316960001932],
   [-83.2464810004319, 34.6268970000059],
   [-83.2406690002566, 34.6245070001033],
   [-83.2433809997263, 34.6179969997764],
   [-83.2325799999065, 34.6115969998929],
   [-83.2222829998769, 34.6094760002361],
   [-83.2164029999295, 34.6110109997552],
   [-83.2037850002424, 34.6095990001569],
   [-83.1977789998984, 34.6070979999626],
   [-83.1968790002731, 34.6030979996449],
   [-83.1852780003871, 34.6069979997337],
   [-83.1725779997859, 34.6077979996719],
   [-83.1596770002416, 34.6032979997635],
   [-83.1605769998669, 34.5994980001822],
   [-83.1650779996148, 34.6008979998249],
   [-83.1709779996536, 34.5980979998637],
   [-83.1696739997973, 34.5916930000115],
   [-83.154577000269, 34.5881980003551],
   [-83.159077000192, 34.5814990001598],
   [-83.1586770001589, 34.5789989999248],
   [-83.1536769997454, 34.5786990001983],
   [-83.1431760003995, 34.5733989998299],
   [-83.1398759996775, 34.567998999786],
   [-83.1398759996775, 34.5617990002481],
   [-83.133375999589, 34.5594989999383],
   [-83.1271759999746, 34.5619989999069],
   [-83.1229039996572, 34.560116999861],
   [-83.1220519997125, 34.5580280002325],
   [-83.124054000426, 34.5540679996579],
   [-83.121894000427, 34.5523379999101],
   [-83.1162670001997, 34.5521720003027],
   [-83.1106740003076, 34.5478580000198],
   [-83.1092390000317, 34.5421780001772],
   [-83.1040789997846, 34.5400400000146],
   [-83.1026920000876, 34.5324019999981],
   [-83.08782200034, 34.5320849999817],
   [-83.0783989999963, 34.52537200025],
   [-83.0779110000997, 34.5237730001003],
   [-83.0872420003818, 34.5161820001774],
   [-83.0792459999182, 34.5141220002311],
   [-83.0764540000825, 34.5077929997314],
   [-83.0725859999962, 34.50410500006],
   [-83.0657960003778, 34.5010409998815],
   [-83.0586970000647, 34.5035830001384],
   [-83.0543219998151, 34.5027939999151],
   [-83.0516379999345, 34.4937020000581],
   [-83.0484240003245, 34.4934389999968],
   [-83.0345649996319, 34.4835709999716],
   [-83.0283950001547, 34.4838760001665],
   [-83.0172520000281, 34.478246999711],
   [-82.9959009995603, 34.4722039999672],
   [-82.992737000179, 34.4789469998127],
   [-82.979746000223, 34.4829379999968],
   [-82.974917000412, 34.4793500003132],
   [-82.9664799999073, 34.4823330002529],
   [-82.9642410002656, 34.4845710000866],
   [-82.9585479999161, 34.4795539997841],
   [-82.9556449997523, 34.4788869998014],
   [-82.9482280003202, 34.4804920002384],
   [-82.9428580000108, 34.4859109999568],
   [-82.9304150002259, 34.4856130000401],
   [-82.9252020002753, 34.4814609996414],
   [-82.9177360004394, 34.4841679996312],
   [-82.9015510000665, 34.4867640002013],
   [-82.8924480002883, 34.4811070003619],
   [-82.887397999646, 34.4809590001384],
   [-82.8813450003447, 34.4728670002558],
   [-82.8806440000127, 34.4690000000118],
   [-82.8734789995773, 34.4643189998664],
   [-82.871845999833, 34.4611889997544],
   [-82.8644000000886, 34.4597849997624],
   [-82.8590680003124, 34.4554349997515],
   [-82.8601239995732, 34.4528400000677],
   [-82.855485999558, 34.441790999946],
   [-82.8540109999974, 34.428818000166],
   [-82.8496259997021, 34.4256780000696],
   [-82.8469689995857, 34.4198760002086],
   [-82.847510999909, 34.4127610001354],
   [-82.8414529996864, 34.3990540002154],
   [-82.8417740000768, 34.3915530001384],
   [-82.8359750003203, 34.3809089998556],
   [-82.8344570002038, 34.3652210002402],
   [-82.813770000258, 34.3539349999756],
   [-82.8126069999687, 34.3511439999015],
   [-82.7944700000093, 34.3403789996455],
   [-82.7911779996831, 34.3305410002193],
   [-82.7917450001208, 34.3251609997746],
   [-82.7870610000748, 34.310299000091],
   [-82.7819929998892, 34.3031769998995],
   [-82.7821279996084, 34.2982780002757],
   [-82.7715150001095, 34.2860529999578],
   [-82.7667540000706, 34.2823220002287],
   [-82.7616880004332, 34.2816850001055],
   [-82.7542419997904, 34.2757870000993],
   [-82.7468110001145, 34.2665880001131],
   [-82.7485359999203, 34.2631259999822],
   [-82.7458250002755, 34.2583560000246],
   [-82.7455669996344, 34.2540660000195],
   [-82.7429199995637, 34.2510719999186],
   [-82.7448729998734, 34.2449189998725],
   [-82.7420120004408, 34.2293280003488],
   [-82.7443630002355, 34.2254100002966],
   [-82.7402569995995, 34.2194479999894],
   [-82.7423399996055, 34.2134319996609],
   [-82.7418750001734, 34.2102090003543],
   [-82.7321239997667, 34.1933210002598],
   [-82.731452999572, 34.1770859996921],
   [-82.7247690002589, 34.1686940003144],
   [-82.7174590000585, 34.15054600037],
   [-82.7041290001688, 34.1410209997378],
   [-82.6921519996409, 34.1389859997938],
   [-82.6862900001352, 34.1344540001146],
   [-82.6769849997923, 34.1314250002252],
   [-82.6685590000567, 34.1204349996344],
   [-82.6663439998061, 34.1127509999512],
   [-82.6587290000072, 34.1037720003535],
   [-82.6478360001259, 34.0985479997468],
   [-82.6424159995878, 34.0932640002823],
   [-82.6401690004485, 34.0873289997253],
   [-82.6453670003305, 34.0782570003232],
   [-82.6436609998929, 34.0721050000977],
   [-82.6389850002428, 34.0667790002054],
   [-82.6266319999712, 34.0633390001915],
   [-82.6212869997762, 34.0578289998252],
   [-82.6191560000895, 34.051350000167],
   [-82.6130140002014, 34.0468979998481],
   [-82.6094209995764, 34.0399059997713],
   [-82.5944589997672, 34.0293039998995],
   [-82.5954970003831, 34.0158649999619],
   [-82.5889589997615, 34.0005119997593],
   [-82.5850289997509, 33.9961710002058],
   [-82.575554000427, 33.9922110001394],
   [-82.5755689995973, 33.9902350002329],
   [-82.5801239997719, 33.9864469999251],
   [-82.5799799998319, 33.9805190003326],
   [-82.5675899997504, 33.9678800000908],
   [-82.5652020001458, 33.9567649998207],
   [-82.5567649996411, 33.9453240003525],
   [-82.5431069996883, 33.9409499997927],
   [-82.5341110000403, 33.9436509997457],
   [-82.5245150003426, 33.9433600000378],
   [-82.5114220002793, 33.9356129999049],
   [-82.4929289997694, 33.9097539996672],
   [-82.4801110000657, 33.9018969997905],
   [-82.4551049999469, 33.8816500003091],
   [-82.4405029999879, 33.8751229999019],
   [-82.4311499999645, 33.8670510000027],
   [-82.4248880004258, 33.8641329999241],
   [-82.4083530002485, 33.8663199999899],
   [-82.4038790002647, 33.8654750001573],
   [-82.395734999663, 33.8590879996938],
   [-82.3797710001214, 33.8510959997791],
   [-82.3717749996577, 33.8438130003418],
   [-82.3469329995705, 33.8342980003495],
   [-82.3378289999674, 33.827155999905],
   [-82.324479999811, 33.820033000212],
   [-82.314350000186, 33.8111110000208],
   [-82.3128999998415, 33.8074860000915],
   [-82.3002129996591, 33.8006270000251],
   [-82.2982860001869, 33.7835180001099],
   [-82.2858040000439, 33.7800580000657],
   [-82.2776810002569, 33.7720319996684],
   [-82.2661270002266, 33.766745000101],
   [-82.2632059996212, 33.7619620002777],
   [-82.2552669999572, 33.7596900000197],
   [-82.2474720002333, 33.7525909997437],
   [-82.2456639996885, 33.7434430001606],
   [-82.2390980003762, 33.7308719999014],
   [-82.2357530003466, 33.7143899999643],
   [-82.2371919999221, 33.7078799996671],
   [-82.2345719996156, 33.7002169999486],
   [-82.2178830003509, 33.6855390000442],
   [-82.2103610004384, 33.6698370001152],
   [-82.1998979998291, 33.661699999782],
   [-82.2011859997919, 33.6468979998474],
   [-82.1968950001062, 33.6314400001754],
   [-82.1861550003858, 33.620880999713],
   [-82.1761720001755, 33.613774999853],
   [-82.1562879997707, 33.6086300000434],
   [-82.1533570000178, 33.6063190002226],
   [-82.1488160000869, 33.5980920001972],
   [-82.1380279997876, 33.592235999987],
   [-82.1290799998201, 33.5899250000605],
   [-82.1175049998734, 33.5962290000749],
   [-82.1078399996804, 33.5962770001023],
   [-82.0941280001992, 33.5827420002901],
   [-82.0731039999696, 33.577510000373],
   [-82.0577270000588, 33.5667739998088],
   [-82.0463350004102, 33.5638299997541],
   [-82.0373749998488, 33.5546620002532],
   [-82.0330230000638, 33.5464540000039],
   [-82.0256130003043, 33.5427560002757],
   [-82.0234380002367, 33.5407339999699],
   [-82.0234380002367, 33.5379350001985],
   [-82.019837999939, 33.5350350000402],
   [-82.0109650003146, 33.5313399997429],
   [-82.0076379998283, 33.5233350001264],
   [-82.0013379997564, 33.520134999712],
   [-81.9919379998773, 33.5044349998335],
   [-81.9903820001259, 33.5007589999502],
   [-81.9909379997946, 33.494235000256],
   [-81.9859380002794, 33.486536000145],
   [-81.9341359999387, 33.4683370001796],
   [-81.9263360001919, 33.4629369998408],
   [-81.9194360000704, 33.4490980002991],
   [-81.9138520003991, 33.4420259996546],
   [-81.9131259999528, 33.4383329998282],
   [-81.917323999752, 33.4323460000122],
   [-81.9297409995977, 33.4295939996947],
   [-81.9324300003995, 33.4257060003182],
   [-81.9245500002867, 33.4191090001123],
   [-81.9107650001123, 33.4150350002047],
   [-81.9096930000596, 33.4119790002946],
   [-81.912098000281, 33.4082599997796],
   [-81.916405999685, 33.4073489998482],
   [-81.9217740003446, 33.4088669998119],
   [-81.9244980004082, 33.4124790002135],
   [-81.9313900001338, 33.4069560002002],
   [-81.9426689998045, 33.4091949997294],
   [-81.945181000156, 33.4075310002278],
   [-81.9444759996261, 33.4036740001875],
   [-81.9354530002138, 33.3978509998057],
   [-81.9308610002293, 33.3800760000393],
   [-81.9248370003419, 33.3741399998105],
   [-81.9257369999672, 33.3711399996703],
   [-81.9306340004486, 33.3681650003066],
   [-81.9346370002541, 33.3689399999794],
   [-81.9396369997693, 33.3725400003085],
   [-81.9463369998743, 33.3706399997156],
   [-81.9467369999074, 33.367340000111],
   [-81.944736999742, 33.3640409999814],
   [-81.9348320002478, 33.3560440001271],
   [-81.9356370003368, 33.3520409996773],
   [-81.9398369997859, 33.3477410002911],
   [-81.9397370002268, 33.3449410002176],
   [-81.9327369996478, 33.3435409998104],
   [-81.9247369998845, 33.3453410002734],
   [-81.9188000000358, 33.3402089998818],
   [-81.9132149996412, 33.3468209998112],
   [-81.9091370003909, 33.3466409997712],
   [-81.9084369998839, 33.3438419999326],
   [-81.9150370004297, 33.3415420002152],
   [-81.9191370003197, 33.334442000248],
   [-81.9081370003082, 33.3271419996434],
   [-81.9091370003909, 33.3239419996968],
   [-81.9137369998731, 33.3200420000205],
   [-81.9115369996911, 33.3191420001405],
   [-81.9061299995718, 33.321619999682],
   [-81.9060369996854, 33.3260420001562],
   [-81.9027369998616, 33.3302420001115],
   [-81.9036370003852, 33.334841999743],
   [-81.9013839996015, 33.335773999854],
   [-81.8965370002471, 33.3295419997925],
   [-81.8994370000378, 33.3251419999369],
   [-81.8990370000048, 33.3233420001356],
   [-81.8955370001644, 33.3216419999634],
   [-81.8905369997509, 33.3224419998039],
   [-81.8891369996351, 33.3210430001795],
   [-81.8925369999163, 33.3153430000343],
   [-81.8837370000868, 33.317643000095],
   [-81.8816359996391, 33.315843000053],
   [-81.8846369997121, 33.3089429996639],
   [-81.8807369998387, 33.3022430001737],
   [-81.8679359998535, 33.3140429996265],
   [-81.8649359996054, 33.3118429999316],
   [-81.86392899985, 33.3020360000786],
   [-81.8551859999219, 33.3103949997852],
   [-81.8473359999464, 33.306843000147],
   [-81.846036000288, 33.3043430000996],
   [-81.8495360001283, 33.3000440001662],
   [-81.8487360000622, 33.2967439996309],
   [-81.8503360001945, 33.2964439998763],
   [-81.8529360004095, 33.2996440001296],
   [-81.8600359996493, 33.2991440003199],
   [-81.8620359998147, 33.2969440001455],
   [-81.8632359999139, 33.2888439996449],
   [-81.8576360003491, 33.2846439997482],
   [-81.8456360002549, 33.2842439996936],
   [-81.8440360001226, 33.2786440002007],
   [-81.8388359996925, 33.2737450003605],
   [-81.8326360000781, 33.2758449997785],
   [-81.8357380004335, 33.2710449996366],
   [-81.8358360003427, 33.2628450000903],
   [-81.8334360001442, 33.2622450001062],
   [-81.8301360003205, 33.2645450000722],
   [-81.8283360001716, 33.2630450001707],
   [-81.8345359997861, 33.2583450000342],
   [-81.8438620000453, 33.2666209998427],
   [-81.8473359999464, 33.2663450003511],
   [-81.8481360000126, 33.2639449997476],
   [-81.8461359998471, 33.2581449997094],
   [-81.8410359998745, 33.2570449998798],
   [-81.8396359997587, 33.2552450002642],
   [-81.8451359997644, 33.2525450000476],
   [-81.8511370000856, 33.2525450000476],
   [-81.853137000251, 33.2507450001305],
   [-81.8520760000689, 33.2472879997288],
   [-81.8465359998802, 33.2417459996453],
   [-81.8350360002766, 33.2361450002085],
   [-81.8279360001385, 33.2287460000822],
   [-81.8096359999725, 33.2226469997511],
   [-81.807936000281, 33.2137459997335],
   [-81.8052380001567, 33.211440999812],
   [-81.798235999928, 33.2116470001143],
   [-81.7902360001646, 33.2084469998644],
   [-81.7845350003174, 33.2081460000757],
   [-81.7775349997385, 33.211346999659],
   [-81.777334999722, 33.2149469996285],
   [-81.7810349995788, 33.219847000365],
   [-81.7801349999536, 33.2211469998985],
   [-81.7740349998982, 33.2211469998985],
   [-81.7689349999256, 33.2174469996741],
   [-81.7635350003773, 33.2036480000503],
   [-81.7568350002723, 33.197947000001],
   [-81.7595350000465, 33.1947470003395],
   [-81.7606349996883, 33.1892479998323],
   [-81.7670350002176, 33.1865480001575],
   [-81.7676350002672, 33.1835479997331],
   [-81.7649349995947, 33.179949000269],
   [-81.7654350000853, 33.1783480002025],
   [-81.7686350003499, 33.1784490002911],
   [-81.7717350001572, 33.1815479997683],
   [-81.7724349997659, 33.1804490003196],
   [-81.7670350002176, 33.1721479998134],
   [-81.7682350003168, 33.1679489996425],
   [-81.764234999986, 33.1652490001248],
   [-81.7631350003442, 33.1594490002819],
   [-81.7521350003328, 33.1485499997635],
   [-81.7463349998531, 33.1460499999314],
   [-81.7438350000955, 33.1414500001122],
   [-81.7295340004353, 33.1337499996893],
   [-81.7217339997902, 33.1264509997383],
   [-81.7116340003024, 33.1219510002319],
   [-81.7046339997234, 33.1164499999009],
   [-81.6969340004341, 33.1165509997958],
   [-81.679432999611, 33.1117510003441],
   [-81.6584329996708, 33.1031520003521],
   [-81.6423329996867, 33.0931520002146],
   [-81.6172560003211, 33.0956979999089],
   [-81.6116569996829, 33.0942589997916],
   [-81.608285999714, 33.0890219998906],
   [-81.6129429999959, 33.0839810003568],
   [-81.6120719997846, 33.0800190003294],
   [-81.6068360002678, 33.0817160000397],
   [-81.6013200003686, 33.0870490001429],
   [-81.596500999705, 33.0798930001718],
   [-81.5989910003152, 33.0766819999215],
   [-81.6032979998944, 33.0750899996752],
   [-81.6022119995979, 33.0734729999828],
   [-81.5960250004023, 33.0726929997553],
   [-81.5935859998457, 33.0694959999394],
   [-81.592517000166, 33.0730619996912],
   [-81.5873100000633, 33.0698510000319],
   [-81.5843609998688, 33.0699020002753],
   [-81.5829749999967, 33.0685749996717],
   [-81.5831030000432, 33.0649599997257],
   [-81.5771870001109, 33.065939000258],
   [-81.5771600003467, 33.0643350003314],
   [-81.5809940000979, 33.0626970001126],
   [-81.5749109997609, 33.0617990001622],
   [-81.5745049998801, 33.0600489999486],
   [-81.5783319999586, 33.0589359999352],
   [-81.5781219998963, 33.0577740001343],
   [-81.5717980004332, 33.058078999632],
   [-81.5728799996334, 33.0541799999395],
   [-81.566310999948, 33.0534300002911],
   [-81.5627690002749, 33.056123000032],
   [-81.5669899996404, 33.060401000301],
   [-81.5633719997992, 33.0601560003265],
   [-81.5593990001309, 33.0549040000973],
   [-81.5591730001751, 33.0517649997265],
   [-81.560343999962, 33.0500359997262],
   [-81.5661079995584, 33.0487920003759],
   [-81.5619480002923, 33.0458440003695],
   [-81.5517859999819, 33.043390000063],
   [-81.5454399998792, 33.048992999692],
   [-81.5422420001627, 33.0473639998268],
   [-81.5387890001781, 33.0391849999032],
   [-81.5354979996768, 33.0384889996327],
   [-81.5291239999851, 33.0408100002752],
   [-81.5284510001405, 33.0356460002921],
   [-81.5227419998974, 33.0308360002385],
   [-81.5112449997685, 33.0277860000876],
   [-81.5116900000075, 33.0245059996612],
   [-81.5190749996526, 33.0231959998376],
   [-81.5160729997546, 33.020833000078],
   [-81.5102820003941, 33.021233000277],
   [-81.5082200003044, 33.0175099997787],
   [-81.5115619999609, 33.0123659998573],
   [-81.5106899999248, 33.0103150002216],
   [-81.5082179997563, 33.0105269996283],
   [-81.5066679998526, 33.0150820000836],
   [-81.504052999569, 33.0162530002592],
   [-81.4986799997849, 33.0113360001753],
   [-81.4919539997406, 33.0090930002662],
   [-81.4918990003874, 33.006693999665],
   [-81.4987529995798, 33.0050649997818],
   [-81.5006299997216, 33.0024540001514],
   [-81.4998299996554, 33.0006549999135],
   [-81.4911970002305, 32.9978239996235],
   [-81.4922519996665, 32.993509999835],
   [-81.4963270003403, 32.9902120003254],
   [-81.4941649997932, 32.985704999917],
   [-81.4956729998641, 32.9827259999999],
   [-81.4947360004289, 32.9789969997289],
   [-81.497837000061, 32.973010999828],
   [-81.4998299996554, 32.9638160002656],
   [-81.5024559998097, 32.9627979997273],
   [-81.5061420003212, 32.9641289997748],
   [-81.5088389997223, 32.9693680000638],
   [-81.5113469998758, 32.9667210002434],
   [-81.5086089995685, 32.9525510001989],
   [-81.4994460004142, 32.9449880001389],
   [-81.5027160001007, 32.9386879998894],
   [-81.5024270003957, 32.9353529997095],
   [-81.4998289998305, 32.932614000103],
   [-81.495092000081, 32.9315959998451],
   [-81.4831980002921, 32.9218019998572],
   [-81.487626000245, 32.9164070002115],
   [-81.4870489997617, 32.9147720002735],
   [-81.4800080000731, 32.9134439998975],
   [-81.481444000174, 32.9088949996996],
   [-81.4791800004179, 32.9042029996632],
   [-81.4808120003373, 32.8986139997091],
   [-81.4717890000267, 32.8938069996283],
   [-81.4707610003549, 32.9009020001676],
   [-81.4677000000074, 32.9008999999345],
   [-81.4644329997956, 32.8986029996512],
   [-81.4646549995535, 32.8959989999505],
   [-81.468232000285, 32.8932510001986],
   [-81.4661900002868, 32.8891280000302],
   [-81.471702999813, 32.8904389997783],
   [-81.4763960000798, 32.8879620002219],
   [-81.4794449998334, 32.8810820000997],
   [-81.475918000229, 32.8776400000424],
   [-81.4703760003904, 32.8762670000055],
   [-81.4680420003142, 32.876675000125],
   [-81.4671379995926, 32.8806019999314],
   [-81.4650510002869, 32.8808959999847],
   [-81.4605560003868, 32.8751610002813],
   [-81.4528829999633, 32.8729639998681],
   [-81.4513670003948, 32.8671649999653],
   [-81.4533609998142, 32.8638469998135],
   [-81.4530169998576, 32.8596359999565],
   [-81.456916999731, 32.8526039996845],
   [-81.4555590003463, 32.8465080000009],
   [-81.4602109997069, 32.8474020001113],
   [-81.4607220000681, 32.8460379996521],
   [-81.4527440000461, 32.8441499997642],
   [-81.4511990001653, 32.8479240002102],
   [-81.4448659995832, 32.8509659996835],
   [-81.4426710003224, 32.8501070000063],
   [-81.4428820002096, 32.845847000074],
   [-81.438651999725, 32.8400760000204],
   [-81.4335950003084, 32.8436739997131],
   [-81.4264750000789, 32.8407729998518],
   [-81.4216139995825, 32.8351779999851],
   [-81.4206200002459, 32.8312230001757],
   [-81.4236740000224, 32.828306999863],
   [-81.4299430001321, 32.8282460001454],
   [-81.4219519996913, 32.8214659997481],
   [-81.4230410003608, 32.8197469998999],
   [-81.4282350000448, 32.8185839999939],
   [-81.4243540004379, 32.816178999695],
   [-81.4205850000858, 32.8186509997793],
   [-81.4179840000459, 32.8181959998152],
   [-81.4213430003192, 32.8121079996621],
   [-81.4264449999416, 32.8107099998127],
   [-81.4224439997859, 32.8024770002129],
   [-81.4282919999462, 32.8008320000272],
   [-81.4252339999717, 32.7941899997306],
   [-81.419878999731, 32.7948570000874],
   [-81.4200109999754, 32.79288500006],
   [-81.4276669997822, 32.7876130002185],
   [-81.4220680000423, 32.785563000198],
   [-81.4222399995714, 32.7839859999334],
   [-81.4256359996546, 32.7826040002153],
   [-81.429976999569, 32.7852350000396],
   [-81.4312350002929, 32.7841410001432],
   [-81.4300779998513, 32.7817709996378],
   [-81.4248309995656, 32.7787029999992],
   [-81.4161680000035, 32.7797919996909],
   [-81.4144559997181, 32.7781489997835],
   [-81.4173530000341, 32.7733359999547],
   [-81.4298540004437, 32.7721289998963],
   [-81.4315130001272, 32.771483999737],
   [-81.4319399999245, 32.7689109998449],
   [-81.4189430001207, 32.7676289996866],
   [-81.4168340001753, 32.7653180001588],
   [-81.4189010002879, 32.7611320000993],
   [-81.4177659995877, 32.7602219998922],
   [-81.4146280001456, 32.7641840003182],
   [-81.4120930002279, 32.763865999908],
   [-81.4140380001417, 32.755423000361],
   [-81.4081590000193, 32.7558029998006],
   [-81.411567999623, 32.7531169996485],
   [-81.416732999893, 32.7532760003026],
   [-81.419697999981, 32.7491670001753],
   [-81.4178710000679, 32.7478259999781],
   [-81.4058659999509, 32.7491439997168],
   [-81.4049319999905, 32.7455590000717],
   [-81.4070429995856, 32.7406619998938],
   [-81.4136780002916, 32.7379919998398],
   [-81.4120990000757, 32.7326220001283],
   [-81.4185420002627, 32.7325849997122],
   [-81.4167520001596, 32.7287620003],
   [-81.4177689999607, 32.7250740000249],
   [-81.4105769997611, 32.7233169999461],
   [-81.4099879995822, 32.7218630000367],
   [-81.4203380002136, 32.7210509999422],
   [-81.4151200002402, 32.7136689998091],
   [-81.4211940003563, 32.7119780002853],
   [-81.4224379999381, 32.7096069998456],
   [-81.4259269999078, 32.7100370003537],
   [-81.4240790000783, 32.7062939997541],
   [-81.4275169999944, 32.7018950001655],
   [-81.4214189995888, 32.7004910003031],
   [-81.4178099999686, 32.6955690001903],
   [-81.4135770000093, 32.6954719996486],
   [-81.4124029998493, 32.6920950002862],
   [-81.4105449999741, 32.6925120002392],
   [-81.410874999687, 32.6968599997708],
   [-81.4090119997889, 32.6965510000977],
   [-81.4056840003761, 32.6898879999371],
   [-81.4070099999737, 32.685550000014],
   [-81.4012110002172, 32.6798820002499],
   [-81.404286999735, 32.6677969997263],
   [-81.397153999985, 32.6613779996431],
   [-81.4003419996558, 32.6584879996538],
   [-81.4032540000403, 32.6594640000508],
   [-81.4053689998335, 32.662919000076],
   [-81.4071930002718, 32.6605190002726],
   [-81.405272999574, 32.6565170001479],
   [-81.3994319999848, 32.6568129997625],
   [-81.3938180001762, 32.6534909999192],
   [-81.3930330001787, 32.6515419999318],
   [-81.4035820001034, 32.6433970003622],
   [-81.4125529996371, 32.6415229997317],
   [-81.4118270000891, 32.6393829997294],
   [-81.4027350001816, 32.6370309996454],
   [-81.4072710000896, 32.6317369999012],
   [-81.4102599995687, 32.6313919997925],
   [-81.4128840000732, 32.6366799998985],
   [-81.414761000215, 32.6374389996699],
   [-81.4184309999346, 32.6347039996377],
   [-81.4186600002635, 32.6293920003122],
   [-81.4130869995645, 32.6248030001088],
   [-81.4119059997318, 32.6184089998332],
   [-81.4075170001369, 32.6165829996735],
   [-81.4064640003507, 32.6130419997642],
   [-81.3937649995744, 32.6023309999096],
   [-81.3892050002753, 32.5954159999788],
   [-81.3788830001313, 32.5889119996899],
   [-81.3760059999068, 32.5892560001633],
   [-81.3731449995759, 32.5920940002368],
   [-81.3692699998168, 32.5907049999979],
   [-81.3698189998128, 32.5850479999547],
   [-81.3635859996881, 32.5829769999051],
   [-81.3672479999101, 32.5783609999906],
   [-81.3667819997548, 32.5769149996284],
   [-81.3634939996266, 32.5769079998081],
   [-81.3601679998635, 32.5817780000796],
   [-81.3580310003291, 32.5822109999926],
   [-81.353492000048, 32.5787700001126],
   [-81.3583140001863, 32.5744510003706],
   [-81.3559259996834, 32.5716060001924],
   [-81.3286049997549, 32.5610920002033],
   [-81.3196020004342, 32.5595620003786],
   [-81.2976519997417, 32.562931000318],
   [-81.2953019997719, 32.5608059998027],
   [-81.2980719998662, 32.5553429997852],
   [-81.2961649995872, 32.5533449996879],
   [-81.2914799997163, 32.5557139999561],
   [-81.2913120003851, 32.5583649998029],
   [-81.2934559995923, 32.5615840003157],
   [-81.2909609998575, 32.5626629998506],
   [-81.2884669999477, 32.5584560003367],
   [-81.2899619995997, 32.5521799999118],
   [-81.2886269997813, 32.5511130001351],
   [-81.2851479998573, 32.5523450001266],
   [-81.2838580002447, 32.5565380001393],
   [-81.2805800001623, 32.55603000009],
   [-81.2787359996325, 32.5524460001497],
   [-81.2808739998901, 32.5478999998822],
   [-81.274800999599, 32.5444159998075],
   [-81.2752390001652, 32.5398130001969],
   [-81.2799220003863, 32.5376359996473],
   [-81.2796550004227, 32.5363379999006],
   [-81.2746459997883, 32.5341879999847],
   [-81.2719230004479, 32.529984000339],
   [-81.2520630003325, 32.5181360003279],
   [-81.2474549995562, 32.5182809997962],
   [-81.2439370001725, 32.5209039996621],
   [-81.2408649999544, 32.5164810003762],
   [-81.2337940001286, 32.5183099998132],
   [-81.2323220000428, 32.5120820001323],
   [-81.2371590002497, 32.5112959998605],
   [-81.2388210003063, 32.5080519998938],
   [-81.2377950002844, 32.5052559998195],
   [-81.2342540004362, 32.5027469998274],
   [-81.2331110002383, 32.4980690000509],
   [-81.2004080003049, 32.4683139999976],
   [-81.1947700002069, 32.4651619999239],
   [-81.1871410001644, 32.4641489997358],
   [-81.1886020003795, 32.4612260000043],
   [-81.1944120000066, 32.4589089998118],
   [-81.1920129996331, 32.453786000316],
   [-81.1976690001727, 32.4546080000501],
   [-81.1978680003643, 32.4485549999741],
   [-81.2032860003543, 32.4529929997544],
   [-81.2057740004163, 32.4504740001074],
   [-81.2063180003895, 32.4470070000225],
   [-81.2007549997362, 32.4460860001152],
   [-81.1992919998712, 32.4429140003115],
   [-81.2005849998569, 32.4406889999511],
   [-81.2083889998017, 32.4372499998485],
   [-81.2051140000923, 32.4272249996893],
   [-81.2054050003454, 32.4233390002681],
   [-81.1782829997102, 32.3929860003586],
   [-81.177783999943, 32.3862310003466],
   [-81.1818450003731, 32.3793480002144],
   [-81.1767269999588, 32.3767189997468],
   [-81.1679139997105, 32.3684819996713],
   [-81.168995999809, 32.3651189999848],
   [-81.1739629997123, 32.3629169998089],
   [-81.1667570001673, 32.3616030001181],
   [-81.167569999754, 32.3587469998051],
   [-81.1609790003273, 32.3552440001325],
   [-81.160801000052, 32.3499610002288],
   [-81.1549629999375, 32.3502469998997],
   [-81.1552969998484, 32.3439020000924],
   [-81.1599840002674, 32.3428520000892],
   [-81.1599950001381, 32.3404760000919],
   [-81.152819999657, 32.3413210002688],
   [-81.1495469995974, 32.3450790001119],
   [-81.1453270000568, 32.3451600003129],
   [-81.1479389999674, 32.348912999672],
   [-81.1438569996208, 32.3515430000618],
   [-81.1413660000841, 32.3501470001017],
   [-81.1392959995985, 32.3450249996846],
   [-81.1334819997734, 32.340977999697],
   [-81.1296150004103, 32.3417039998843],
   [-81.1285349999616, 32.3383809996944],
   [-81.1289559999111, 32.3364520002278],
   [-81.132803999906, 32.3347460003389],
   [-81.1380129996585, 32.3282400001449],
   [-81.1355460004112, 32.3243229999105],
   [-81.1307350001436, 32.3257560003563],
   [-81.1316270002712, 32.3186440002987],
   [-81.1297769998937, 32.3132899996631],
   [-81.1330159996181, 32.3085459997113],
   [-81.128563000449, 32.306872999645],
   [-81.1252340003129, 32.309075000168],
   [-81.1226309997249, 32.3069899998573],
   [-81.1239170000378, 32.2951939998012],
   [-81.1204559996573, 32.2908750003026],
   [-81.119628000002, 32.2867780000315],
   [-81.1271409996936, 32.2822549999507],
   [-81.1264629998262, 32.2792489996219],
   [-81.1232759999803, 32.2769750003691],
   [-81.1235050003092, 32.2751430001428],
   [-81.1294789999678, 32.2762179998717],
   [-81.1331789998247, 32.2696780001984],
   [-81.1360329995846, 32.2726629996604],
   [-81.1421080004239, 32.2715760002175],
   [-81.137924999795, 32.2688910002838],
   [-81.1369299997352, 32.2661149996582],
   [-81.1393669997436, 32.2636999996224],
   [-81.1463170000938, 32.2633230001037],
   [-81.1447389997028, 32.2580599999731],
   [-81.1481239999154, 32.2550910000908],
   [-81.1459050003651, 32.2507240001271],
   [-81.1544649999951, 32.2469210003612],
   [-81.1566069995524, 32.2432839999422],
   [-81.1528830003045, 32.2367869996395],
   [-81.1457709995725, 32.2323430003147],
   [-81.1436340000381, 32.2292659996513],
   [-81.1463489998808, 32.224555000049],
   [-81.1520639999717, 32.2220420001261],
   [-81.1431749995555, 32.2217389996773],
   [-81.1389249998777, 32.2197679996758],
   [-81.1360119996682, 32.212853999975],
   [-81.1274200002512, 32.2075540001776],
   [-81.1231489997587, 32.2013200000096],
   [-81.1218850000854, 32.1955379997168],
   [-81.1140319997368, 32.1957010000727],
   [-81.1148209999323, 32.1934229998783],
   [-81.1217949996737, 32.1917939998398],
   [-81.1211579998141, 32.1900799996512],
   [-81.118237000107, 32.1892029996458],
   [-81.1179349999832, 32.1852970000016],
   [-81.1204389999388, 32.1786999999325],
   [-81.1169330002507, 32.1761979998104],
   [-81.1119329998372, 32.1780020003571],
   [-81.1102339999707, 32.176800000315],
   [-81.1111319999461, 32.1727029998229],
   [-81.1169459997712, 32.1709140002415],
   [-81.1194370002063, 32.1753619997162],
   [-81.1211489995933, 32.1749020003349],
   [-81.1281419996012, 32.169097000063],
   [-81.1297559999773, 32.1658389998925],
   [-81.1220299998503, 32.1617959999546],
   [-81.1221369999804, 32.1573039999544],
   [-81.1200339998829, 32.1533030000362],
   [-81.1122359997859, 32.1508040001981],
   [-81.1122340001361, 32.1493060000229],
   [-81.1183309998184, 32.144408000247],
   [-81.1199950004231, 32.1342649998018],
   [-81.1152469999047, 32.1255039997465],
   [-81.1172250003288, 32.1176040000866],
   [-81.1133429999987, 32.1132000001102],
   [-81.0915749997794, 32.1109090000262],
   [-81.0905239996431, 32.1063779999071],
   [-81.0882060003587, 32.1039430001275],
   [-81.0754430000083, 32.0970679997562],
   [-81.0669650003941, 32.0903839997379],
   [-81.0503170002389, 32.0853239998983],
   [-81.032633000016, 32.0854760000152],
   [-81.0216840000582, 32.0908639999452],
   [-81.0119700003597, 32.1001779997659],
   [-81.0067599998839, 32.1011569997307],
   [-81.0023179996872, 32.1000559999576],
   [-80.9944700002599, 32.0947239996433],
   [-80.9831920004141, 32.0796590003254],
   [-80.9544830000654, 32.0686160000137],
   [-80.9267689997765, 32.0416629996252],
   [-80.9180030002822, 32.0375789999264],
   [-80.8855299996043, 32.0345960003173],
   [-80.7514289999175, 32.0334679997907],
   [-80.7580339995879, 32.0259819999681],
   [-80.7697680004416, 32.0197930001568],
   [-80.78069300011, 32.0113700001467],
   [-80.782997000049, 31.9956669999975],
   [-80.7862399999714, 31.9857519998888],
   [-80.7866819998372, 31.9756130002802],
   [-80.789841000094, 31.9643899997929],
   [-80.7962719996871, 31.9534589997538],
   [-80.8040539998906, 31.9461310001168],
   [-80.8146060001884, 31.9399990000606],
   [-80.8192750001658, 31.933854999874],
   [-80.8292240000409, 31.9265840002415],
   [-80.8553390000223, 31.9108090002869],
   [-80.8748130003484, 31.8807040002901],
   [-80.8887790002727, 31.8657820003761],
   [-80.8969880002734, 31.8528060002491],
   [-80.9117049998601, 31.8406490001867],
   [-80.9168170004267, 31.8383770001118],
   [-80.9221340001343, 31.8252790002696],
   [-80.9285890000169, 31.8169819996656],
   [-80.9383160001341, 31.8090080001638],
   [-80.9496309997899, 31.8030489999674],
   [-80.9707260001646, 31.7983529996809],
   [-80.9730989997006, 31.7882230000801],
   [-80.9769519997183, 31.7808759996612],
   [-80.9830020004433, 31.7726820000139],
   [-80.9961699999513, 31.762132999736],
   [-80.9972000001712, 31.7485029997223],
   [-81.0138120003414, 31.7286430002474],
   [-81.0258240001311, 31.7202270000481],
   [-81.0566230001586, 31.7148750002405],
   [-81.0515209996378, 31.7051980000601],
   [-81.0497890001593, 31.6886330000446],
   [-81.0590059997403, 31.6660079998228],
   [-81.0666349997829, 31.6573279999348],
   [-81.0696330003812, 31.6424049996219],
   [-81.0658260003942, 31.6252269996461],
   [-81.0766830002905, 31.5979459997584],
   [-81.0831830003789, 31.5649089999982],
   [-81.0870670003588, 31.5291580003819],
   [-81.0923609996019, 31.5231699996346],
   [-81.0996859998708, 31.5185259997854],
   [-81.1071400000112, 31.49663200036],
   [-81.1149980003826, 31.4870110001142],
   [-81.1254350001544, 31.4775410001215],
   [-81.1335219999563, 31.4737399997977],
   [-81.1438449999253, 31.4715210001452],
   [-81.1501459998221, 31.4580399997009],
   [-81.1635510000549, 31.4420340002614],
   [-81.162444999667, 31.4370270000748],
   [-81.1669389997422, 31.4234449997158],
   [-81.1821900001546, 31.4053229999992],
   [-81.1907580001805, 31.3998759996896],
   [-81.1756130000733, 31.3824789999309],
   [-81.1710889998609, 31.3703229997094],
   [-81.1719819998134, 31.3552990002009],
   [-81.1771160001213, 31.3392770002796],
   [-81.1861190003404, 31.3267960001731],
   [-81.1833500000709, 31.311235000132],
   [-81.1846849998894, 31.3011209998032],
   [-81.1825640002484, 31.2689909996624],
   [-81.1876750000917, 31.2462579996217],
   [-81.2024519999529, 31.2276780002779],
   [-81.2091369999893, 31.2131269997855],
   [-81.2162630000666, 31.2046510001817],
   [-81.2221599997324, 31.187968999842],
   [-81.2343370002768, 31.1702580002573],
   [-81.2479099998408, 31.1608119997539],
   [-81.2669680002402, 31.1570320002452],
   [-81.2921450000632, 31.1554149996409],
   [-81.2964170003806, 31.151879999777],
   [-81.2927250000214, 31.1428260002967],
   [-81.2940099996111, 31.1356660002],
   [-81.2898710002614, 31.1311570001793],
   [-81.2865300004298, 31.1166860003658],
   [-81.2863160001695, 31.1030830001343],
   [-81.2901990003245, 31.093493999788],
   [-81.2978740003978, 31.0809519997656],
   [-81.313637000098, 31.0678520003828],
   [-81.3261719999444, 31.0626599997987],
   [-81.3453059996135, 31.0593620001021],
   [-81.35341599988, 31.0371210000536],
   [-81.3444980000498, 31.0181099997899],
   [-81.337944000433, 31.0163149999148],
   [-81.3239440001734, 31.0088100003775],
   [-81.3107089998199, 30.9973289996304],
   [-81.3047789996439, 30.9857120001041],
   [-81.3048320002457, 30.9707739998603],
   [-81.3073040004142, 30.9622270000666],
   [-81.3175350003216, 30.9485000000594],
   [-81.3332290004248, 30.9365860003029],
   [-81.3452069998793, 30.9315030000708],
   [-81.3502039999198, 30.8891089998648],
   [-81.3588559996113, 30.8675120000572],
   [-81.3633420001889, 30.8505569996303],
   [-81.3626819998648, 30.8459260001677],
   [-81.3748090001806, 30.8173769997666],
   [-81.3863299997006, 30.8013710003063],
   [-81.4004820002962, 30.7664260003031],
   [-81.3913040001749, 30.7646660002961],
   [-81.3748090001806, 30.7574829998818],
   [-81.3591460000395, 30.7443140002903],
   [-81.3513949997982, 30.7308789997918],
   [-81.3502499999506, 30.7214030002022],
   [-81.3470150004241, 30.7124439999292],
   [-81.404174999582, 30.7124389999371],
   [-81.403701999754, 30.7098139999572],
   [-81.4512409999981, 30.7094840001921],
   [-81.4741130000572, 30.7139130001327],
   [-81.4848250001886, 30.7251129998539],
   [-81.4884639999461, 30.7263090003412],
   [-81.5098870003839, 30.7226140003214],
   [-81.5322949999374, 30.7240910003031],
   [-81.5344919997463, 30.7200740001842],
   [-81.5340110004207, 30.7114469999284],
   [-81.5383909997948, 30.7086559998977],
   [-81.5428310003417, 30.7111340000854],
   [-81.5460199998374, 30.7169420002713],
   [-81.5483770003782, 30.7182790001773],
   [-81.5614240004107, 30.7116720000861],
   [-81.563461000386, 30.7134230001864],
   [-81.5635070004168, 30.7175100002342],
   [-81.5742179998249, 30.7225039996799],
   [-81.5876840001571, 30.7236350000486],
   [-81.592657999733, 30.7189079998362],
   [-81.596176000015, 30.7186659996145],
   [-81.5973729997412, 30.7202259996875],
   [-81.5965340002153, 30.7274719999943],
   [-81.601881999885, 30.7295859997904],
   [-81.605833999637, 30.727263999624],
   [-81.6060479998972, 30.7186759997251],
   [-81.6098699999528, 30.7159329998042],
   [-81.6119300003927, 30.7172009996306],
   [-81.6141659996613, 30.7223529999567],
   [-81.6229780000847, 30.7234669998988],
   [-81.6234340001943, 30.7255549998321],
   [-81.6206809998184, 30.7309589997786],
   [-81.6243659996066, 30.7363400000544],
   [-81.6283309997773, 30.7353940003677],
   [-81.6288289997197, 30.7293070001682],
   [-81.6304320002251, 30.7280880000322],
   [-81.6326819997374, 30.7285960000951],
   [-81.6366190003191, 30.7339379999237],
   [-81.6435849996645, 30.7282029996546],
   [-81.6485359996742, 30.7274230001294],
   [-81.6521529996904, 30.7288700003187],
   [-81.6540900001066, 30.7330929998569],
   [-81.6518280000004, 30.7422080003812],
   [-81.6543040003669, 30.7443599998222],
   [-81.6608350004174, 30.7423399997834],
   [-81.6630089997618, 30.743492000085],
   [-81.6634819995898, 30.7458530000589],
   [-81.6597290000295, 30.7497160002197],
   [-81.6623230003968, 30.7541770001288],
   [-81.669554999881, 30.7517009996224],
   [-81.6790689995629, 30.7509060002309],
   [-81.6830959996579, 30.7484919999775],
   [-81.6827769998157, 30.7459680000982],
   [-81.6799079999871, 30.7446920002901],
   [-81.6734840000667, 30.7485500002581],
   [-81.670836999996, 30.7473959998763],
   [-81.6688379996555, 30.7425140000527],
   [-81.6724940000297, 30.7387900001693],
   [-81.6794200000905, 30.7415200001943],
   [-81.6891549997054, 30.7416319996797],
   [-81.6938930001781, 30.7484719997662],
   [-81.7020870001102, 30.7454700001597],
   [-81.709113999555, 30.7479759999902],
   [-81.7201300003582, 30.7447190000127],
   [-81.7320550001093, 30.7498150001781],
   [-81.7332150000256, 30.7512760002932],
   [-81.7318569997426, 30.7549949998464],
   [-81.7421949997802, 30.7592540001935],
   [-81.7407529998316, 30.7650069997816],
   [-81.7559430001446, 30.769344000084],
   [-81.7617200001598, 30.7756980002997],
   [-81.7707059997621, 30.7768199997733],
   [-81.7702639998963, 30.7736700001127],
   [-81.7735899996593, 30.7677079998116],
   [-81.7715679997526, 30.7641430001279],
   [-81.7826299996883, 30.7614539999942],
   [-81.7827600002829, 30.7666130003614],
   [-81.7866969999663, 30.7698019999971],
   [-81.7837899996046, 30.7733330000774],
   [-81.7913509998751, 30.7828040000251],
   [-81.793074999856, 30.7872510000775],
   [-81.7971260002404, 30.7850299999438],
   [-81.795967000149, 30.7821290000533],
   [-81.7971720002711, 30.7814690000112],
   [-81.8018870002793, 30.7868750000304],
   [-81.8074490002093, 30.7901189997673],
   [-81.8194879997632, 30.7898859997327],
   [-81.8237990004387, 30.7868119997001],
   [-81.8300090000989, 30.789359999685],
   [-81.8394769997501, 30.7865890001471],
   [-81.8418800003216, 30.7875690003122],
   [-81.8402709999684, 30.7925910000413],
   [-81.8458710004316, 30.7907499997219],
   [-81.8526910001872, 30.7948969996143],
   [-81.8685149999867, 30.7929060000968],
   [-81.8711389995929, 30.7980399998351],
   [-81.8826669996839, 30.8002819998691],
   [-81.8821410001525, 30.8048339996385],
   [-81.8845289997571, 30.8075979999349],
   [-81.8847189997279, 30.8114219997342],
   [-81.8914339999015, 30.8165860000991],
   [-81.8919750003999, 30.8248819999382],
   [-81.8955379999893, 30.825289000215],
   [-81.9012449996843, 30.8298800000074],
   [-81.906968999996, 30.8264899997602],
   [-81.9042130001454, 30.818589999696],
   [-81.9091109995533, 30.8156800001825],
   [-81.9186469998749, 30.8178179998456],
   [-81.9287410004133, 30.8175109998735],
   [-81.9298319998342, 30.8200719997421],
   [-81.9352570003952, 30.8210659997308],
   [-81.9335169996225, 30.8233760001365],
   [-81.936064999959, 30.8244820001175],
   [-81.9362940002878, 30.8277660000455],
   [-81.9388340002284, 30.8298489999107],
   [-81.9390789995525, 30.8272109998834],
   [-81.9428150002927, 30.8285039996906],
   [-81.9420240004473, 30.8255539997427],
   [-81.9498059997525, 30.8277639998213],
   [-81.9644849997044, 30.8172969998554],
   [-81.9612190002158, 30.8140369998352],
   [-81.96388200018, 30.8129459996762],
   [-81.9619439999389, 30.80870799999],
   [-81.9650420000963, 30.806806999988],
   [-81.9617230000059, 30.8009929999138],
   [-81.9621730002677, 30.7969779999639],
   [-81.9690239999854, 30.7912769999886],
   [-81.9701229998024, 30.7843569997443],
   [-81.9736400002594, 30.7787190003545],
   [-81.9780420002731, 30.7765520001283],
   [-81.9810199998816, 30.7769760002942],
   [-81.9887119996734, 30.7803629996215],
   [-81.9955730003352, 30.786838999957],
   [-81.997406000096, 30.7863910001453],
   [-82.0035870003422, 30.7916749997311],
   [-82.0058519999231, 30.7903860000798],
   [-82.0076739998133, 30.7930370001149],
   [-82.0104459995575, 30.789979000099],
   [-82.0141839999475, 30.789209999951],
   [-82.0129739998025, 30.7909309996882],
   [-82.016327000228, 30.7915800000276],
   [-82.0177149997499, 30.7905770003225],
   [-82.0169749999582, 30.7877979999901],
   [-82.0228800000199, 30.7877059999728],
   [-82.0236430002762, 30.7830820003729],
   [-82.0173800000142, 30.7757999998103],
   [-82.0183650000283, 30.772610999819],
   [-82.0151599997408, 30.7719710000463],
   [-82.011602999999, 30.7618780003019],
   [-82.0201640003523, 30.7578389999705],
   [-82.0175890002516, 30.754991000229],
   [-82.0213679997511, 30.7551929996149],
   [-82.0218439999522, 30.7536260000704],
   [-82.0276810002418, 30.7509350000346],
   [-82.0316790000245, 30.7503879997627],
   [-82.0345860003862, 30.7542890003365],
   [-82.0367730001494, 30.7544340003499],
   [-82.0397950001388, 30.7472969997476],
   [-82.0388560001554, 30.7436979997431],
   [-82.0404510002649, 30.7379720002531],
   [-82.0388939997903, 30.7361529998831],
   [-82.0440709997558, 30.7312629997408],
   [-82.0419309998483, 30.7300089998941],
   [-82.0455269999481, 30.7278810002117],
   [-82.0385130000238, 30.722636999864],
   [-82.0395659998099, 30.7192990001652],
   [-82.037117000106, 30.718537999807],
   [-82.0388939997903, 30.7176510003756],
   [-82.0390389995552, 30.7149520000588],
   [-82.0368040001115, 30.7130330001207],
   [-82.0359270000524, 30.7062049998579],
   [-82.0397790002453, 30.7021920003346],
   [-82.0395659998099, 30.7006780000635],
   [-82.0429459999996, 30.7008859998492],
   [-82.0412439997601, 30.699384999828],
   [-82.0440370003189, 30.6973690001449],
   [-82.0416789999532, 30.6961520001404],
   [-82.0412370000874, 30.6910319997311],
   [-82.0429839996345, 30.6900650001361],
   [-82.0436859997914, 30.6839470002647],
   [-82.0484309999367, 30.6824319999505],
   [-82.0462950002272, 30.6811239999902],
   [-82.0502550003751, 30.6761150000151],
   [-82.0482789996008, 30.6689060002808],
   [-82.0459899997304, 30.6663699997859],
   [-82.0480590003911, 30.6650310003423],
   [-82.0464709999543, 30.6608059997324],
   [-82.0483439998981, 30.6629259999176],
   [-82.0493959998593, 30.6620070001447],
   [-82.0490229995904, 30.6552950001305],
   [-82.0443450002905, 30.6529409998064],
   [-82.0460759999441, 30.6520719996677],
   [-82.0416410003184, 30.648981000359],
   [-82.038611999758, 30.6409469997528],
   [-82.0402450004006, 30.6397090002048],
   [-82.0387680002919, 30.6386630003241],
   [-82.0404899997246, 30.636050000175],
   [-82.0358199999223, 30.6318529997692],
   [-82.0367299995933, 30.6299749997672],
   [-82.0331710002018, 30.6294930001675],
   [-82.0349589997568, 30.6268369998642],
   [-82.0316840000474, 30.6250159999612],
   [-82.0316460004125, 30.6195300001847],
   [-82.0295180002006, 30.6191579998107],
   [-82.0299829996327, 30.6219120000302],
   [-82.0282589996518, 30.6220109997033],
   [-82.0262829997758, 30.6133900002133],
   [-82.0288849996407, 30.6116849998191],
   [-82.0268090002056, 30.6108719996154],
   [-82.0272290003302, 30.6066610001761],
   [-82.0236549999717, 30.6067750001295],
   [-82.0150869999458, 30.6006020002239],
   [-82.0144490002614, 30.5962859997129],
   [-82.0122189999422, 30.5939100002794],
   [-82.0127930000525, 30.5903029999331],
   [-82.011202999966, 30.588991000028],
   [-82.0121579998428, 30.5862620002887],
   [-82.010208999731, 30.5864190003047],
   [-82.0107540004274, 30.5837169999653],
   [-82.0088780001105, 30.5829359999954],
   [-82.0089950002864, 30.5801489998395],
   [-82.0061259995595, 30.5771240002967],
   [-82.0080260001658, 30.5770299996402],
   [-82.0061830003593, 30.5749569998982],
   [-82.0078799996777, 30.5749690002436],
   [-82.0065320003387, 30.572940999681],
   [-82.0082300003803, 30.5697119999067],
   [-82.005820999961, 30.5651330001663],
   [-82.0084069999323, 30.5636579996619],
   [-82.0054009998364, 30.5635739997931],
   [-82.0080329998385, 30.5602529996401],
   [-82.0107109998714, 30.5607359997271],
   [-82.010024999608, 30.557872999718],
   [-82.0084680000317, 30.5580939998983],
   [-82.0095969999858, 30.5562060003266],
   [-82.0084229998259, 30.5545290000773],
   [-82.0111390003919, 30.5534729998681],
   [-82.0099500001633, 30.5504339999885],
   [-82.0131660003214, 30.5499459998026],
   [-82.0134120003687, 30.542444000201],
   [-82.0157539999426, 30.539538999633],
   [-82.0166090002603, 30.5347330001333],
   [-82.0190499995684, 30.5330120002064],
   [-82.0176009999471, 30.532319999762],
   [-82.018303000104, 30.5285209998077],
   [-82.0154340002755, 30.5285009997177],
   [-82.0164410000309, 30.5268989997986],
   [-82.0181809999053, 30.5275100000465],
   [-82.0186310001671, 30.5238340003734],
   [-82.0162960002659, 30.521903999955],
   [-82.0185850001363, 30.5194890001358],
   [-82.0155410004056, 30.5200980001296],
   [-82.0145639998891, 30.51231199995],
   [-82.017020999989, 30.5071319998792],
   [-82.0170870001112, 30.5020760001716],
   [-82.015149999695, 30.4998919998515],
   [-82.017754000108, 30.4957919997669],
   [-82.0157170001327, 30.494821999617],
   [-82.0180169998737, 30.4920749999703],
   [-82.0164050000458, 30.488670000173],
   [-82.0171430001877, 30.4855830001394],
   [-82.0155480000783, 30.4839580000591],
   [-82.0192179997979, 30.4829790001155],
   [-82.0174329997176, 30.4798749998542],
   [-82.0193480003926, 30.4789759999952],
   [-82.0169060003613, 30.4751109998016],
   [-82.0235949996973, 30.4672119996521],
   [-82.0241639997847, 30.4644789997863],
   [-82.0222719995744, 30.4613819996539],
   [-82.0283199997512, 30.455391000201],
   [-82.026870000305, 30.4537219999641],
   [-82.0296250003307, 30.4507429996371],
   [-82.028144999849, 30.4472520001645],
   [-82.0324699998698, 30.44159899968],
   [-82.0338740001836, 30.4423160003766],
   [-82.0360640003198, 30.4384630002697],
   [-82.0400010000031, 30.4366609996653],
   [-82.0394589996798, 30.4343719996962],
   [-82.0377270002013, 30.4354739999431],
   [-82.0353239996298, 30.4279920000188],
   [-82.033782999947, 30.427649999879],
   [-82.0360030002204, 30.4249670002016],
   [-82.0340649999793, 30.420032000341],
   [-82.0375520002991, 30.4175379998628],
   [-82.0405419996032, 30.4176389997399],
   [-82.0391390000127, 30.4170510000086],
   [-82.0395049997105, 30.4155030002653],
   [-82.0409850001923, 30.416596999766],
   [-82.0437619999593, 30.4148689996808],
   [-82.0408470001, 30.4149379998537],
   [-82.0433579997283, 30.4130500000067],
   [-82.0418680000991, 30.4113140001226],
   [-82.043632000263, 30.4105570002363],
   [-82.0438990002267, 30.4079899997329],
   [-82.041288000141, 30.4066690003764],
   [-82.0446850000491, 30.4039630002306],
   [-82.0400649995772, 30.3913849996308],
   [-82.0358349999909, 30.3850130000687],
   [-82.0381559996484, 30.3822090003341],
   [-82.0366330004073, 30.378095999775],
   [-82.0388119997745, 30.3746929997549],
   [-82.0367649997534, 30.375109000169],
   [-82.0381409995798, 30.3729240003594],
   [-82.0371570002889, 30.3720330000984],
   [-82.0407509998406, 30.3705559999527],
   [-82.041757999596, 30.3676200002895],
   [-82.0444869996824, 30.3676720002279],
   [-82.0421390002608, 30.3654090003575],
   [-82.0451169998693, 30.3663070002399],
   [-82.0477709996127, 30.363485000157],
   [-82.0580910001069, 30.3592140003484],
   [-82.0624210001506, 30.3606489997934],
   [-82.0628560003437, 30.3587549998477],
   [-82.0654210003987, 30.3585500001256],
   [-82.0649109998624, 30.3564329997366],
   [-82.0661839997566, 30.3557569997647],
   [-82.0726679999515, 30.36141000001],
   [-82.0811139997787, 30.3587679996568],
   [-82.0833180001586, 30.3603340000928],
   [-82.0947180002031, 30.3607030001495],
   [-82.0986990002674, 30.3640609999277],
   [-82.1010750001764, 30.3627909998432],
   [-82.1006280002877, 30.3644170000436],
   [-82.1023749998348, 30.3643789996314],
   [-82.1015589998751, 30.3666399996195],
   [-82.1052410001886, 30.3688960001501],
   [-82.1103999997125, 30.3667200003745],
   [-82.1103319999404, 30.3652089999289],
   [-82.111521000169, 30.3675569998056],
   [-82.1157750000447, 30.3680530000204],
   [-82.1181490003039, 30.3643580000705],
   [-82.1268539996989, 30.3682620000654],
   [-82.1286720002894, 30.3670829997713],
   [-82.1275640002517, 30.3654380001027],
   [-82.1338640003235, 30.3622370002553],
   [-82.1370639996899, 30.3625160002162],
   [-82.1369420003895, 30.3642239997759],
   [-82.1399619998307, 30.3649189998599],
   [-82.1485490001232, 30.3608980001972],
   [-82.1500990000268, 30.3618299999324],
   [-82.1578970001238, 30.3601219996469],
   [-82.1617769999057, 30.3571110003485],
   [-82.1674130003539, 30.360002000083],
   [-82.1701969997936, 30.3589719997736],
   [-82.1737450002128, 30.3650659997244],
   [-82.1809909999407, 30.3686110000644],
   [-82.1802400002784, 30.3725799999518],
   [-82.1834930002465, 30.3733380002226],
   [-82.1847949995547, 30.378936000173],
   [-82.1871090004377, 30.378983999867],
   [-82.1899149996188, 30.3761700001752],
   [-82.1927859999955, 30.3790650003143],
   [-82.1934220000301, 30.3833449996141],
   [-82.1917890002858, 30.3847159999025],
   [-82.1942079998526, 30.3892040002748],
   [-82.1965839997616, 30.3882779996378],
   [-82.1992249999845, 30.389832000166],
   [-82.1981079997259, 30.3918430000086],
   [-82.2014000000521, 30.3953500001479],
   [-82.2028570000693, 30.4012559999805],
   [-82.2042449995912, 30.4015530002248],
   [-82.2037600000676, 30.4053109996469],
   [-82.2067550002929, 30.4068429996387],
   [-82.2053269996897, 30.4083399998233],
   [-82.2057550002102, 30.411946000245],
   [-82.2083839998392, 30.4143040002472],
   [-82.2064330000776, 30.4170840003364],
   [-82.2080340000348, 30.4174669997793],
   [-82.2074619995743, 30.4211199998234],
   [-82.209198999974, 30.421329000226],
   [-82.2108600002057, 30.4253790002332],
   [-82.2090049998052, 30.4283369996991],
   [-82.2097200003809, 30.4330550001432],
   [-82.2064160003592, 30.4357250000103],
   [-82.2073510001445, 30.4391730001038],
   [-82.2037860000069, 30.4449840002767],
   [-82.2057379995934, 30.4510479998631],
   [-82.2040579999935, 30.4528640003476],
   [-82.2044949998365, 30.4553419996428],
   [-82.2073789997336, 30.456980999885],
   [-82.207700000124, 30.4604069997753],
   [-82.2061510000453, 30.4624149998151],
   [-82.2063719999782, 30.4685149996949],
   [-82.2044660004225, 30.4687560002389],
   [-82.204374000361, 30.4710950002278],
   [-82.2010350001791, 30.4746649996619],
   [-82.2014729998471, 30.4800630002666],
   [-82.2032060000488, 30.4821839997281],
   [-82.2012400002186, 30.4851139997144],
   [-82.206670999729, 30.493205999872],
   [-82.2115780002561, 30.4972889996486],
   [-82.2126319998672, 30.5005489997943],
   [-82.2250720001774, 30.5076900002428],
   [-82.2303040003945, 30.5173250001789],
   [-82.2293009999387, 30.5205869999853],
   [-82.2307589997808, 30.5265760002264],
   [-82.2373879997407, 30.5322159997566],
   [-82.2404030000574, 30.5377700001006],
   [-82.2388790000931, 30.5455049998881],
   [-82.2344350002465, 30.5487549999861],
   [-82.2341859998262, 30.5576500001671],
   [-82.2238620000324, 30.5652760001063],
   [-82.216920999903, 30.56531599983],
   [-82.2143969998559, 30.566827000074],
   [-82.2146770002384, 30.5685560000252],
   [-82.5957350000345, 30.5925300000496],
   [-83.4999250002108, 30.6456739998242],
   [-84.2503570000762, 30.6841330000235],
   [-84.4744089996162, 30.6927930002523],
   [-84.6443320002085, 30.7019639996806],
   [-84.8646930001124, 30.7115419999388],
   [-84.8697520000772, 30.7218969999763],
   [-84.8754210001373, 30.7274909999352],
   [-84.8838209999337, 30.7325909999952],
   [-84.8875219996155, 30.7417909996424],
   [-84.8961220003267, 30.7505910000695],
   [-84.9002220002167, 30.751890999724],
   [-84.9073220003547, 30.7505910000695],
   [-84.9135219999692, 30.7522909997689],
   [-84.9150219996441, 30.7611909997878],
   [-84.9201230003399, 30.7659900001636],
   [-84.9180229997171, 30.7720900002152],
   [-84.9180229997171, 30.7780899998965],
   [-84.9283230001198, 30.7930900001245],
   [-84.9283230001198, 30.8050899996167],
   [-84.9374240002482, 30.820889000166],
   [-84.9348149998123, 30.8358509998322],
   [-84.9321379996044, 30.8382960001139],
   [-84.9270839996625, 30.8390239998433],
   [-84.9256650001784, 30.8426849999672],
   [-84.9278240003525, 30.8478890002372],
   [-84.9332239999008, 30.85148799981],
   [-84.9361239996915, 30.8569879996784],
   [-84.9364240001655, 30.8597879998124],
   [-84.9323240002755, 30.8642880002499],
   [-84.9398240004467, 30.8725880002787],
   [-84.934424, 30.8835879998483],
   [-84.9425250000457, 30.8884879997283],
   [-84.9561250002722, 30.9075869999065],
   [-84.9667260000755, 30.9172869998782],
   [-84.9710259999819, 30.9281869996492],
   [-84.9831270003584, 30.9347859999398],
   [-84.983026999901, 30.9425859996851],
   [-84.9795270000607, 30.955585999865],
   [-84.9801270001103, 30.9612860000458],
   [-84.9825270003088, 30.9655859999004],
   [-84.9872170002026, 30.9685429998344],
   [-84.9999279997762, 30.9711860000845],
   [-85.0057340001036, 30.9759080002493],
   [-85.0059340001202, 30.9798040000179],
   [-85.0023809996781, 30.9878360002195],
   [-85.0024989996789, 31.0006850000506],
   [-84.999428000184, 31.0138440003317],
   [-85.0045470004232, 31.0191820002359],
   [-85.0050510002134, 31.0247020000381],
   [-85.0094070001963, 31.0323770001701],
   [-85.0085769999929, 31.0440610003764],
   [-85.0113909995699, 31.053546999913],
   [-85.0181500001244, 31.0592540000434],
   [-85.0285729996525, 31.0745830003147],
   [-85.026067999872, 31.0841799998792],
   [-85.0297359999418, 31.0961629999622],
   [-85.0354569998804, 31.1043439998691],
   [-85.0356150000642, 31.1081920002228],
   [-85.0546769997632, 31.1208180001866],
   [-85.060432999862, 31.1314990003195],
   [-85.0640280001369, 31.142495000237],
   [-85.0693289999509, 31.1470080003182],
   [-85.0766280002806, 31.1569270001351],
   [-85.0835819999305, 31.1596300002253],
   [-85.0921059995755, 31.1602930003075],
   [-85.1002069996211, 31.165490000074],
   [-85.0985069999297, 31.1801530001337],
   [-85.1020519999758, 31.1847339998954],
   [-85.1075159999966, 31.186451000264],
   [-85.1069629998026, 31.2026929999745],
   [-85.0987039995732, 31.2112859997698],
   [-85.0987069999462, 31.2195110002483],
   [-85.0967629998573, 31.2256509997755],
   [-85.1123520003786, 31.2595800001957],
   [-85.1132610002247, 31.2643430001024],
   [-85.1119049995915, 31.2724769999475],
   [-85.114601000066, 31.2773330000273],
   [-85.1103580000609, 31.2817470000301],
   [-85.098712999794, 31.2844550001566],
   [-85.0897740000474, 31.2950259998644],
   [-85.0874039999861, 31.3112229997843],
   [-85.0837760000993, 31.3182100003289],
   [-85.0836969995582, 31.3238460000712],
   [-85.0841519998429, 31.3283129998341],
   [-85.0894230004181, 31.3360280000164],
   [-85.0870890003418, 31.3403209999204],
   [-85.0859179996566, 31.3531459998319],
   [-85.0917909999312, 31.3552069999046],
   [-85.0923270004067, 31.3640489999049],
   [-85.0824310002351, 31.3845400002512],
   [-85.0804029995822, 31.3939319997357],
   [-85.0776259998152, 31.3988800001054],
   [-85.0773870003389, 31.4028439999369],
   [-85.0799780003331, 31.4104719996407],
   [-85.0767460002814, 31.4159710001035],
   [-85.0758929996135, 31.4249279998914],
   [-85.0737019996524, 31.4289590000991],
   [-85.0685459996032, 31.4273109998043],
   [-85.0660120004087, 31.4305289999427],
   [-85.065954999609, 31.4429790002485],
   [-85.0716210001944, 31.4683839997574],
   [-85.0652590001982, 31.4849239999679],
   [-85.0621049999643, 31.4880170001204],
   [-85.0569279999988, 31.5001710001866],
   [-85.044985999631, 31.5182300000908],
   [-85.044556000359, 31.5209080001181],
   [-85.0482629998885, 31.5260120000999],
   [-85.0418130000288, 31.5377539997072],
   [-85.0413610001172, 31.5424950001958],
   [-85.0425469999727, 31.5459529997738],
   [-85.0508379999892, 31.5555510002876],
   [-85.052931000041, 31.5628900001132],
   [-85.0579599998686, 31.570839999749],
   [-85.0552840003839, 31.5775960001783],
   [-85.0584400002676, 31.583690000077],
   [-85.0559759995967, 31.6051749998938],
   [-85.0605519996877, 31.608223999853],
   [-85.0575270002235, 31.6168829998728],
   [-85.0581690001059, 31.6202269996609],
   [-85.073828999874, 31.6295669999271],
   [-85.0800290003867, 31.6368669997585],
   [-85.0862290000012, 31.6385670002775],
   [-85.0870290000674, 31.640965999683],
   [-85.0826289997035, 31.6502659996268],
   [-85.0780290002213, 31.6515660000413],
   [-85.0791289998631, 31.6547660001227],
   [-85.0924289996156, 31.6599660002813],
   [-85.1094299999482, 31.6774650001225],
   [-85.1139299998713, 31.6868649997549],
   [-85.1223299996677, 31.6912649999252],
   [-85.1255299999323, 31.6949649997914],
   [-85.126530000015, 31.7167640001389],
   [-85.1222300001086, 31.7227639997315],
   [-85.1222300001086, 31.7264640000144],
   [-85.1189300002848, 31.73266400016],
   [-85.122030000092, 31.7440640000716],
   [-85.1292309996141, 31.7586630000582],
   [-85.1252300003567, 31.7670629997206],
   [-85.1419310002153, 31.7819630000887],
   [-85.1329310003693, 31.7923629999909],
   [-85.1322309998622, 31.7951620001782],
   [-85.1329310003693, 31.8080619996624],
   [-85.1312309997795, 31.8167619997015],
   [-85.1345309996033, 31.8249619999472],
   [-85.1319310002866, 31.8270619997556],
   [-85.1377309998679, 31.8321619998137],
   [-85.1418309997579, 31.839261000306],
   [-85.1377309998679, 31.845861000189],
   [-85.1380310003419, 31.8512619997493],
   [-85.140731000116, 31.8574609997739],
   [-85.13583100016, 31.862460999832],
   [-85.1337310004354, 31.870061000258],
   [-85.1284310004462, 31.8775600000847],
   [-85.1341309995702, 31.892160000038],
   [-85.1150310002363, 31.8930600001122],
   [-85.1120300001633, 31.894760000063],
   [-85.1106300000475, 31.8968599999583],
   [-85.1122310000047, 31.9027600003515],
   [-85.1080299998325, 31.9051600002985],
   [-85.11313099963, 31.9118590000558],
   [-85.1024300002676, 31.9173589996861],
   [-85.0995299995786, 31.9252590001079],
   [-85.0918300002893, 31.9288590000573],
   [-85.0864299998427, 31.9359590002682],
   [-85.0789299996715, 31.9401590002007],
   [-85.0824300004101, 31.9453580001392],
   [-85.0867300003166, 31.9591580002489],
   [-85.083229999578, 31.9624580001846],
   [-85.0739300001563, 31.9641580001746],
   [-85.067829000276, 31.9673580002155],
   [-85.0659289996698, 31.972458000128],
   [-85.0709299999082, 31.9816580002981],
   [-85.0683299996932, 31.9867569999068],
   [-85.0673299996105, 31.9955570001378],
   [-85.0634409996077, 32.0041399996413],
   [-85.0550750001465, 32.0107139998913],
   [-85.0538149997728, 32.0135020001602],
   [-85.055307999775, 32.0177529997864],
   [-85.0536690001829, 32.0206619999254],
   [-85.0488250003033, 32.0217009997898],
   [-85.0571239998174, 32.0299190001836],
   [-85.0580340003867, 32.0336059999773],
   [-85.0535319999156, 32.0359520002677],
   [-85.0534680003415, 32.0378230001305],
   [-85.0588300002549, 32.0469560002278],
   [-85.0541789998209, 32.0679849996956],
   [-85.0558130002884, 32.0744390003141],
   [-85.0532320003399, 32.0806039998928],
   [-85.0447610003984, 32.0858359997643],
   [-85.0534020002192, 32.1048439997163],
   [-85.0619069995976, 32.1328630002021],
   [-85.0455930002516, 32.1437579999106],
   [-85.0265830004311, 32.1661029999775],
   [-85.0170799997215, 32.1735160003415],
   [-85.0112669997213, 32.1804930001518],
   [-84.9750279999626, 32.1910520001257],
   [-84.9637280003755, 32.19585199989],
   [-84.9617280002101, 32.1987510001709],
   [-84.9646280000008, 32.2035500001811],
   [-84.974427999913, 32.2035500001811],
   [-84.9802290002176, 32.20775100035],
   [-84.9798290001845, 32.2110500000586],
   [-84.9733280002712, 32.2176500002751],
   [-84.9681279998411, 32.2193509998183],
   [-84.9593280000116, 32.2178509997332],
   [-84.9393280001541, 32.2179510003568],
   [-84.9282269998603, 32.2198509996671],
   [-84.9229269998711, 32.2247499997026],
   [-84.9233260000793, 32.2308509996928],
   [-84.9163270002236, 32.2365509996339],
   [-84.9127269999259, 32.2433500002845],
   [-84.9154269997, 32.2460489999163],
   [-84.9203269996561, 32.2440499996961],
   [-84.9232270003451, 32.2446499997822],
   [-84.9244270004444, 32.2501490003079],
   [-84.9072269999201, 32.2490490002863],
   [-84.9040869999299, 32.2508379997063],
   [-84.9034790003827, 32.2556059999926],
   [-84.9011910003372, 32.2583739999664],
   [-84.8911910004085, 32.2574940000532],
   [-84.888246000412, 32.2596699998166],
   [-84.9040230003558, 32.2737490002086],
   [-84.9228719996196, 32.2853329998711],
   [-84.9346480003061, 32.2989159997945],
   [-84.9888259996575, 32.3191719996817],
   [-85.0003429998779, 32.3214179996589],
   [-85.0064019999253, 32.3267569999875],
   [-85.008095999769, 32.3366769997105],
   [-85.004581999685, 32.3451960002947],
   [-84.9838660003252, 32.3623859996501],
   [-84.9832419999862, 32.3651220001439],
   [-84.9863300000978, 32.3686899996885],
   [-84.9853219996192, 32.3725140001025],
   [-84.9820419998869, 32.3743379997548],
   [-84.9752260003293, 32.3741619998556],
   [-84.9721540001112, 32.3769140000242],
   [-84.9748100004027, 32.3802570001224],
   [-84.9862820004173, 32.380336000149],
   [-84.9884099997309, 32.3852159996636],
   [-84.9830019997867, 32.3919530003288],
   [-84.9783620001216, 32.3904970001164],
   [-84.9687300004388, 32.3914570001001],
   [-84.969274000412, 32.3934409998409],
   [-84.9747459999303, 32.3956010001845],
   [-84.9798979997815, 32.4000970003727],
   [-84.9809539999407, 32.4060640001709],
   [-84.9794309998012, 32.4122439996728],
   [-84.9718300002461, 32.416244000027],
   [-84.9630300004166, 32.424244000303],
   [-84.967030999674, 32.4353429998348],
   [-84.971831000071, 32.4428430000672],
   [-84.9838310001651, 32.4456419998519],
   [-84.993529999795, 32.4507429999868],
   [-84.9953299999439, 32.4532429997977],
   [-84.9985310000334, 32.4687420000301],
   [-84.9952279998366, 32.4752989996983],
   [-84.9949309997357, 32.4801419999826],
   [-84.9954320000511, 32.4889420000609],
   [-84.9988320003323, 32.4970410003608],
   [-84.9965319996929, 32.5016410000874],
   [-84.999832000415, 32.5043400003235],
   [-84.9989319998914, 32.5063409997353],
   [-85.0009320000568, 32.5087409998219],
   [-85.0015320001065, 32.514740999647],
   [-85.006380000184, 32.5184930000448],
   [-85.0070999998842, 32.5238679997796],
   [-85.0152610002044, 32.5276759998694],
   [-85.0152610002044, 32.5311149997593],
   [-85.0208769996627, 32.535852000311],
   [-85.0220449999749, 32.5408269999908],
   [-85.0207970001951, 32.5427640003702],
   [-85.0261890002458, 32.5448430001315],
   [-85.0313889997775, 32.5441710003186],
   [-85.0357260003922, 32.55396300019],
   [-85.0436619996832, 32.5565230001448],
   [-85.045470000228, 32.5617229999511],
   [-85.056926000349, 32.5712410000968],
   [-85.0579020001422, 32.5746499997435],
   [-85.0619829997656, 32.5782490000648],
   [-85.0675349996499, 32.5795460001098],
   [-85.0789749998774, 32.6001689999086],
   [-85.0822400004394, 32.6162640001955],
   [-85.0879360002637, 32.6205200003076],
   [-85.090886000283, 32.6251349996602],
   [-85.0900359999882, 32.6276359997094],
   [-85.0838360003737, 32.6319359999072],
   [-85.0831180003233, 32.6361610001094],
   [-85.0900359999882, 32.6340360003299],
   [-85.0966840002146, 32.6361970003363],
   [-85.0962180000593, 32.6348289996882],
   [-85.1000889996204, 32.6345759999075],
   [-85.0988989995669, 32.6369349999032],
   [-85.1003880002694, 32.6371180002683],
   [-85.1015540000335, 32.6403929998993],
   [-85.104531999642, 32.6410110001585],
   [-85.1054320001656, 32.6449339999672],
   [-85.1043369996483, 32.6464339998036],
   [-85.0983360002255, 32.6520340003737],
   [-85.0897360004125, 32.6556339999769],
   [-85.0884829997115, 32.657758000338],
   [-85.0930360002363, 32.6644340000174],
   [-85.0935359998285, 32.6697339998539],
   [-85.1040370000727, 32.6796340003463],
   [-85.1126369998856, 32.6834340002812],
   [-85.1170370002495, 32.692032999971],
   [-85.1200380003225, 32.7065330000967],
   [-85.1181379997162, 32.7094319998013],
   [-85.1218379995731, 32.7126320002396],
   [-85.1227380000967, 32.7160320001583],
   [-85.1208380003887, 32.7229320002274],
   [-85.1141380002837, 32.7304320000248],
   [-85.1133289999967, 32.7357509998673],
   [-85.1185500003432, 32.7408169999856],
   [-85.1282380001024, 32.7422320003254],
   [-85.1331380000585, 32.7446310003699],
   [-85.1394549998488, 32.7518290000278],
   [-85.1429870003745, 32.7599340002369],
   [-85.1416220004188, 32.7641620001079],
   [-85.1374170000486, 32.7671929998397],
   [-85.1366920003255, 32.7711720001326],
   [-85.1308840003482, 32.7695620002847],
   [-85.1237380001794, 32.7720310002482],
   [-85.122326000368, 32.7743829997856],
   [-85.1262189996704, 32.7791889997857],
   [-85.1310990004333, 32.7803129998829],
   [-85.1348930000015, 32.7844609996977],
   [-85.1396649999111, 32.7851500000775],
   [-85.1421830001104, 32.792136000183],
   [-85.1463320004041, 32.7915660001068],
   [-85.1520249998554, 32.7941790003146],
   [-85.1680479998466, 32.8118409997228],
   [-85.1686439996982, 32.8142450000796],
   [-85.1648450001072, 32.8197890002016],
   [-85.1683419995744, 32.8285159996295],
   [-85.1641079997902, 32.8352949999123],
   [-85.1540130003252, 32.8407440001899],
   [-85.1533880001612, 32.8448649999539],
   [-85.1562889997768, 32.8505239998691],
   [-85.1623100001894, 32.8564790003753],
   [-85.1686880000791, 32.8525869997559],
   [-85.1771249996855, 32.8538899997092],
   [-85.1843999997257, 32.8613169999417],
   [-85.1841310001122, 32.870525000153],
   [-85.233108000107, 33.1117140001027],
   [-85.3865810000125, 33.901718999712],
 ];