import axios from "axios";
import { toast } from "react-toastify";
import globalRouter from "../globalRouter.ts";
import {removeLocaStorage} from './utils.js'
const apiUrl = window.location.hostname === "demo.tscti.com"
  ? 'https://demo.tscti.com/api/'
  : 'https://demo.tscti.com/api';

const AXIOS = axios.create({ baseURL: apiUrl });

AXIOS.interceptors.request.use(

  async (config) => {
    let token = localStorage.getItem("token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token.replace(/['"]+/g, '')}`
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


const Axios = {
  get: async (endPoint, query) => {
    try {
      const res = await AXIOS.get(endPoint, { params: query });

      return res;
    } catch (error) {
      console.log('sdadadadss',error)
      if (error.message === 'Network Error' && !error.response) {


        removeLocaStorage()
        globalRouter.navigate("/");
        toast.error("Session Expired");
      } else if (error.response && error.response.status === 0) {
        toast.error("CORS error: Server not responding or cross-origin request blocked");
      }

      return error;
    }
  },

  patch: async (endPoint, data) => {
    try {
      const res = await AXIOS.patch(endPoint, data);
      return res;
    } catch (error) {
      if (error.message === 'Network Error' && !error.response) {
        removeLocaStorage()
        globalRouter.navigate("/");
        toast.error("Session Expired");

      } else if (error.response && error.response.status === 0) {
        toast.error("CORS error: Server not responding or cross-origin request blocked");
      }
      return error;
    }
  },

  put: async (endPoint, data) => {
    try {
      const res = await AXIOS.put(endPoint, data);
      return res;
    } catch (error) {
      if (error.message === 'Network Error' && !error.response) {
        removeLocaStorage()
        globalRouter.navigate("/");
        toast.error("Session Expired");

      } else if (error.response && error.response.status === 0) {
        toast.error("CORS error: Server not responding or cross-origin request blocked");
      }
      return error;
    }
  },

  post: async (endPoint, data) => {
    try {
      const res = await AXIOS.post(endPoint, data);
 console.log(res)
      return res;
    } catch (error) {
      if (error.message === 'Network Error' && !error.response) {
        removeLocaStorage()
        globalRouter.navigate("/");
        toast.error("Session Expired");

      } else if (error.response && error.response.status === 0) {
        toast.error("CORS error: Server not responding or cross-origin request blocked");
      }
      console.log(error)
      return error;
    }
  },

  delete: async (endPoint, data) => {
    try {
      const res = await AXIOS.delete(endPoint);
      return res;
    } catch (error) {
      if (error.message === 'Network Error' && !error.response) {
        removeLocaStorage()
        globalRouter.navigate("/");
        toast.error("Session Expired");

      } else if (error.response && error.response.status === 0) {
        toast.error("CORS error: Server not responding or cross-origin request blocked");
      }
      return error;
    }
  },
};

export default Axios;
