import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./CallTickets.scss";
import {
  Table,
  Button,
  Space,
  Select,
  DatePicker,
  TimePicker,
  Tabs,
  Tag,
  Card
} from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const formatDateUS = (date) => {
  return new Date(date).toLocaleDateString("en-US");
};

export default function CallTickets() {

  const { Option } = Select;
  const navigate = useNavigate();
  const [list, setlist] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    total: 0, // You’ll get the total count of records from your API response
  });
  const handleChange = (value) => {
    console.log('Selected:', value);
  };

  const columns = [
    {
      title: "Request Type",
      dataIndex: "request_type",
      key: "request_type",
    },
    {
      title: "Request Sub Type",
      dataIndex: "request_subtype",
      key: "request_subtype",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (record) => (
        <div
          style={{
            color:
              record === "High"
                ? "red"
                : record === "Medium"
                ? "orange"
                : record === "Low" || !record
                ? "green"
                : "black", // Default color if none matches
            fontWeight: "bold",
          }}
        >
          {record ? record : "Low"}
        </div>
      ),
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",

      render: (record) => formatDateUS(record),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "address",
      align: "center",
      render: (_, record) => (
        <div
          onClick={() => navigate(`/ticket-detail/${record.ticketNumber}`)}
          style={{ cursor: "pointer" }}
        >
          <i className="bx bx-show" style={{ fontSize: "20px" }}></i>
        </div>
      ),
    },
  ];
  useEffect(() => {
    fetchData(pagination.page, pagination.size);
  }, []);

  const handlePaginationChange = (page, pagesize) => {
    console.log("asd", page, pagesize);
    setPagination((prev) => ({ ...prev, page: page, size: pagesize }));
    fetchData(page, pagesize);
  };

  const fetchData = async (page, size) => {
    const obj = {
      page: page,
      size: size,
    };
    try {
      const response = await axios.get(
        `https://demo.tscti.com/api/incidents/all?page=${page}&size=${size}`
      );

      setlist(response.data.incidents);
      setPagination((prop) => ({
        ...prop,
        total: response.data.totalElements || 0, // Set total from the response
      }));
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log(false);
    }
  };

  return (
    <div
      className="signup-content2 "
      style={{ paddingLeft: "2%", paddingRight: "2%", paddingTop: "1%" ,height:'100vh'}}
    >
      <div
          className="card  mb-3  p-3"
          style={{
            background:
              "rgb(5, 162, 179)",
            textAlign: "left",
          }}
        >
          <h5 style={{ color: "whitesmoke" ,marginBottom:'0px'}}> Assigned Tickets </h5>
        </div>

      <div className="bg-gradient p-2 rounded filter">
        <div className="row">
          <div className="col-md-2 col-lg-2">
            <label className="label-heading1">Ticket Number</label>
            <input
              className={`form-input
                          `}
              name="ticketNumber"
              id="ticketNumber"
              placeholder="Ticket Number*"
              autoComplete="new-password"
            />
          </div>
          <div className="col-md-2 col-lg-2">
            <label className="label-heading1">Ticket Type</label>
            <input
              className={`form-input`}
              name="request_type"
              id="request_type"
              placeholder="Ticket Type*"
              autoComplete="new-password"
            />
          </div>

          <div className="col-md-2 col-lg-2">
            <label className="label-heading1">Status</label>
            <Select
              style={{
                width: "100%",
                height: '65%',
                background: "#15212d",
                borderColor: "rgb(223, 223, 223)",
              }}
              placeholder="Status*"
              options={[
                { value: "Reported", label: "Reported" },
                { value: "In Progress", label: "In Progress" },
                {
                  value: "Assign To DPT",
                  label: "Assign to DPT",
                },
                {
                  value: "Resolved",
                  label: "Resolved",
                },
              ]}
            />
          </div>

          <div className="col-md-2 col-lg-2">
          <label className="label-heading1">Select Agent</label>
          <Select
            style={{
              width: "100%",
              height: '65%',
              maxHeight: '65%',
              background: "#15212d",
              borderColor: "rgb(223, 223, 223)",
            }}
      mode="multiple"
      allowClear

      placeholder="Select options"
      onChange={handleChange}
    >
      <Option value="apple">Apple</Option>
      <Option value="banana">Banana</Option>
      <Option value="cherry">Cherry</Option>
      <Option value="grape">Grape</Option>
    </Select>
          </div>

          <div className="col-md-3 col-lg-3">
          <div className="filter-button">
          <button
            className="btn btn-style btn-save"
            style={{ background: "rgb(5, 162, 179)", color: "whitesmoke" }}
          >
            <i className="fas fa-search" style={{ marginRight: "8px" }}></i> Search
          </button>

          <button
            className="btn btn-style btn-cancel"
            style={{ background: "#F44336", color: "whitesmoke" }}
            onClick={() => navigate("/dashboard")}
          >
            <i className="fas fa-times" style={{ marginRight: "8px" }}></i>{" "}
            Cancel
          </button>

          </div>
          </div>
        </div>



      </div>

      <div className="bg-gradient p-2 rounded" style={{ position: "relative" }}>
        <Table
          dataSource={list}
          columns={columns}
          scroll={{ y: 350 }}
          rowClassName={() => "custom-row"}
          pagination={{
            showSizeChanger: true,
            onChange: handlePaginationChange,

            current: pagination.page,
            pageSize: pagination.size,

            total: pagination.total,

            showTotal: (total, range) => (
              <span style={{ color: "whitesmoke" }}>
                Total {total} records, showing {range[0]} to {range[1]}
              </span>
            ),
          }}
        />
        {/* <div
          className="table-header"
          style={{
            background: "rgb(5, 162, 179)",
          }}
        >
          <h4 style={{ fontFamily: "Myriad Pro" }}>Agent Care Tickets</h4>
        </div> */}
        ;
      </div>
    </div>
  );
}
