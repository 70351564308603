
import { useLocation } from 'react-router-dom';

import React, { useState,useEffect,useContext } from 'react';
import './SideBar.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import image  from '../../assests/logo.svg'
import { getLogoName ,listenToEvent, removeListener } from '../../Helpers/utils';
import {getRole} from '../../Helpers/utils'
import { UserInfo } from "../../Context/userInfo";
const SideBar = ({ isOpen, toggleSidebar }) => {

  const { userData, updateUserData } = useContext(UserInfo);
  const [roles,setRoles]=useState(null)

  const navigate = useNavigate();
  useEffect(()=>{
if(userData){
  setRoles( getRole(userData.data?.userInfo?.roles))
}


  },[userData])





    const [showClass, setShowClass] = useState('');
  const setToggle = (data) => {
    setShowClass((prev) => (prev === data ? '' : data));
  };




  return (
    <div className={`sidebar ${isOpen ? 'open' : 'close'}`}>
      <div className="logo-details">
 <Link style={{textDecoration:'none'}}>
       <div className="logo-resize">


       {!isOpen ?  <img
                    src={image}
                       style={{ height: "60px" }}
                      alt="Logo"
                    />: <div className='d-flex' style={{marginLeft:'-10%'}}><img
                    src={image}
                       style={{ height: "60px"}}
                      alt="Logo"
                    /><div style={{ marginTop: '5%',
                    fontFamily: 'Myriad Pro',
                    fontSize: 'xx-large',
                    color: 'whitesmoke'}}>1Dallas</div></div>}

                    </div>
</Link>

      </div>
      <ul className="nav-links">


      <li onClick={() => setToggle('Dashboard')}>
          <div className={`icon-link ${showClass == 'Dashboard' ? "icon-link1" : ''}`}  onClick={() => {
      navigate("/Dashboard")}}>
            <Link className="loginhere-link" >
              <i className='bx bx-line-chart'></i>
              <span className="link_name" style={{color:'#dfdfdf'}}>Dashboard</span>
            </Link>

          </div>
          <ul className="sub-menu" style={{padding:'12px 12px ', backgroundColor:"rgba(31,41,53,255)"}}>
        <li className={`li-setting icon-link ${showClass == 'Dashboard' ? "icon-link1" : ''}`} style={{ padding: '9px' }} onClick={(e) => {
            e.stopPropagation();
            setShowClass('Dashboard'); // Ensure the submodule stays open
            navigate("/Dashboard")
        }}>
            <Link className="loginhere-link" style={{padding: "0px 20px"}}>
                {/* Same icon for submodule */}
                <i className='bx bx-line-chart'></i>
                <span className={`${showClass != 'Dashboard' ? "link_name1" : 'link_name2'}` } style={{color:'#dfdfdf'}}>Dashboard</span>
            </Link>
        </li>
    </ul>





        </li>


     {roles== 'Admin' &&   <li onClick={() => setToggle('Agent-care')}>
          <div className={`icon-link ${showClass == 'Agent-care' ? "icon-link1" : ''}`}  onClick={() => {
      navigate("/Agent-care")}}>
            <Link className="loginhere-link" >
              <i className='bx bx-support'></i>
              <span className="link_name" style={{color:'#dfdfdf'}}>Agent Care</span>
            </Link>

          </div>
          <ul className="sub-menu" style={{padding:'12px 12px ', backgroundColor:"rgba(31,41,53,255)"}}>
        <li className={`li-setting icon-link ${showClass == 'Agent-care' ? "icon-link1" : ''}`} style={{ padding: '9px' }} onClick={(e) => {
            e.stopPropagation();
            setShowClass('Agent-care'); // Ensure the submodule stays open
            navigate("/Agent-care")
        }}>
            <Link className="loginhere-link" style={{padding: "0px 20px"}}>
                {/* Same icon for submodule */}
                <i className='bx bx-support'></i>
                <span className={`${showClass != 'Agent-care' ? "link_name1" : 'link_name2'}` } style={{color:'#dfdfdf'}}>Agent Care</span>
            </Link>
        </li>
    </ul>





        </li>}

       {roles== 'Agent' && <li onClick={() => setToggle('My-tickets')}>
          <div className={`icon-link ${showClass == 'My-tickets' ? "icon-link1" : ''}`}  onClick={() => {
      navigate("/my-tickets")}}>
            <Link className="loginhere-link" >
              <i className='bx bx-help-circle'></i>
              <span className="link_name" style={{color:'#dfdfdf'}}>My Tickets</span>
            </Link>

          </div>
          <ul className="sub-menu" style={{padding:'12px 12px ', backgroundColor:"rgba(31,41,53,255)"}}>
        <li className={`li-setting icon-link ${showClass == 'My-tickets' ? "icon-link1" : ''}`} style={{ padding: '9px' }} onClick={(e) => {
            e.stopPropagation();
            setShowClass('My-tickets'); // Ensure the submodule stays open
            navigate("/my-tickets")
        }}>
            <Link className="loginhere-link" style={{padding: "0px 20px"}}>
                {/* Same icon for submodule */}
                <i className='bx bx-help-circle'></i>
                <span className={`${showClass != 'My-tickets' ? "link_name1" : 'link_name2'}` } style={{color:'#dfdfdf'}}>My Tickets</span>
            </Link>
        </li>
    </ul>





        </li>}




      </ul>
    </div>
  );
};

export default SideBar;

